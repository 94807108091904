export default {
	AllPages: {
		user: [""],
		project: ["user"],
		welcomevideo: [null, "information"],
		information: ["welcomevideo", "healthcarevideo"],
		healthcarevideo: ["information", "selectvideo"],
		selectvideo: ["healthcarevideo", "selection"],
		selection: ["selectvideo", "blueprintvideo"],
		blueprintvideo: ["selection", "problemvideo"],
		problemvideo: ["blueprintvideo", "problem"],
		problem: ["problemvideo", "aimvideo"],
		aimvideo: ["problem", "aim"],
		aim: ["aimvideo", "rostervideo"],
		rostervideo: ["aim", "roster"],
		roster: ["rostervideo", "nextvideo"],
		nextvideo: ["roster", "dashboard"],
		measurementvideo: ["dashboard", "measurement"],
		measurement: ["measurementvideo", "causevideo"],
		causevideo: ["measurement", "cause"],
		cause: ["causevideo", "star"],
		star: ["cause", "dashboard"],
		solutionsvideo: ["dashboard", "solutions"],
		solutions: ["solutionsvideo", "prioritization"],
		prioritization: ["solutions", "timelinevideo"],
		timelinevideo: ["prioritization", "timeline"],
		timeline: ["timelinevideo", "dashboard"],
		preview: ["dashboard", "blueprint"],
		blueprint: ["preview", ""],
		dashboard1: ["nextvideo", "measurementvideo"],
		dashboard2F: ["cause", "solutionsvideo"],
		dashboard2B: ["nextvideo", "measurementvideo"],
		dashboard3F: ["timeline", "preview"],
		dashboard3B: ["cause", "solutionsvideo"],

		mappingvideo: ["advancedtools", "mapping"],
		mapping: ["mappingvideo", "advancedtools"],

		chartvideo: ["advancedtools", "chart"],
		chart: ["chartvideo", "advancedtools"],
	},
	ActivitiesOnly: {
		user: [""],
		project: ["user"],
		information: [null, "selection"],
		selection: ["information", "problem"],
		problem: ["selection", "aim"],
		aim: ["problem", "roster"],
		roster: ["aim", "dashboard"],
		measurement: ["dashboard", "cause"],
		cause: ["measurement", "star"],
		star: ["cause", "dashboard"],
		solutions: ["dashboard", "prioritization"],
		prioritization: ["solutions", "timeline"],
		timeline: ["prioritization", "dashboard"],
		preview: ["dashboard", "blueprint"],
		blueprint: ["preview", ""],
		dashboard1: ["roster", "measurement"],
		dashboard2F: ["cause", "solutions"],
		dashboard2B: ["roster", "measurement"],
		dashboard3F: ["timeline", "preview"],
		dashboard3B: ["cause", "solutions"],

		welcomevideo: [null, "information"],
		healthcarevideo: ["information", "selectvideo"],
		selectvideo: ["healthcarevideo", "selection"],
		blueprintvideo: ["selection", "problemvideo"],
		problemvideo: ["blueprintvideo", "problem"],
		aimvideo: ["problem", "aim"],
		rostervideo: ["aim", "roster"],
		nextvideo: ["roster", "dashboard"],
		measurementvideo: ["dashboard", "measurement"],
		causevideo: ["measurement", "cause"],
		solutionsvideo: ["dashboard", "solutions"],
		timelinevideo: ["prioritization", "timeline"],

		mappingvideo: ["advancedtools", "mapping"],
		mapping: ["mappingvideo", "advancedtools"],

		chartvideo: ["advancedtools", "chart"],
		chart: ["chartvideo", "advancedtools"],
	}
} 
