import React from 'react';
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Glyphicon,
  Jumbotron,
} from 'react-bootstrap';
import AllVideos from '../../helpers/AllVideos.jsx';
import Footer from '../../global-components/Footer';

class NextVideo extends React.Component{
  
  render(){
    var topToolTip = 
    <Tooltip id="topToolTip">
    Can't see the video? Click on the Menu and select About to for technical help. 
Can't hear the video? Make sure that your volume is turned up.
    </Tooltip>;

    return (
      <div className="page-shift">
          <Jumbotron className="jumbotron-video">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>

          <div className="video-title">
            <span id="video-title-text">Next Steps</span>
          </div>
          <div className="embedded-video">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe src={AllVideos["next"]} title="next" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen="true"></iframe>
            </div>
          </div>

          <div className="footer-buttons">
            <Footer pageName="nextvideo" setEdit={false} routerKey={this.props.routerKey} history={this.props.history} />    
          </div>

          </Jumbotron>
      </div>
    )
  }
};

export default NextVideo;
