import {
	initializeApp,
} from 'firebase';

var config = {
	apiKey: "AIzaSyDBKw9by6zxBSFQUHUBZBYZt4_txBP8tlQ",
	authDomain: "testbitesize42.firebaseapp.com",
	databaseURL: "https://testbitesize42.firebaseio.com",
	projectId: "testbitesize42",
	storageBucket: "testbitesize42.appspot.com",
	messagingSenderId: "680642808563"
};

const email = "bitesizeqi@gmail.com";
const password = "1243bitesizeQI";

var fire = initializeApp(config);

fire.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
	var errorCode = error.code;
	var errorMessage = error.message;
	console.log(errorMessage, errorCode);
});

export default fire;
