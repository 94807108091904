import React from 'react';
import {
  Button,
} from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import AllMapRouting from '../helpers/AllMapRouting.jsx';
import ReactGA from './../../ga.js';

const styling = {
  fontSize: '32px',
}

class Footer extends React.Component {

  componentWillUnmount() {
    if(this.props.setEdit && this.props.setEdit()) { this.props.setEdit(true); }
  }

  back() {
    let pageName = this.props.pageName;
    let key = this.props.routerKey ? this.props.routerKey : "AllPages";
    let pageRouter = AllMapRouting[key];
    let transition = pageName ? pageRouter[pageName][0] : null;
    ReactGA.event({
      category: 'Main',
      action: 'Footer',
      label: 'Going back from ' + pageName,
    });
    if (transition === null) { return; }
    this.props.history.goBack(); 
    return;
    // this.props.history.push("/" + transition);
  }

  next() {
    let pageName = this.props.pageName;
    let key = this.props.routerKey ? this.props.routerKey : "AllPages";
    let pageRouter = AllMapRouting[key]
    let transition = pageRouter[pageName][1];
    ReactGA.event({
      category: 'Main',
      action: 'Footer',
      label: 'Going forward from ' + pageName,
    });
    this.props.history.push("/" + transition);
  }

  save() {
    let pageName = this.props.pageName;
    ReactGA.event({
      category: 'Main',
      action: 'Footer',
      label: 'Saving ' + pageName,
    });
    this.props.history.push("/preview");
  }

  render () {

    if(this.props.setEdit && this.props.setEdit()) {
      return (
        <div className="activity-footer-buttons">
        <Button className="footer-button save-footer-button" bsSize="large" onClick={this.save.bind(this)}>
          Save &nbsp; &nbsp;
          <div className="footer-right-image">
            <Icon style={styling}>keyboard_arrow_right</Icon>
          </div>
        </Button>  
        </div>
      )
    }

    return (
      <div className="activity-footer-buttons">
        <Button className="footer-button left-footer-button" bsSize="large" onClick={this.back.bind(this)}>
          <div className="footer-left-image">
              <Icon style={styling}>keyboard_arrow_left</Icon>
          </div>
          &nbsp; &nbsp; Back
        </Button>  

        {this.props.nextDisabled ? null : 
          <Button className="footer-button right-footer-button" bsSize="large" onClick={this.next.bind(this)}>
            Next &nbsp; &nbsp;
            <div className="footer-right-image">
              <Icon style={styling}>keyboard_arrow_right</Icon>
            </div>
          </Button>  
        }
      </div>
    )
  }
};

export default Footer;
