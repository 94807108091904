import React from 'react';
import {
  Button,
  Image,
  Tooltip,
  OverlayTrigger,
  Glyphicon,
  Jumbotron,
} from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import Footer from '../global-components/Footer';
import storageHelper from '../helpers/storageHelper';
import ReactGA from './../../ga.js';

const styling = {
  fontSize: '32px',
  color: "white",
}

const circle = {
  fontSize: '20px',
  color: "white",
}

const sectionText = {
  charter: {
    problem: "Problem Statement",
    aim: "Aim Statement",
    roster: "Team Roster",
  },
  discover: {
    measurement: "Measurement",
    cause: "Cause and Effect",
  },
  launch: {
    solutions: "Solutions",
    timeline: "Timeline",
  },
}

const linkStatus = {
  problem: 2,
  aim: 3,
  roster: 4,
  measurement: 5,
  cause: 6,
  solutions: 7,
  timeline: 8,
}

class Dashboard extends React.Component {

  moveTo(page) {
    this.props.history.push('/' + page);
  }

  back(newPage) {
    storageHelper.clearDashboardBack();
    ReactGA.event({
      category: 'Main',
      action: 'Footer',
      label: 'Going back from dashboard',
    });
    this.props.history.push("/" + newPage);
  }

  render() {
    const dashboardBackPage = storageHelper.getDashboardBack();
    let lastBite = (this.props.lastBite === null) ? 1: this.props.lastBite;
    let goingForward = this.props.goingForward;
    var pageName = null;
    if (lastBite === 1) { pageName = "dashboard1"; } 
    else if (lastBite === 2) {
      if (goingForward) { pageName = "dashboard2F"; } 
      else { pageName = "dashboard2B"; }} 
    else if (lastBite === 3) {
      if (goingForward) { pageName = "dashboard3F"; } 
      else { pageName = "dashboard3B"; }
    }

    var topToolTip = 
    <Tooltip id="topToolTip">
      This page summarizes your work so far, and what is left to be done. You can return to each section by clicking on the corresponding icon. You can view your Project Blueprint by clicking on the Project Blueprint button at the bottom of the screen. If you have just finished the Charter or Discover phase, click the arrow in the bottom right to continue. If you want to leave and return later to finish your work, be sure to save your project code on the top right of your screen. You will not be able to access completed work without your unique code. 
    </Tooltip>;

    var activityStatus = this.props.activityStatus;
    var lastActivity = activityStatus.length - 1;
    while (lastActivity >= 2) {
      if (activityStatus[lastActivity] === "0" && activityStatus[lastActivity-1] !== "0") {
        break;
      }
      lastActivity--;
    }
    lastActivity -= 2;
    if (activityStatus[activityStatus.length - 1] !== "0") {
      lastActivity = activityStatus.length - 1;
    }
    var charterSub = [];
    const charterText = sectionText.charter;
    var currentIndex = 0;
    for (const key of Object.keys(charterText)) {
      var mainClass = "dashboard-charter-sub";
      if (currentIndex >= lastActivity) {
        mainClass += " dashboard-inactive";
      }
      var icon;
      if (activityStatus[linkStatus[key]] === "2") {
        icon = 
        <div className="dashboard-check dashboard-charter-check">
          <Icon className="dashboard-icon" style={circle}>check</Icon>
        </div>;
      } else if (activityStatus[linkStatus[key]] === "1") {
        icon = 
        <div className="dashboard-check dashboard-exclamation">
          <Icon className="dashboard-icon" style={circle}>priority_high</Icon>
        </div>;
      }
      charterSub.push(
        <div key={key} className={mainClass} onClick={this.moveTo.bind(this, key)}>
          <div className="dashboard-activity-image">
            <Image className="dashboard-image" src={"/images/" + key + ".png"} responsive />
          </div>
          <p className="dashboard-sub-text">{charterText[key]}</p>
          {icon}
        </div>
      )
      currentIndex++;
    }

    var discoverSub = [];
    const discoverText = sectionText.discover;
    for (const key of Object.keys(discoverText)) {
      mainClass = "dashboard-discover-sub";
      if (currentIndex >= lastActivity) {
        mainClass += " dashboard-inactive";
      }
      icon = null;
      if (activityStatus[linkStatus[key]] === "2") {
        icon = 
        <div className="dashboard-check dashboard-discover-check">
          <Icon className="dashboard-icon" style={circle}>check</Icon>
        </div>;
      } else if (activityStatus[linkStatus[key]] === "1") {
        icon = 
        <div className="dashboard-check dashboard-exclamation">
          <Icon className="dashboard-icon" style={circle}>priority_high</Icon>
        </div>;
      }
      discoverSub.push(
        <div key={key} className={mainClass} onClick={this.moveTo.bind(this, key)}>
          <div className="dashboard-activity-image">
            <Image className="dashboard-image" src={"/images/" + key + ".png"} responsive />
          </div>
          <p className="dashboard-sub-text">{discoverText[key]}</p>
          {icon}
        </div>
      )
      currentIndex++;
    }

    var launchSub = [];
    const launchText = sectionText.launch;
    for (const key of Object.keys(launchText)) {
      mainClass = "dashboard-launch-sub";
      if (currentIndex >= lastActivity) {
        mainClass += " dashboard-inactive";
      }
      icon = null;
      if (activityStatus[linkStatus[key]] === "2") {
        icon = 
        <div className="dashboard-check dashboard-launch-check">
          <Icon className="dashboard-icon" style={circle}>check</Icon>
        </div>;
      } else if (activityStatus[linkStatus[key]] === "1") {
        icon = 
        <div className="dashboard-check dashboard-exclamation">
          <Icon className="dashboard-icon" style={circle}>priority_high</Icon>
        </div>;
      }
      launchSub.push(
        <div key={key} className={mainClass} onClick={this.moveTo.bind(this, key)}>
          <div className="dashboard-activity-image">
            <Image className="dashboard-image" src={"/images/" + key + ".png"} responsive />
          </div>
          <p className="dashboard-sub-text">{launchText[key]}</p>
          {icon}
        </div>
      )
      currentIndex++;
    }

    return (
      <div className="page-shift">
          <Jumbotron className="jumbotron-dashboard">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>

          <div className="dashboard-title">
            <p className="dashboard-title-text">Project Dashboard</p>
          </div>

          <div className="dashboard-activity-main">
            <div className="dashboard-charter-main">
              <div className="charter-title">
                <p className="dashboard-activity-title">Charter</p>
              </div>
              {charterSub}
            </div>
            
            <div className="dashboard-discover-main">
              <div className="discover-title">
                <p className="dashboard-activity-title">Discover</p>
              </div>
              {discoverSub}
            </div>

            <div className="dashboard-launch-main">
              <div className="launch-title">
                <p className="dashboard-activity-title">Launch</p>
              </div>
              {launchSub}
            </div>
          </div>

          <div className="dashboard-quick-sub">
            <div className="dashboard-quick-section" onClick={this.moveTo.bind(this, "preview")}>

              <div className="dashboard-sub-image">
                <Image className="dashboard-image" src="/images/blueprint.png" responsive />
              </div>

              <p className="dashboard-sub-title">Project Blueprint</p>
            </div>
          </div>
          {
            dashboardBackPage !== "null" ? 
              <div className="activity-footer-buttons">
                <Button className="footer-button left-footer-button" bsSize="large" onClick={this.back.bind(this, dashboardBackPage)}>
                  <div className="footer-left-image">
                    <Icon style={styling}>keyboard_arrow_left</Icon>
                  </div>
                  &nbsp; &nbsp; Back
                </Button>  
              </div>
              : 
              <div className="dashboard-footer-buttons">
                <Footer pageName={pageName} setEdit={false} routerKey={this.props.routerKey} history={this.props.history} /> 
              </div>
          }
        </Jumbotron>
      </div>
    )
  }
};

export default Dashboard;
