import React from 'react';
import {
  Jumbotron,
} from 'react-bootstrap';

class About extends React.Component {

  componentDidMount() {
    this.props.setShowProgress(false);
  }

  componentWillUnmount() {
    this.props.setShowProgress(true);
  }

  render() {

    const team = [                
      "Arif Kamal MD", 
      "Jon Nicolla MBA", 
      "Steve Power", 
      "Tisha Broyles", 
      "Fred Friedman", 
      "Simran Singh", 
    ];
    var teamText = [];
    for(const member of team) {
      teamText.push(member);
      teamText.push(<br />);
    }
    return (
      <div>

        <div className="static-content">
          <Jumbotron className="jumbotron-static">
            <div className="static-text">
              <h2>About Us</h2>
              <br />
                About BiteSizeQI:  Quality improvement (QI) is an integral component to delivering high-quality healthcare.  However, QI is challenging, and with mounting day-to-day duties, healthcare workers struggle to find the time to learn proper skills to successfully affect change in their workplace.  In addition, healthcare can be hierarchical, with top doctors and executives often pushing change initiatives in clinical settings without obtaining proper feedback from their colleagues.  We believe that BiteSizeQI levels the playing field.
              <br />
              <br />
                BiteSizeQI is an interactive platform that teaches the skills necessary to successfully implement a healthcare quality improvement project.  Using short videos and activities, BiteSizeQI teaches users the quality improvement skills they need to build their own quality improvement project and impact positive change in their workplace.  
              <br />
              <br />
                <p className="about-text">Contributors</p>
              <br />
                BiteSizeQI was created by a passionate team of quality improvement experts, clinicians, programmers and researchers at Duke University to foster micro-innovation at the ground level of healthcare delivery.  
              <br />
              <br />
                <p className="about-text">Our Team</p>
              <br />
                {teamText}
              <br />
              <br />
            </div>


            <div className="static-pages"> 
              <a href="#about" className="home-page-text">About</a> 
              &nbsp; • &nbsp; 
              <a href="#terms" className="home-page-text">Terms</a>
              &nbsp; • &nbsp; 
              <a href="#privacy" className="home-page-text">Privacy</a>            
              &nbsp; • &nbsp; 
              <a href="#contact" className="home-page-text">Contact</a>
            </div>

          </Jumbotron>
        </div>
        
      </div>
    )
  }
}

export default About