import areIntlLocalesSupported from 'intl-locales-supported';

let DateTimeFormat;
if (areIntlLocalesSupported(['fr', 'fa-IR'])) {
  DateTimeFormat = global.Intl.DateTimeFormat;
} 

function setSelection(snapshot) {
  let data = snapshot.val();
  if(!data || data.length !== 3) { return ["", "", ""]; }
  return data;
}

function setProblem(snapshot) {
  var values = [];
  snapshot.forEach((childSnapshot) => {
    values[childSnapshot.key] = childSnapshot.val();
  })
  var setProblem = (values.length !== 5) ? false : true;
  for(var i = 0; i<values.length; i++) {
    if(values[i] === "") {
      setProblem = false;
    } 
  }
  var problem = {};
  if (setProblem) {
    problem.statement = values[0] + " " + values[1] + " " + values[2] + " " + values[3] + ". " + values[4] + ".";
  } else { problem.statement = "Please complete the Problem Statement Activity"; }
  if (values[3] === undefined || values[3] === "") { problem.what = 'No "What" in Problem Statement';
  } else { problem.what = values[3]; }
  return problem;
}

function setAim(snapshot) {
  var values = [];
  snapshot.forEach((childSnapshot) => {
    values[childSnapshot.key] = childSnapshot.val();
  })
  var setProblem = (values.length !== 4) ? false : true;
  for(var i = 0; i<values.length; i++) {
    if(values[i] === "") {
      setProblem = false;
    } 
  }
  var problem = ["Please complete the Aim Statement Activity"];
  if (setProblem) {
    problem[0] = "" + values[0] + " " + values[1] + " " + values[2] + " " + values[3] + ".";
  }
  return problem;
}

function setRoster(snapshot) {
  var values = {
    "Project Champion": [],
    "Project Lead": [],
    "QI Team": [], 
  };
  let allRoster = JSON.parse(snapshot.child("allRoles").val());
  if (allRoster === null) { return values; }
  allRoster[0] = "No Role Given";
  snapshot.child("team").forEach((childSnapshot) => {
    for(var key in childSnapshot.val()) {
      let role = allRoster[key];
      if(role === "No Role Given") { return; }
      let name = childSnapshot.val()[key] === "" ? "No Name Given": childSnapshot.val()[key];
      values[role].push(name);
    }
  });
  return values;
}

function setMeasurement(snapshot) {
  var values = [[], []];
  var responses = [];
  let keys = ["how/0", "how/1", "how/2", "how/3"];
  let defaultValue = "No Response Given";
  for(var i = 0; i<keys.length; i++) {
    let currentVal = snapshot.child(keys[i]).val();
    let response = (currentVal === "" || currentVal === null) ? defaultValue : currentVal;
    responses[i] = response;
  }
  values[0][0] = responses[0];
  values[0][1] = responses[1];
  values[1][0] = responses[2];
  values[1][1] = responses[3];
  return values;
}

function setMeasurementWhat(snapshot) {
  var responses = [];
  let keys = ["what"];
  let defaultValue = "No Response Given";
  for(var i in keys) {
    let currentVal = snapshot.child(keys[i]).val();
    let response = (currentVal === "" || currentVal === null) ? defaultValue : currentVal;
    responses[i] = response;
  }
  return responses;
}

function setCause(snapshot) {
  var values = [[], []];
  var starred = [];
  let mainValues = JSON.parse(snapshot.child("main").val());
  let subValues = JSON.parse(snapshot.child("subValues").val());
  let checkValues = JSON.parse(snapshot.child("checkValues").val());
  if(mainValues === null) { return values; }
  for(var i in mainValues) {
    for(var j in subValues[i]) {
      var effect = subValues[i][j];
      if (effect === "") { continue; }
      if(checkValues[i][j]) { starred.push(effect); }
    }
  }
  var index = 0;
  var valuesIndex = 0;
  for(i = 0; i<5; i++) {
    let starValue = starred[index];
    if(starValue === undefined || starValue === null) { continue; }
    values[valuesIndex].push(starValue);
    if(index === 2) { valuesIndex += 1; }
    index += 1;
  }
  return values;
}

function setSolutions(snapshot) {
  var values = {
    solutions: [],
    causes: [],
    likelihood: [],
    difficulty: [],
  };
  var allCauses = JSON.parse(snapshot.child("allCauses").val());
  if(!allCauses) { return values; }
  snapshot.child("allSolutions").forEach((childSnapshot) => {
    var dataMap = childSnapshot.val();
    let potential = dataMap["potential"];
    if(!dataMap) { return values; }
    if (potential === "") { return values; }
    let cause = allCauses[dataMap["causes"]] === "Select a Cause" ? "No Cause Give" : allCauses[dataMap["causes"]];
    let likelihood = dataMap["likelihood"] === "0" ? 0 : 6 - dataMap["likelihood"]; 
    let difficulty = dataMap["difficulty"] === "0" ? 0 : 6 - dataMap["difficulty"];
    values.solutions.push(potential);
    values.causes.push(cause);
    values.likelihood.push("+" + likelihood);
    values.difficulty.push("+" + difficulty);
  });
  return values;
} 

function setPrioritization(snapshot) {
  let prev = JSON.parse(snapshot.child("prevSolutions").val());
  if(prev === null || prev.length === 0) { return ["No response given!"]; }
  var prevNames = [];
  for(var i in prev) {
    let aSolution = prev[i];
    prevNames.push(aSolution["text"]);
  }
  while(prevNames.length < 3) { prevNames.push(""); }
  return prevNames;
}

function setTimeline(snapshot) {
  var values = {
    milestones: [],
    dates: [],
    assignments: [],
  };
  var allRoster = JSON.parse(snapshot.child("allRoster").val());
  if (!allRoster) { return values; }
  snapshot.child("allEvents").forEach((childSnapshot) => {
    let index = childSnapshot.key; 
    if (index >= 10) { return values; }
    var dataMap = childSnapshot.val();
    let readData = readDataMap(dataMap, allRoster);
    values.milestones.push(readData.milestone);
    values.dates.push(readData.date);
    values.assignments.push(readData.asignee);
  })
  let addedEvents = JSON.parse(snapshot.child("extraFields").val());
  if(addedEvents === null) { addedEvents = []; }
  for(var i in addedEvents) {
    let index = addedEvents[i]*2;
    let indices = [index + 10, index + 11];
    for(var j in indices) {
      let current = indices[j];
      let child = snapshot.child("allEvents/" + current);
      var dataMap = child.val();
      let readData = readDataMap(dataMap, allRoster);
      values.milestones.push(readData.milestone);
      values.dates.push(readData.date);
      values.assignments.push(readData.asignee);
    }
  }
  return values;
}

function readDataMap(dataMap, allRoster) {
  let event = dataMap["events"];
  var values = {
    milestone: "No Milestone",
    date: "No Date Given",
    asignee: "No Asignee",
  };
  if (!event) { return values; }
  values.milestone = event;
  let parsedDate = JSON.parse(dataMap["dates"]);
  var date = new Date(parsedDate);
  if(!isNaN(date.getTime())) {
    let formatter = new DateTimeFormat(
      'en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
    values.date = formatter.format(date);
  }
  let rosterIndex = dataMap["roster"];
  var asignee = allRoster[rosterIndex];
  if(!(rosterIndex === 0) || (rosterIndex >= allRoster.length)) {
    values.asignee = asignee;
  } 
  return values;
}

function setTopTimeline(snapshot) {
  var values = []; 
  let add = ["0", "7", "8"];
  for(var i in add) {
    let rawDate = snapshot.child("allEvents/" + add[i] + "/dates").val();
    let parsedDate = JSON.parse(rawDate);
    let date = new Date(parsedDate);
    if(isNaN(date.getTime()) || parsedDate === null) { 
      date = "No Date Given"; 
    } else {
      let formatter = new DateTimeFormat(
        'en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
      date = formatter.format(date);
    }
    values.push(date);
  } 
  return values;
}

function setFishbone(snapshot) {
  var values = {
    causes: [[], []],
    subs: [[[], []], [[], []]],
    what: 'No "What" in Problem Statement',
  };
  let mainValues = JSON.parse(snapshot.child("main").val());
  let subValues = JSON.parse(snapshot.child("subValues").val());
  if(mainValues === null) { return values; }
  let secondValues = [0, 0, 1, 1];
  for(var i in mainValues) {
    var firstIndex = 0;
    let secondIndex = secondValues[i];
    if(i === "1" || i === "3") { firstIndex = 1; } 
    let cause = mainValues[i] === "" ? "No Cause Given" : mainValues[i];
    for(var j in subValues[i]) {
      var effect = subValues[i][j];
      if (effect === "") { continue; }
      values.subs[firstIndex][secondIndex].push(effect);
    }
    values.causes[firstIndex].push(cause);
  }
  return values;
}

export {
  setSelection,
  setProblem,
  setAim,
  setRoster,
  setMeasurement,
  setMeasurementWhat,
  setCause,
  setSolutions,
  setPrioritization,
  setTimeline,
  setTopTimeline,
  setFishbone,
};
