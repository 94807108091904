import storageHelper from './storageHelper';
import fire from './../../fire';

const firebaseHelper = {

    getDatabase() {
        return fire.database().ref();
    },

    getClientDatabase(key="") {
        const client = storageHelper.getClient();
        const path = client + "/" + key;
        return fire.database().ref(path);
    },
    
    getSession(key="") {
        const client = storageHelper.getClient();
        const projectCode = storageHelper.getProjectCode();
        if (!projectCode || !client) { throw new Error("No project code. Please go back to the home!"); }
        const path = client + "/project_codes/" + projectCode + key;
        return fire.database().ref(path); 
    },

};

export default firebaseHelper;
