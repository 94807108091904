import React from 'react';
import {
  Button,
  FormControl,
  Modal,
  DropdownButton,
  Grid,
  Row,
  Col,
  InputGroup,
  MenuItem,
  FormGroup, 
  OverlayTrigger,
  Tooltip,
  Glyphicon,
  Jumbotron
} from 'react-bootstrap';
import Footer from '../global-components/Footer';
import DatePicker from "react-16-bootstrap-date-picker";

const confirmedNumber = 10
const defaultSolutions = [        
  "Implement ", "Decide on ", 
];

class Timeline extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      initialCheck: true,
      events: ["Project Start Date", "Finalize Problem Statement", "Finalize Aim Statement", 
        "Finalize Roster", "Finalize Measurements", "Finalize Cause and Effect", 
        "Finalize Prioritization of Solutions", "Review with Lead and Sign Off"],
      addedEvents: [],
      dates: ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
      roster: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      allRoster: ["Choose a Team Member", "QI Team"]
    };
  }

  componentWillReceiveProps(nextProps) {
    var roster = [this.state.allRoster[0], this.state.allRoster[1]].concat(nextProps.roster);
    this.setState({ allRoster: roster });
  }

  componentDidMount() {
    var allRoster = [this.state.allRoster[0], this.state.allRoster[1]].concat(this.props.roster);
    this.setState({ allRoster: allRoster });
    this.props.session.on('value', ss => {
      let addedEvents = JSON.parse(ss.child("extraFields").val());
      let snapshot = ss.child("allEvents");
      var dates = [];
      var roster = [];
      snapshot.forEach((childSnapshot) => {
        var index = childSnapshot.key;
        var dataMap = childSnapshot.val();
        var dateText = JSON.parse(dataMap["dates"]);
        var date = new Date(dateText);
        if(isNaN(date.getTime())) { date = ""; }
        dates[index] = dateText;
        var rosterIndex = dataMap["roster"];
        if(!(rosterIndex < allRoster.length)) { rosterIndex = 0; } 
        roster[index] = rosterIndex; 
      })
      let solutions = JSON.parse(ss.child("solutions").val());
      var appendingEvents = [];
      for(var i in solutions) {
        var solution = solutions[i].text;
        if(solution === "Please complete the previous activity") { solution = "No Solution Given!"; }
        for(var j in defaultSolutions) {
          appendingEvents.push(defaultSolutions[j] + solution);
        }
      }
      while(appendingEvents.length < 6) {
        for(j in defaultSolutions) {
          appendingEvents.push(defaultSolutions[j] + "No Solution Given!");
        }      
      }
      let events = this.state.events.concat(appendingEvents);
      if(addedEvents === null) { addedEvents = []; }
      if(dates.length <= confirmedNumber) { 
        dates = ["", "", "", "", "", "", "", "", "", "", "", "", "", ""]; 
        roster = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      }
      this.setState({
        events: events,
        dates: dates,
        roster: roster,
        addedEvents: addedEvents
      });
    });
  }

  componentWillUnmount() {
    var completed = "1";
    for(var i = 0; i<this.state.dates.length; i++) {
      if ((this.state.dates[i] !== "") || (this.state.roster[i] !== 0)) {
        completed = "2";
      }
      this.props.session.update({ ["allEvents/" + i]: {
        "events": this.state.events[i], 
        "dates": JSON.stringify(this.state.dates[i]),
        "roster": this.state.roster[i],
      }});
    }
    this.props.session.update({ "allRoster/": JSON.stringify(this.state.allRoster) });
    this.props.session.update({ "extraFields/": JSON.stringify(this.state.addedEvents) });
    this.props.updateCompletion(8, completed, 3, true);
  }
  
  close() {
    this.setState({ initialCheck: false });
  }

  handleSelect(index, key, e) {
    var rosterCopy = this.state.roster.slice();
    rosterCopy[index] = key;
    this.setState({ roster: rosterCopy });
  }

  addField() {
    var addedEventsCopy = this.state.addedEvents.slice();
    for(var i = 0; i<addedEventsCopy.length; i++) {
      if(i !== addedEventsCopy[i]) {
        addedEventsCopy.splice(i, 0, i);
        this.setState({ addedEvents: addedEventsCopy });  
        return;
      }
    }
    if (addedEventsCopy.length < 2) { addedEventsCopy.push(addedEventsCopy.length); }
    this.setState({ addedEvents: addedEventsCopy });  
  }

  handleDate(index, date) {
    var dateCopy = this.state.dates.slice();
    dateCopy[index] = date;
    this.setState({ dates: dateCopy });
  }

  removeField(i, index, e) {
    var addedEventsCopy = this.state.addedEvents.slice();
    addedEventsCopy.splice(i, 1);
    var datesCopy = this.state.dates.slice();
    datesCopy[index + 1] = "";
    datesCopy[index] = "";
    var rosterCopy = this.state.roster.slice();
    rosterCopy[index + 1] = 0;
    rosterCopy[index] = 0;
    this.setState({ 
      addedEvents: addedEventsCopy,
      dates: datesCopy,
      roster: rosterCopy,
    });  
  }

  render() {
    var topToolTip = 
    <Tooltip id="topToolTip">
      For each milestone, click or tap Select a Date to choose a date that each milestone will be completed. Click or tap Choose a Team Member to assign that task to a specific team member.
    </Tooltip>;

    var initialCheck =
    <Modal className="activity-modal" show={this.state.initialCheck && this.props.showInstruction} onHide={this.close.bind(this)}>
      <Modal.Body>
        <p className="modal-heading">
          Timeline
        </p>
        <img className="modal-image" src="/images/timeline.png" alt="..."/>
        <br/>
        <br/>
        <p className="instructions-header">Instructions:</p>
        <p className="modal-text">
          For each milestone, click or tap Select a Date to choose a date that each milestone will be completed. Click or tap Choose a Team Member to assign that task to a specific team member.
        </p>
        <br/>
        <Button className="footer-button" bsSize="large" onClick={this.close.bind(this)}>
          Continue
        </Button> 
        <br/>
        <br/>
      </Modal.Body>
    </Modal>

    let labels = 
    <Row key={0} className="selection-grid">
      <Col xs={6} md={4}>
          <span id="timeline-top">Milestones</span>
      </Col>
      <Col xs={6} md={4}>
        <span id="timeline-top">Date</span>
      </Col>
      <Col xs={6} md={4}>
          <span id="timeline-top">Assign to</span>
      </Col>
    </Row>;

    var confirmedFields = [];
    for(var i = 0; i<confirmedNumber; i++) {
      confirmedFields.push(
      <Row key={i} className="selection-grid">
        <Col xs={6} md={4}>
          <div className="timeline-left">
            <p className="timeline-text">{this.state.events[i]}</p>
          </div>
        </Col>
        <Col xs={6} md={4}>
          <div className="picker">
            <DatePicker
              onChange={this.handleDate.bind(this, i)} 
              value={this.state.dates[i]}
            />
          </div>
        </Col>
        <Col xs={6} md={4}>
          <div className="timeline-right">
            <DropdownButton 
              title={this.state.allRoster[this.state.roster[i]]} 
              onSelect={this.handleSelect.bind(this, i)} 
              id="timeline-selector">
              {this.state.allRoster.map((value, index) => (
                <MenuItem key={index} eventKey={index}>{value}</MenuItem>
              ))}
            </DropdownButton>
          </div>
        </Col>
      </Row>);
    }
    var extraFields = [];
    for(i = 0; i<this.state.addedEvents.length; i++) {
      let index = 2 * this.state.addedEvents[i] + confirmedNumber;
      extraFields.push(
      <div>
        <Row key={i} className="selection-grid">
          <Col xs={6} md={4}>
            <div className="formgroup-margin">
              <FormGroup>
                <InputGroup>
                  <InputGroup.Button>
                    <Button bsStyle="danger" onClick={this.removeField.bind(this, i, index)}><Glyphicon glyph="remove" /></Button>
                  </InputGroup.Button>
                  <FormControl style={{zIndex: 0}} className="timeline-form" type="text" value={this.state.events[index]} readOnly/>
                </InputGroup>
              </FormGroup>
            </div>
          </Col>
          <Col xs={6} md={4}>
            <div className="picker">
              <DatePicker
                onChange={this.handleDate.bind(this, index)} 
                value={this.state.dates[index]}
              />
            </div>
          </Col>
          <Col xs={6} md={4}>
            <div className="timeline-right">
              <DropdownButton 
                title={this.state.allRoster[this.state.roster[index]]} 
                onSelect={this.handleSelect.bind(this, index)} 
                id="timeline-selector">
                {this.state.allRoster.map((value, i) => (
                  <MenuItem key={i} eventKey={i}>{value}</MenuItem>
                ))}
              </DropdownButton>
            </div>
          </Col>
        </Row>
        <Row key={index+1} className="selection-grid">
          <Col xs={6} md={4}>
            <div align="right" className="timeline-left">
              <p className="timeline-text">{this.state.events[index+1]}</p>
            </div>
          </Col>
          <Col xs={6} md={4}>
            <div className="picker">
              <DatePicker
                onChange={this.handleDate.bind(this, index+1)} 
                value={this.state.dates[index+1]}
              />
            </div>
          </Col>
          <Col xs={6} md={4}>
            <div className="timeline-right">
              <DropdownButton 
                title={this.state.allRoster[this.state.roster[index+1]]} 
                onSelect={this.handleSelect.bind(this, index+1)} 
                id="timeline-selector">
                {this.state.allRoster.map((value, i) => (
                  <MenuItem key={i} eventKey={i}>{value}</MenuItem>
                ))}
              </DropdownButton>
            </div>
          </Col>
        </Row>
      </div>);
    }

    return (
      <div className="page-shift">
        <Jumbotron className="jumbotron-dashboard">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>
                    
          <div>
            <div className="information-title">
              <p id="activity-text">Timeline</p>
            </div>
            <div className="timeline-grid"> 
              <Grid>
                {labels}
                {confirmedFields}
                {extraFields}
                <Row>
                  <Col xs={4} md={3}>
                  </Col>
                  <Col xs={10} md={6}></Col>
                  <Col xs={4} md={3}>
                    {this.state.addedEvents.length < 2 ? 
                    (<div align="left" className="solutions-added-btns">
                      <Button className="roster-button" onClick={this.addField.bind(this)}>Add Another Solution</Button>
                    </div>)
                    : null}
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>

          {initialCheck}

          <Footer pageName="timeline" setEdit={this.props.setEdit} routerKey={this.props.routerKey} history={this.props.history} />

        </Jumbotron>
      </div>
    )
  }
};

export default Timeline;
