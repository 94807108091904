import React from 'react';
import {
  Button,
  Modal,
  Glyphicon,
  Alert,
  FormGroup, 
  InputGroup,
  FormControl,
  Jumbotron,
} from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import ReactGA from './../../ga.js';

const $ = require('jquery');
const styling = {
  fontSize: '32px',
}

class Project extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      alertShowing: false,
      showContinue: false,
      showCode: false,
      projectCode: this.props.projectCode,
      code: "",
      emails: [""],
      sentEmail: false,
      newProject: false,
    };
  }

  back() {
    this.props.history.push('/user');
    ReactGA.event({
      category: 'Project',
      action: 'Back',
      label: 'Back to User',
    });
  }

  componentDidMount() {
    this.props.setShowProgress(false);
  }

  componentWillUnmount() {
    this.props.setShowProgress(true);
  }

  continueProject() {
    this.setState({ showContinue: true });
    ReactGA.event({
      category: 'Project',
      action: 'Continue',
      label: 'Continue a project pressed',
    });
  }

  newProject() {
    this.setState({ showCode: true });
    this.props.addNewUser();
    this.props.setReturningUser(true);
    ReactGA.event({
      category: 'Project',
      action: 'Continue',
      label: 'Returning User with new project',
    });
  }

  closeCode() {
    this.setState({ newProject: true, showCode: false });
  }

  close() {
    this.props.history.push('/welcomevideo');
  }

  continue() {
    var inputCode = this.state.code;
    inputCode = inputCode.replace(/\s/g,'');
    if (this.props.addedOldUser(inputCode)) {
      this.cancel();
      this.props.history.push('/dashboard');
      ReactGA.event({
        category: 'Project',
        action: 'Continue',
        label: 'Valid project code',
      });
    } else {
      this.setState({ alertShowing: true });
      ReactGA.event({
        category: 'Project',
        action: 'Continue',
        label: 'Invalid project code',
      });
    }
  }

  cancel() {
    this.setState({ showContinue: false })
  }

  handleChange(evt) {
    this.setState({ code: evt.target.value });
  }

  handleAlertHide() {
    this.setState({ alertShowing: false });
  }

  handleEmail(index, e) {
    var emailsCopy = this.state.emails.slice();
    emailsCopy[index] = e.target.value;
    this.setState({ emails: emailsCopy, sentEmail: false });
  }

  removeField(index, e) {
    var emailsCopy = this.state.emails.slice();
    emailsCopy.splice(index, 1);
    this.setState({ emails: emailsCopy });  
  }

  addField() {
    var emailsCopy = this.state.emails.slice();
    emailsCopy.push("");
    this.setState({ emails: emailsCopy });
  }

  sendEmail() {
    if(this.state.sentEmail) { return; }
    ReactGA.event({
      category: 'Project',
      action: 'Email',
      label: this.state.emails.length + ' emails sent',
    });
    var formData = new FormData();
    formData.append("code", this.props.projectCode);
    formData.append("emails", this.state.emails);
    $.ajax({
      type: 'POST',
      url: 'https://php.pcforme.org/codesender.php',
      data: formData,
      dataType: 'json',
      contentType: false,
      processData: false,
    });  
    this.setState({ 
      sentEmail: true, 
      showCode: false, 
    });
  }

  setRouterKey() {
    ReactGA.event({
      category: 'Project',
      action: 'Continue',
      label: 'Activities only project selected',
    });
    this.props.setRouterKey("ActivitiesOnly");
    this.props.history.push('/information');
  }

  render() {
    var newProjectModal =         
    <Modal show={this.state.newProject} onHide={this.close.bind(this)}>
      <Modal.Header>
        <Modal.Title className="green-header">How would you like to proceed?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="activity-modal">
          <Button className="project-button" onClick={this.close.bind(this)}>Activities and Videos (Recommended)</Button>
          <br/>
          <br/>
          <Button className="project-button" onClick={this.setRouterKey.bind(this)}>Activities Only</Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Close</Button>
      </Modal.Footer>
    </Modal>;
    
    var projetCodeText = "Your Project Code: " + this.props.projectCode;
    var codeModal =         
    <Modal show={this.state.showCode} onHide={this.closeCode.bind(this)}>
      <Modal.Body>
        <span id="show-project-code">{projetCodeText}</span>
        <h5>
          When you start a new project, that project is assigned a unique 6-digit code that allows you to return to your project at any time.  To return to your work, simply enter this code at the home screen next time you visit BiteSizeQI.  
        </h5> 
        <h5>
          Enter your email in the box below and BiteSizeQI will email you your project code to save for later use.  Remember, if you do not have your project code, you cannot retrieve your work.  
        </h5>
        {this.state.emails.map((value, i) => (
          <FormGroup key={i}>
            <InputGroup>
              <FormControl type="text" onChange={this.handleEmail.bind(this, i)} value={value} placeholder="Insert Email"/>
              <InputGroup.Button>
                <Button bsStyle="danger" onClick={this.removeField.bind(this, i)}><Glyphicon glyph="remove" /></Button>
              </InputGroup.Button>
            </InputGroup>
          </FormGroup>
        ))}
        <Button className="user-button" onClick={this.addField.bind(this)}>Add Email Address</Button>
        <Button className="user-button user-send" onClick={this.sendEmail.bind(this)}>Send Email</Button>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.closeCode.bind(this)}>Close</Button>
      </Modal.Footer>
    </Modal>;

    var continueModal =         
    <Modal show={this.state.showContinue} onHide={this.cancel.bind(this)}>
      <Modal.Header>
        <Modal.Title className="green-header bold">Continue Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="black-text">Please enter your project code. Project codes are case sensitive.</h4>
        <FormControl className="activity-input" bsSize="large" type="text" onChange={this.handleChange.bind(this)} value={this.state.code} placeholder="Insert Code"/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.cancel.bind(this)}>Cancel</Button>
        <Button onClick={this.continue.bind(this)}>Continue</Button>
      </Modal.Footer>
    </Modal>;

    var sentEmail = 
    <Modal show={this.state.sentEmail} onHide={this.close.bind(this)}>
      <Modal.Body>
        <div>
          <Alert bsStyle="success">
            Your email was sent!
          </Alert>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Continue</Button>
      </Modal.Footer>
    </Modal>;

    return (
      <div className="page-center">
        <Jumbotron className="jumbotron-nav">
          <div className="two-button-shift">
            <br/>
            <div className="inline intro-buttons">
              <Button className="gray-btn" bsSize="large" block onClick={this.newProject.bind(this)}><b>New Project</b></Button>
              <br/>
              <Button className="gray-btn next-block" bsSize="large" block onClick={this.continueProject.bind(this)}><b>Continue a Project</b></Button>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            {continueModal}
            {codeModal}
            {newProjectModal}
            {sentEmail}
            <div className="footer-buttons">
              <Button className="footer-button left-footer-button" bsSize="large" onClick={this.back.bind(this)}>
                <div className="footer-left-image">
                  <Icon style={styling}>keyboard_arrow_left</Icon>
                </div>
                &nbsp; &nbsp; Back
              </Button>  
            </div>

          </div>
        </Jumbotron>
      </div>
    )
  }
};

export default Project;
