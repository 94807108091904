import React from 'react'
import {
  Button,
  FormControl,
  InputGroup,
  FormGroup,
  Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router';
import Header from './global-components/Header'
import ProgressBar from './global-components/ProgressBar'
import ReactGA from './../ga';
import firebaseHelper from './helpers/firebaseHelper.jsx';
import storageHelper from './helpers/storageHelper.jsx'
import MainRouter from './MainRouter.jsx'
import ErrorNotFound from './ErrorNotFound.jsx';

class Main extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showCode: true,
      // showCode: false, // TODO remove in production
      showError: false,
      allCodes: [],
      allPages: ["selection", "problem", "aim", "roster", "measurement",
        "cause", "solutions", "prioritization", "timeline", "mapping", "chart"],
      projectCode: "",
      projectTitle: "",
      activityStatus: ["0", "0", "0", "0", "0", "0", "0", "0", "0"],
      roster: [""],
      problemValues: [],
      aimValues: [],
      problemStatement: "",
      what: "",
      causes: [],
      lastBite: "",
      goingForward: true,
      edit: false,
      inputCodes: [],
      resetClient: () => { },
      updateLogo: () => { },
      setEdit: () => { },
      setProjectTitle: () => { },
      setProjectCode: () => { },
      setShowProgress: () => { },
      setProgressExpansion: () => { },
      addNewUser: () => { },
      addedOldUser: () => { },
      updateCompletion: () => { },
      routerKey: "AllPages",
      returningUser: false,
      code: "",
      showProgress: true,
      progressExpanded: true,
      incorrectPassword: false,
      logoStyling: null,
      logoImage: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    const currentPage = this.props.location.pathname;
    const nextPage = nextProps.location.pathname;
    if (currentPage !== nextPage) { ReactGA.pageview(nextPage); }
  }

  updateLogo() {
    const database = firebaseHelper.getClientDatabase("whitelabel");
    database.on('value', snapshot => {
      if (!snapshot) { return; }
      const value = snapshot.val();
      if (!value) { return; }
      const image = value && value.image ? value.image : null;
      const styling = value && value.styling ? value.styling : null;
      this.setState({
        logoImage: image,
        logoStyling: styling,
      });
    });
  }

  getURLClient() {
    const currentURL = new URL(window.location.href);
    const pathname = currentURL.pathname;
    const urlElements = pathname.split("/").filter(e => e !== "");
    var client = "bsqi";
    if (urlElements.length !== 0) {
      const validURL = urlElements[0];
      if (validURL) { client = validURL; }
    }
    storageHelper.setClient(client);
    return client;
  }

  resetClient() {
    const database = firebaseHelper.getDatabase();
    database.on('value', snapshot => {
      const clients = Object.keys(snapshot.val());
      const client = this.getURLClient();
      if (clients.indexOf(client) === -1) {
        this.setState({ showError: true });
      }
    });
    this.updateLogo();
  }

  componentWillMount() {
    this.getURLClient();
    this.resetClient();
    this.init();
    const currentPage = this.props.location.pathname;
    if (currentPage) { ReactGA.pageview(currentPage); }
  }

  getInputCodes() {
    let dataRef = firebaseHelper.getClientDatabase('input_codes');
    dataRef.on('value', snapshot => {
      const values = [];
      snapshot.forEach((childSnapshot) => {
        let val = childSnapshot.val();
        values[childSnapshot.key] = val;
      })
      this.setState({
        inputCodes: values,
      });
    });
  }

  componentDidMount() {
    this.setAllCodes();
    this.getInputCodes();
    this.initUser();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.projectCode !== this.state.projectCode) {
      this.initUser();
    }
  }

  initUser() {
    this.setDashboard();
    this.setAim();
    this.setProblem();
    this.setRoster();
    this.getOldProjectTitle();
    this.getRouterKey();
    this.getReturningUser();
  }

  getRouterKey() {
    var db = firebaseHelper.getClientDatabase("project_codes/" + this.state.projectCode + "/dashboard");
    db.on('value', snapshot => {
      let routerKey = snapshot.child("routerKey").val();
      this.setState({ routerKey: routerKey });
    });
  }

  getReturningUser() {
    var db = firebaseHelper.getClientDatabase("project_codes/" + this.state.projectCode + "/dashboard");
    db.on('value', snapshot => {
      let returningUser = snapshot.child("isReturning").val();
      if (returningUser === null || returningUser === undefined) {
        return;
      }
      this.setState({ returningUser: returningUser });
    });
  }

  setRoster() {
    var db = firebaseHelper.getClientDatabase("project_codes/" + this.state.projectCode + "/roster");
    db.child("team").on('value', snapshot => {
      if (snapshot === null) { return; }
      var names = [];
      snapshot.forEach((childSnapshot) => {
        for (var key in childSnapshot.val()) {
          var name = childSnapshot.val()[key];
          if (name !== "") {
            names[childSnapshot.key] = name;
          }
        }
      })
      this.setState({ roster: names });
    });
  }

  setAllCodes() {
    var codesArray = [];
    let dataRef = firebaseHelper.getClientDatabase('project_codes');
    dataRef.on('value', snapshot => {
      snapshot.forEach(function (childSnapshot) {
        codesArray.push(childSnapshot.key);
      })
    });
    this.setState({ allCodes: codesArray });
  }

  setAim() {
    var db = firebaseHelper.getClientDatabase("project_codes/" + this.state.projectCode + "/aim");
    db.on('value', snapshot => {
      var values = [];
      snapshot.forEach((childSnapshot) => {
        values[childSnapshot.key] = childSnapshot.val();
      })
      this.setState({ aimValues: values });
    });
  }

  setProblem() {
    var db = firebaseHelper.getClientDatabase("project_codes/" + this.state.projectCode + "/problem");
    db.on('value', snapshot => {
      var values = [];
      snapshot.forEach((childSnapshot) => {
        values[childSnapshot.key] = childSnapshot.val();
      })
      this.setState({ problemValues: values });
      var setProblem = (values.length !== 5) ? false : true;
      for (var i = 0; i < values.length; i++) {
        if (values[i] === "") {
          setProblem = false;
        }
      }
      let what = values[3] === "" ? "No \"What\" in Problem Statement" : values[3];
      let problem = setProblem ?
        (values[0] + " " + values[1] + " " + values[2] + " " + values[3] + ". " + values[4] + ".")
        : "Please complete the Problem Statement Activity";
      this.setState({
        what: what,
        problemStatement: problem
      });
    });
  }

  setEdit(execute = false) {
    if (execute) {
      var db = firebaseHelper.getClientDatabase("project_codes/" + this.state.projectCode + "/dashboard");
      db.update({ "edit": false });
    }
    return this.state.edit;
  }

  setDashboard() {
    var db = firebaseHelper.getClientDatabase("project_codes/" + this.state.projectCode + "/dashboard");
    db.on('value', snapshot => {
      let status = snapshot.child("activityStatus");
      var values = ["0", "0", "0", "0", "0", "0", "0", "0", "0"];
      status.forEach((childSnapshot) => {
        values[childSnapshot.key] = childSnapshot.val();
      })
      let lastBite = snapshot.child("lastBite").val();
      let edit = snapshot.child("edit").val();
      let goingForward = snapshot.child("goingForward").val();
      this.setState({
        edit: edit,
        lastBite: lastBite,
        goingForward: goingForward,
        activityStatus: values,
      });
    });
  }

  codeGenerator() {
    var result = '';
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = 6; i > 0; --i) {
      result += chars[Math.round(Math.random() * (chars.length - 1))];
    }
    return result;
  }

  addNewUser() {
    var result = this.codeGenerator();
    while (this.state.allCodes.indexOf(result) !== -1) {
      result = this.codeGenerator();
    }
    var newCode = firebaseHelper.getClientDatabase("project_codes/" + result);
    this.state.allPages.forEach((value) => (
      newCode.update({
        [value]: ""
      })
    ));
    newCode.update({
      date_created: new Date(),
    });
    storageHelper.setProjectCode(result);
    this.setState({ projectCode: result });
  }

  init() {
    this.setState({
      resetClient: this.resetClient.bind(this),
      updateLogo: this.updateLogo.bind(this),
      setProjectTitle: this.setProjectTitle.bind(this),
      setProjectCode: this.setProjectCode.bind(this),
      setShowProgress: this.setShowProgress.bind(this),
      setProgressExpansion: this.setProgressExpansion.bind(this),
      addedOldUser: this.addedOldUser.bind(this),
      addNewUser: this.addNewUser.bind(this),
      updateCompletion: this.updateCompletion.bind(this),
      setEdit: this.setEdit.bind(this),
      setReturningUser: this.setReturningUser.bind(this),
      setRouterKey: this.setRouterKey.bind(this),
      projectCode: storageHelper.getProjectCode(),
    });
  }

  setProgressExpansion(value) {
    this.setState({ progressExpanded: value });
  }

  setProjectCode(value) {
    this.setState({ projectCode: value });
  }

  setShowProgress(value) {
    this.setState({
      showProgress: value,
    });
  }

  setProjectTitle(value) {
    this.setState({ projectTitle: value });
  }

  setReturningUser(isReturning) {
    let projectCode = storageHelper.getProjectCode();
    var db = firebaseHelper.getClientDatabase("project_codes/" + projectCode + "/dashboard");
    db.update({ isReturning: isReturning });
  }

  setRouterKey(routerKey) {
    let projectCode = storageHelper.getProjectCode();
    var db = firebaseHelper.getClientDatabase("project_codes/" + projectCode + "/dashboard");
    db.update({ routerKey: routerKey });
  }

  getOldProjectTitle() {
    var db = firebaseHelper.getClientDatabase("project_codes/" + this.state.projectCode + "/selection");
    db.on('value', snapshot => {
      this.setState({ projectTitle: snapshot.child("2").val() });
    });
  }

  addedOldUser(code) {
    if (this.state.allCodes.indexOf(code) !== -1) {
      this.getOldProjectTitle();
      this.setState({ projectCode: code });
      storageHelper.setProjectCode(code);
      return true;
    } else {
      return false;
    }
  }

  updateCompletion(index, val, lastBite, goingForward = null) {
    var db = firebaseHelper.getClientDatabase("project_codes/" + this.state.projectCode + "/dashboard");
    db.update({ lastBite: lastBite, goingForward: goingForward });
    db.update({ ["activityStatus/" + index]: val });
  }

  isCodeValid(code) {
    return this.state.inputCodes.map(e => e.code).includes(code);
  }

  close() {
    if (this.isCodeValid(this.state.code)) {
      ReactGA.event({
        category: 'Main',
        action: 'Input',
        label: 'Valid code to enter website - ' + this.state.code,
      });
      this.setState({
        showCode: false,
      });
    } else {
      this.setState({
        incorrectPassword: true,
      });
    }
  }

  handleCode(e) {
    this.setState({ code: e.target.value });
  }

  handleKeyPress(target) {
    if (target.charCode === 13) {
      this.close();
    }
  }

  goHome() {
    this.props.history.push("/");
  }

  render() {
    const staticPages = ["", "about", "contact", "terms", "privacy", "admin-portal"];
    var fullLink = window.location.href.split("/");
    var route = fullLink[fullLink.length - 1];
    var onStaticPage = (staticPages.indexOf(route) > -1) ? true : false;
    var actuallyShowCode = this.state.showCode && !onStaticPage ? true : false;
    localStorage.setItem('codeModal', actuallyShowCode);

    var codeModal =
      <Modal className="code-modal" show={actuallyShowCode} onHide={this.close.bind(this)}>
        <Modal.Header>
          <Modal.Title>Please enter a password to continue</Modal.Title>
        </Modal.Header>
        <Modal.Body className="code-input">
          BiteSizeQI is currently only available to users with a password.
        <FormGroup>
            <InputGroup>
              <FormControl onKeyPress={this.handleKeyPress.bind(this)} style={{ zIndex: 0 }} bsSize="large" type="password" onChange={this.handleCode.bind(this)} value={this.state.code} />
              <InputGroup.Button>
                <Button bsStyle="primary" bsSize="large" onClick={this.close.bind(this)} >Submit</Button>
              </InputGroup.Button>
            </InputGroup>
          </FormGroup>
          {
            this.state.incorrectPassword ? <div className="main-error-text">
              You entered an incorrect password.
          </div> : null
          }
        </Modal.Body>
      </Modal>;

    const publicPages = ["", "about", "contact", "terms", "privacy", "user", "project", "admin-portal"];
    var onPrivatePage = publicPages.indexOf(route) === -1;
    const invalidProjectCode = code => {
      return code === "" || code === null || code === undefined || this.state.allPages.indexOf(code) !== -1;
    }
    var shouldGoHome = onPrivatePage && invalidProjectCode(this.state.projectCode);
    var noCodeError =
      <Modal className="code-modal" show={shouldGoHome}>
        <Modal.Body className="no-code-modal">
          Oops! Something happened and you don't have a session project code. Click the button below to go to the home page!
      </Modal.Body>
        <Modal.Footer>
          <Button className="green-button" bsStyle="success" onClick={this.goHome.bind(this)}>Go To Home Page</Button>
        </Modal.Footer>
      </Modal>;

    var expanded = this.state.progressExpanded;
    var showProgress = this.state.showProgress;
    var sidebarClass;
    var contentClass;
    if (expanded && showProgress) {
      sidebarClass = "main-sidebar sidebar-expanded";
      contentClass = "main-content content-expanded";
    } else {
      sidebarClass = "main-sidebar sidebar-contracted";
      contentClass = "main-content content-contracted";
    }

    return (
      <div>
        {this.state.showError ? <ErrorNotFound /> :
          <div>
            <Header
              logoImage={this.state.logoImage}
              logoStyling={this.state.logoStyling}
              projectTitle={this.state.projectTitle}
              projectCode={this.state.projectCode} />

            {showProgress ?
              <div className={sidebarClass}>
                <ProgressBar
                  returningUser={this.state.returningUser}
                  activityStatus={this.state.activityStatus}
                  setProgressExpansion={this.state.setProgressExpansion}
                  expanded={this.state.progressExpanded} />
              </div> : null
            }

            <div className={contentClass}>
              <MainRouter {...this.state} />
            </div>
            {codeModal}
            {noCodeError}
          </div>}
      </div>
    )
  }
}

export default withRouter(Main);
