import React from 'react';
import {
  Button,
  Tooltip,
  OverlayTrigger,
  Modal,
  Glyphicon,
  Jumbotron,
} from 'react-bootstrap';
import AllVideos from '../../helpers/AllVideos.jsx';
import Footer from '../../global-components/Footer';

class ProblemVideo extends React.Component {

  constructor(props){
    super(props);
    this.state = { alertShowing: true };
  }

  close() {
    this.setState({ alertShowing: false });
  }

  render(){
    var topToolTip = 
    <Tooltip id="topToolTip">
    Can't see the video? Click on the Menu and select About to for technical help. 
Can't hear the video? Make sure that your volume is turned up.
    </Tooltip>;
    const showAlert = this.state.alertShowing && this.props.showInstruction;
    var alert =
    <Modal className="center-modal" show={showAlert} onHide={this.close.bind(this)}>
      <br />
      <div className="green-line"></div>
      <Modal.Body>
        <p className="video-modal-text">
          You have completed a title for your project and familiarized yourself with the Project Blueprint. Now we can begin building your Project Blueprint with a Problem Statement.
        </p>
      </Modal.Body>
      <div className="green-line"></div>
      <br />
      <br />
      <br />
      <Button className="video-modal-footer" onClick={this.close.bind(this)}>OK</Button>
    </Modal>

    let videoName = showAlert ? AllVideos["problem"] : AllVideos["problemAuto"];

    return (
      <div className="page-shift">
          <Jumbotron className="jumbotron-video">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>

          <div className="video-title">
            <span id="video-title-text">Problem Statement</span>
          </div>
          <div className="embedded-video">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe src={videoName} title="select" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen="true"></iframe>
            </div>
          </div>

          {this.props.returningUser || !showAlert ? null : alert}

          <div className="footer-buttons">
            <Footer pageName="problemvideo" setEdit={false} routerKey={this.props.routerKey} history={this.props.history} />  
          </div>

        </Jumbotron>
      </div>
    )
  }
};

export default ProblemVideo;
