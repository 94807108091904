import React from 'react';

const ErrorNotFound = () => 
  <div style={{ "text-align": "center" }}>
    <h3>404 page not found</h3>
    <p>We are sorry but the page you are looking for does not exist.</p>
    <p>Please recheck your url or try going to http://www.bitesizeqi.org</p>
  </div>

export default ErrorNotFound;
