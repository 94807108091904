import React from 'react';
import {
  Button,
  DropdownButton,
  MenuItem,
  Grid,
  Row,
  Col,
  FormControl,
  InputGroup,
  FormGroup,
  Tooltip,
  Modal,
  OverlayTrigger,
  Glyphicon,
  Jumbotron,
} from 'react-bootstrap';
import Footer from '../global-components/Footer';

class Roster extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      initialCheck: true,
      showSave: false, 
      showExamples: false,
      allRoles: ["Select Role", "Project Champion", "Project Lead", "QI Team"],
      confirmedNumber: 3,
      roles: ["0", "0", "0"],
      names: ["", "", ""]
    };
  }

  componentWillMount() {
    this.props.session.on('value', snapshot => {
      var roles = [];
      var names = [];
      snapshot.child("team").forEach((childSnapshot) => {
        for(var key in childSnapshot.val()) {
          roles[childSnapshot.key] = key;
          names[childSnapshot.key] = childSnapshot.val()[key];
        }
      })
      if(roles.length >= 3) {
        this.setState({ roles: roles, names: names });
      }
    });
  }
  
  componentWillUnmount() {
    var completed = "1";
    this.props.session.set(null);
    for(var i = 0; i<this.state.roles.length; i++) {
      if (!(this.state.roles[i] === "0") || !(this.state.names[i] === "")) {
        completed = "2";
      }
      this.props.session.update({ ["team/" + i]: {[this.state.roles[i]]: this.state.names[i]} });
    }
    this.props.session.update({ allRoles: JSON.stringify(this.state.allRoles) });
    this.props.updateCompletion(4, completed, 1, true);
  }

  close() {
    this.setState({ showExamples: false, showSave: false, initialCheck: false });
  }

  openExamples() {
    this.setState({ showExamples: true });
  }

  openSave() {
    this.setState({ showSave: true });
  }

  handleSelection(index, key, e) {
    var rolesCopy = this.state.roles.slice();
    rolesCopy[index] = key;
    this.setState({ roles: rolesCopy });
  }

  handleChange(index, e) {
    var namesCopy = this.state.names.slice();
    namesCopy[index] = e.target.value;
    this.setState({ names: namesCopy });
  }

  addField() {
    var namesCopy = this.state.names.slice();
    namesCopy.push("");
    var rolesCopy = this.state.roles.slice();
    rolesCopy.push(0);
    this.setState({ names: namesCopy, roles: rolesCopy });
  }

  removeField(index, e) {
    var namesCopy = this.state.names.slice();
    namesCopy.splice(index, 1);
    var rolesCopy = this.state.roles.slice();
    rolesCopy.splice(index, 1);
    this.setState({ names: namesCopy, roles: rolesCopy });  
  }

  render(){
    var topToolTip = 
    <Tooltip id="topToolTip">
      Click or tap in each box and type in the names of the members of your team. Click the Select Role button to define their role on the QI team. If you want to add more team members, click Add Another Member. This can be edited later if necessary. 
    </Tooltip>;

    var initialCheck =
    <Modal className="activity-modal" show={this.state.initialCheck && this.props.showInstruction} onHide={this.close.bind(this)}>
      <Modal.Body>
        <p className="modal-heading">
          Team Roster
        </p>
        <img className="modal-image" src="/images/roster.png" alt="..."/>
        <br/>
        <br/>
        <p className="instructions-header">Instructions:</p>
        <p className="modal-text">
          Click or tap in each box and type in the names of the members of your team. Click the Select Role button to define their role on the QI team. If you want to add more team members, click Add Another Member. This can be edited later if necessary. 
        </p>
        <br/>
        <Button className="footer-button" bsSize="large" onClick={this.close.bind(this)}>
          Continue
        </Button> 
        <br/>
        <br/>
      </Modal.Body>
    </Modal>

    var foundChampion = false;
    var foundLead = false;
    for (var role of this.state.roles) {
      if(role === "1") { foundChampion = true; } 
      else if(role === "2") { foundLead = true; }
    }
    var menuItems = [];
    for (var index in this.state.allRoles) {
      var value = this.state.allRoles[index];
      var isDisabled = false;
      if(index === "1") { isDisabled = foundChampion; } 
      else if(index === "2") { isDisabled = foundLead; }
      menuItems.push(
        <MenuItem className="roster-dropdown" key={index} eventKey={index} disabled={isDisabled}>
          {value}
        </MenuItem>)
    }
    
    var confirmedFields = [];
    for(var i = 0; i<this.state.confirmedNumber; i++) {
      var autoFocus = (i === 0) ? true : false;
      confirmedFields.push(
      <Row key={i} className="selection-grid">
        <Col xs={9} md={6}>
          <FormControl autoFocus={autoFocus} type="text" onChange={this.handleChange.bind(this, i)} value={this.state.names[i]} />
        </Col>
        <Col xs={9} md={6}>
          <DropdownButton 
            title={this.state.allRoles[this.state.roles[i]]} 
            onSelect={this.handleSelection.bind(this, i)} 
            id="roster-selector">
            {menuItems}
          </DropdownButton>
        </Col>
      </Row>);
    }

    var extraFields = [];
    for(i = this.state.confirmedNumber; i<this.state.names.length; i++) {
      extraFields.push(
      <Row key={i} className="roster-extra">
        <Col xs={9} md={6}>
          <FormGroup>
            <InputGroup>
              <FormControl style={{zIndex: 0}} type="text" onChange={this.handleChange.bind(this, i)} value={this.state.names[i]} />
              <InputGroup.Button>
                <Button bsStyle="danger" onClick={this.removeField.bind(this, i)}><Glyphicon glyph="remove" /></Button>
              </InputGroup.Button>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xs={9} md={6}>
          <DropdownButton 
            title={this.state.allRoles[this.state.roles[i]]} 
            onSelect={this.handleSelection.bind(this, i)} 
            id="roster-selector">
            {menuItems}
          </DropdownButton>
        </Col>
      </Row>);
    }

    return (
      <div className="page-shift">
        <Jumbotron className="jumbotron-dashboard">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>

          <div>
            <div className="information-title">
              <p id="activity-text">Team Roster</p>
            </div>
            <div className="roster-sub">
              Who is on your team?
            </div>

            <div className="roster-grid"> 
              <Grid>
                <Row>
                  <Col xs={1} md={1}>
                    <div className="roster-name">Name</div>
                  </Col>
                  <Col  xs={17} md={11}></Col>
                </Row>
                {confirmedFields}
                {extraFields}
                <Row>
                  <Col xs={17} md={8}></Col>
                  <Col xs={1} md={1}>
                    <div className="add-field">
                      <Button className="roster-button" onClick={this.addField.bind(this)}>Add Another Member</Button>
                    </div>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>

          {initialCheck}

          <Footer pageName="roster" setEdit={this.props.setEdit} routerKey={this.props.routerKey} history={this.props.history} />
          
        </Jumbotron>
      </div>
    )
  }
};

export default Roster;
