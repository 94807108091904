import React from 'react';
import {
  Button,
  Row,
  Col,
  Jumbotron
} from 'react-bootstrap';
import ReactGA from './../../ga.js';

const routing = [
  {mappingvideo: "Process Maps", chartvideo: "Run Charts"},
//   {blueprintvideo: "Project Blueprint", problemvideo: "Problem Statement", aimvideo: "Aim Statement"},
//   {rostervideo: "Team Roster", nextvideo: "Next Steps", measurementvideo: "Measurement Plan"},
//   {causevideo: "Cause and Effect", solutionsvideo: "Solutions and Prioritization", timelinevideo: "Timeline"},
];

class AdvancedTools extends React.Component {

  moveTo(page) {
    ReactGA.event({
      category: 'Advanced Tools',
      action: 'Check All Tools',
      label: 'Check Tools: ' + page,
    });
    this.props.history.push('/' + page);
  }

  render() {
    var content = [];
    for (const section of routing) {  
      var currentSection = [];
      for (const link of Object.keys(section)) {
        const buttonText = section[link];
        currentSection.push(
          <Col xs={9} md={6}>
            <Button className="advanced-tools-button" bsSize="large" onClick={this.moveTo.bind(this, link)}>{buttonText}</Button>
          </Col>
        );
      }; 
      content.push(
        <div>
          <Row key={0} className="advanced-tools-grid">
            {currentSection}
          </Row>
        </div>
      );
    } 
    return (
      <div className="page-shift">
        <Jumbotron style={{ width: "85%", }} className="jumbotron-dashboard">
            <div className="information-title">
              <p id="activity-text">Advanced Tools</p>
            </div>
            {content}
            <br/>
            <br/>
            <br/>
            <Row key={0} className="videos-grid">
                <Col xs={3} md={3}>
                    <Button className="footer-button" bsSize="large" onClick={this.moveTo.bind(this, "dashboard")}>Go to Dashboard</Button>
                </Col>
                <Col xs={12} md={6}>
                </Col>
                <Col xs={3} md={3}>
                    <Button className="footer-button" bsSize="large" onClick={this.moveTo.bind(this, "blueprint")}>Go to Blueprint</Button>
                </Col>
            </Row>
        </Jumbotron>
      </div>
    )
  }
};

export default AdvancedTools;
