import React from 'react';
import {
  Button,
  FormControl,
  Modal,
  DropdownButton,
  Grid,
  Row,
  Col,
  InputGroup,
  MenuItem,
  FormGroup,
  OverlayTrigger,
  Tooltip,
  Glyphicon,
  Jumbotron
} from 'react-bootstrap';
import Footer from '../global-components/Footer';

const valueMapper = {
  "3 – Neither": 3,
  "5 – Very Likely": 5,
  "4 – Somewhat Likely": 4,
  "2 - Somewhat unlikely": 2,
  "1 – Very Unlikely": 1,
  "Select Likelihood": 0,
  "5 – Very Easy": 5,
  "4 – Somewhat Easy": 4,
  "2 – Somewhat Difficult": 2,
  "1 – Very Difficult": 1,
  "Select Difficulty": 0,
}

class Solutions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      initialCheck: true,
      potential: ["", "", "", "", ""],
      allCauses: ["Select a Cause"],
      likelihoodScale: ["Select Likelihood", "5 – Very Likely", "4 – Somewhat Likely",
        "3 – Neither", "2 - Somewhat unlikely", "1 – Very Unlikely"],
      difficultyScale: ["Select Difficulty", "5 – Very Easy", "4 – Somewhat Easy",
        "3 – Neither", "2 – Somewhat Difficult", "1 – Very Difficult"],
      confirmedNumber: 5,
      causes: ["0", "0", "0", "0", "0"],
      likelihood: ["0", "0", "0", "0", "0"],
      difficulty: ["0", "0", "0", "0", "0"],
    };
  }

  setCauses() {
    this.props.causeSession.on('value', snapshot => {
      var causes = [];
      let subValues = JSON.parse(snapshot.child("subValues").val());
      let checkValues = JSON.parse(snapshot.child("checkValues").val());
      if (checkValues === null) { return; }
      for (var i in checkValues) {
        for (var j in checkValues[i]) {
          if (checkValues[i][j] && subValues[i][j] !== "") {
            causes.push(subValues[i][j]);
          }
        }
      }
      var allCauses = [this.state.allCauses[0]].concat(causes);
      this.setState({ allCauses: allCauses });
    });
  }

  componentWillMount() {
    this.setCauses();

    this.props.session.child("allSolutions").on('value', snapshot => {
      var potential = [];
      var causes = [];
      var likelihood = [];
      var difficulty = [];
      snapshot.forEach((childSnapshot) => {
        var index = childSnapshot.key;
        var dataMap = childSnapshot.val();
        potential[index] = dataMap["potential"];
        causes[index] = dataMap["causes"];
        likelihood[index] = dataMap["likelihood"];
        potential[index] = dataMap["potential"];
        difficulty[index] = dataMap["difficulty"];
      })
      if (potential.length >= 5) {
        this.setState({
          potential: potential,
          causes: causes,
          likelihood: likelihood,
          difficulty: difficulty,
        });
      }
    });
  }

  componentWillUnmount() {
    var completed = "1";
    this.props.session.set(null);
    for (var i = 0; i < this.state.potential.length; i++) {
      if ((this.state.difficulty[i] !== "0") || (this.state.potential[i] !== "")
        || (this.state.causes[i] !== "0") || (this.state.likelihood[i] !== "0")) {
        completed = "2";
      }
      this.props.session.update({
        ["allSolutions/" + i]: {
          "potential": this.state.potential[i],
          "causes": this.state.causes[i],
          "likelihood": this.state.likelihood[i],
          "difficulty": this.state.difficulty[i]
        }
      });
    }
    this.props.session.update({ "allCauses": JSON.stringify(this.state.allCauses) });
    this.setSession2();
    this.props.updateCompletion(7, completed, 3, false);
  }

  setSession2() {
    var list = [];
    for (var i = 0; i < this.state.potential.length; i++) {
      var diff = valueMapper[this.state.difficultyScale[this.state.difficulty[i]]];
      var like = valueMapper[this.state.likelihoodScale[this.state.likelihood[i]]];
      var currentValue = diff + like;
      list.push({ 'name': this.state.potential[i], 'value': currentValue });
    }
    list.sort(function (a, b) {
      return ((a.value < b.value) ? 1 : ((a.value === b.value) ? 0 : -1));
    });
    var recommended = []
    for (var k = 0; k < list.length; k++) {
      let name = list[k].name;
      if (name === "") { continue; }
      recommended.push(name);
    }
    this.props.prioritizationSession.update({ "recommended": JSON.stringify(recommended.slice(0, 5)) });
  }

  close() {
    this.setState({ initialCheck: false });
  }

  handleCauses(index, key, e) {
    var causesCopy = this.state.causes.slice();
    causesCopy[index] = key;
    this.setState({ causes: causesCopy });
  }

  handleLikelihood(index, key, e) {
    var likelihoodCopy = this.state.likelihood.slice();
    likelihoodCopy[index] = key;
    this.setState({ likelihood: likelihoodCopy });
  }

  handleDifficulty(index, key, e) {
    var difficultyCopy = this.state.difficulty.slice();
    difficultyCopy[index] = key;
    this.setState({ difficulty: difficultyCopy });
  }

  handlePotential(index, e) {
    if (e.target.value.length > 32) { return; }
    var potentials = this.state.potential.slice();
    potentials[index] = e.target.value;
    this.setState({ potential: potentials });
  }

  addField() {
    var potentialCopy = this.state.potential.slice();
    potentialCopy.push("");
    var likelihoodCopy = this.state.likelihood.slice();
    likelihoodCopy.push(0);
    var difficultyCopy = this.state.difficulty.slice();
    difficultyCopy.push(0);
    var causesCopy = this.state.causes.slice();
    causesCopy.push(0);
    this.setState({
      potential: potentialCopy,
      likelihood: likelihoodCopy,
      difficulty: difficultyCopy,
      causes: causesCopy
    });
  }

  removeField(index, e) {
    var potentialCopy = this.state.potential.slice();
    potentialCopy.splice(index, 1);
    var likelihoodCopy = this.state.likelihood.slice();
    likelihoodCopy.splice(index, 1);
    var difficultyCopy = this.state.difficulty.slice();
    difficultyCopy.splice(index, 1);
    var causesCopy = this.state.causes.slice();
    causesCopy.splice(index, 1);
    this.setState({
      potential: potentialCopy,
      likelihood: likelihoodCopy,
      difficulty: difficultyCopy,
      causes: causesCopy
    });
  }

  render() {
    var topToolTip =
      <Tooltip id="topToolTip">
        Click or tap in the boxes on the left side of the screen to type in a possible solution to your problem. Then, match each solution to one of the causes you starred in the previous activity. Lastly, select how likely this solution is to work and how difficult it will be to do. If you want to add more solutions, click or tap Add Another Solution.
    </Tooltip>;

    var initialCheck =
      <Modal className="activity-modal" show={this.state.initialCheck && this.props.showInstruction} onHide={this.close.bind(this)}>
        <Modal.Body>
          <p className="modal-heading">
            Solutions
        </p>
          <img className="modal-image" src="/images/solutions.png" alt="..." />
          <br />
          <br />
          <p className="instructions-header">Instructions:</p>
          <p className="modal-text">
            Click or tap in the boxes on the left side of the screen to type in a possible solution to your problem. Then, match each solution to one of the causes you starred in the previous activity. Lastly, select how likely this solution is to work and how difficult it will be to do. If you want to add more solutions, click or tap Add Another Solution
        </p>
          <br />
          <Button className="footer-button" bsSize="large" onClick={this.close.bind(this)}>
            Continue
        </Button>
          <br />
          <br />
        </Modal.Body>
      </Modal>

    var confirmedFields = [];
    for (var i = 0; i < this.state.confirmedNumber; i++) {
      var disabled = false;
      confirmedFields.push(
        <Row key={i} className="selection-grid">
          <Col xs={5} md={3}>
            <DropdownButton
              title={this.state.allCauses[this.state.causes[i]]}
              onSelect={this.handleCauses.bind(this, i)}
              id="solutions-selector"
              disabled={disabled}>
              {this.state.allCauses.map((value, index) => (
                <MenuItem key={index} eventKey={index}>{value}</MenuItem>
              ))}
            </DropdownButton>
          </Col>
          <Col xs={5} md={3}>
            <FormControl type="text" onChange={this.handlePotential.bind(this, i)} value={this.state.potential[i]} readOnly={disabled} />
          </Col>
          <Col xs={5} md={3}>
            <DropdownButton
              title={this.state.likelihoodScale[this.state.likelihood[i]]}
              onSelect={this.handleLikelihood.bind(this, i)}
              id="solutions-selector"
              disabled={disabled}>
              {this.state.likelihoodScale.map((value, index) => (
                <MenuItem key={index} eventKey={index}>{value}</MenuItem>
              ))}
            </DropdownButton>
          </Col>
          <Col xs={5} md={3}>
            <DropdownButton
              title={this.state.difficultyScale[this.state.difficulty[i]]}
              onSelect={this.handleDifficulty.bind(this, i)}
              id="solutions-selector"
              disabled={disabled}>
              {this.state.difficultyScale.map((value, index) => (
                <MenuItem key={index} eventKey={index}>{value}</MenuItem>
              ))}
            </DropdownButton>
          </Col>
        </Row>);
    }
    var extraFields = [];
    for (i = this.state.confirmedNumber; i < this.state.potential.length; i++) {
      disabled = false;
      extraFields.push(
        <Row key={i} className="selection-grid">
          <Col xs={5} md={3}>
            <DropdownButton
              title={this.state.allCauses[this.state.causes[i]]}
              onSelect={this.handleCauses.bind(this, i)}
              id="solutions-selector"
              disabled={disabled}>
              {this.state.allCauses.map((value, index) => (
                <MenuItem key={index} eventKey={index}>{value}</MenuItem>
              ))}
            </DropdownButton>
          </Col>
          <Col xs={5} md={3}>
            <div className="solutions-extra">
              <FormGroup>
                <InputGroup>
                  <FormControl style={{ zIndex: 0 }} type="text" onChange={this.handlePotential.bind(this, i)} value={this.state.potential[i]} readOnly={disabled} />
                  <InputGroup.Button>
                    <Button bsStyle="danger" onClick={this.removeField.bind(this, i)} disabled={disabled}><Glyphicon glyph="remove" /></Button>
                  </InputGroup.Button>
                </InputGroup>
              </FormGroup>
            </div>
          </Col>
          <Col xs={5} md={3}>
            <DropdownButton
              title={this.state.likelihoodScale[this.state.likelihood[i]]}
              onSelect={this.handleLikelihood.bind(this, i)}
              id="solutions-selector"
              disabled={disabled}>
              {this.state.likelihoodScale.map((value, index) => (
                <MenuItem key={index} eventKey={index}>{value}</MenuItem>
              ))}
            </DropdownButton>
          </Col>
          <Col xs={5} md={3}>
            <DropdownButton
              title={this.state.difficultyScale[this.state.difficulty[i]]}
              onSelect={this.handleDifficulty.bind(this, i)}
              id="solutions-selector"
              disabled={disabled}>
              {this.state.difficultyScale.map((value, index) => (
                <MenuItem key={index} eventKey={index}>{value}</MenuItem>
              ))}
            </DropdownButton>
          </Col>
        </Row>);
    }

    return (
      <div className="page-shift">
        <Jumbotron className="jumbotron-dashboard">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>

          <div>
            <div className="information-title">
              <p id="activity-text">Solutions</p>
            </div>
            <div className="solutions-grid">
              <Grid>
                <Row>
                  <Col xs={5} md={3}><p className="solutions-header-text">Select a Cause</p></Col>
                  <Col xs={5} md={3}><p className="solutions-header-text">Potential Solutions</p></Col>
                  <Col xs={5} md={3}><p className="solutions-header-text">How likely is it to work?</p></Col>
                  <Col xs={5} md={3}><p className="solutions-header-text">How difficult is it to do?</p></Col>
                </Row>
                {confirmedFields}
                {extraFields}
                <Row>
                  <Col xs={5} md={3}>
                    <div align="left" className="solutions-added-btns">
                      <Button className="roster-button" onClick={this.addField.bind(this)}>Add Another Solution</Button>
                    </div>
                  </Col>
                  <Col xs={10} md={6}></Col>
                  <Col xs={5} md={3}>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>

          {initialCheck}

          <Footer pageName="solutions" setEdit={this.props.setEdit} routerKey={this.props.routerKey} history={this.props.history} />

        </Jumbotron>
      </div>
    )
  }
};

export default Solutions;
