import React from 'react';
import {
  Button,
  FormControl,
  InputGroup,
  FormGroup,
  Tooltip,
  OverlayTrigger,
  Glyphicon,
  Jumbotron,
} from 'react-bootstrap';
import Footer from '../global-components/Footer';

class Star extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mainValues: ["Processes", "People", "Equipment", "Environment"],
      subValues: [["", "", "", ""], ["", "", "", ""], ["", "", "", ""], ["", "", "", ""]],
      checkValues: [[false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false]],
    };
  }

  componentWillMount() {
    this.props.session.on('value', snapshot => {
      let mainValues = JSON.parse(snapshot.child("main").val());
      let subValues = JSON.parse(snapshot.child("subValues").val());
      let checkValues = JSON.parse(snapshot.child("checkValues").val());
      if(checkValues !== null && checkValues.length >= 3) {
        this.setState({
          mainValues: mainValues, 
          subValues: subValues,
          checkValues: checkValues 
        });
      }
    });
  }
  
  componentWillUnmount() {
    this.props.session.update({
      main: JSON.stringify(this.state.mainValues),
      subValues: JSON.stringify(this.state.subValues),
      checkValues: JSON.stringify(this.state.checkValues)
    })
  }

  findTrueCount() {
    var count = 0;
    for(var i in this.state.checkValues) {
      let subCheck = this.state.checkValues[i];
      for(var j in subCheck) {
        if(this.state.checkValues[i][j]) { count += 1; }
      }
    }
    return count;
  }

  removeCheck(index, sub) {
    var copy = this.state.checkValues.slice();
    copy[index][sub] = false;
    this.setState({ checkValues: copy });
  }

  addCheck(index, sub) {
    if(this.findTrueCount() >=5 ) { return; }
    var copy = this.state.checkValues.slice();
    copy[index][sub] = true;
    this.setState({ checkValues: copy });
  }

  render() {
    var topToolTip = 
    <Tooltip id="topToolTip">
      Click or tap in each box and type in the names of the members of your team. Click the Select Role button to define their role on the QI team. If you want to add more team members, click Add Another Member. This can be edited later if necessary. 
    </Tooltip>;

    var mains = [];
    for(var i = 0; i<this.state.mainValues.length; i++) {
      var buttonText = this.state.mainValues[i];
      buttonText = buttonText === "" ? "Click Here!" : buttonText;
      mains.push(
        <div key={i} className={"cause-main-div star-main-" + i}>
          <Button key={i} className="star-examples" bsSize="small">
            {buttonText} &nbsp; &nbsp;
          </Button>
        </div>
      );
    };

    var subs = [];
    for(i = 0; i<this.state.checkValues.length; i++) {
      let subValues = this.state.checkValues[i];
      let subComponents = [];
      for(var j in subValues) {
        if (this.state.subValues[i][j] === "") continue;
        var formControlClass = this.state.checkValues[i][j] ? "star-input-checked" : "star-input"
        subComponents.push(
          <FormGroup key={(i+4)*(j+1)} className="cause-sub-comp">
            <InputGroup>
              {this.state.checkValues[i][j] ? (
                <InputGroup.Button>
                  <Button style={{color: "#009A92"}} bsSize="small" onClick={this.removeCheck.bind(this, i, j)}><Glyphicon glyph="star" /></Button>
                </InputGroup.Button>)
                : (<InputGroup.Button>
                  <Button className="cause-star" bsSize="small" onClick={this.addCheck.bind(this, i, j)} active><Glyphicon glyph="star" /></Button>
                </InputGroup.Button>)
              }
              <FormControl className={formControlClass} disabled style={{zIndex: 0}} type="text" bsSize="small" value={this.state.subValues[i][j]} />
            </InputGroup>
          </FormGroup>
        );
      }
      subs.push(subComponents);
    }

    var allSubs = [];
    for(i = 0; i<this.state.checkValues.length; i++) {
      allSubs.push(
        <div key={-1*(i+1)} className={"cause-sub star-sub-" + i}>
        {subs[i]}
        </div>
      )
    }

    return (
      <div className="page-shift">
        <Jumbotron className="jumbotron-dashboard">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>

          <div>
            <div className="information-title">
              <p id="activity-text">Causes</p>
            </div>
            <div className="star-text">
              <span id="prioritization-text">
                Below are the potential causes to the problem you are trying to resolve. <br/> Click the star button next to the five causes you want to focus on in the next section.
              </span>
            </div>

            <div className="star-top-scrollable"> 
              <div className="inside-scrollable">
                {mains}
                {allSubs}
              </div>
            </div>
          </div>

          <Footer pageName="star" setEdit={this.props.setEdit} routerKey={this.props.routerKey} history={this.props.history} />
          
        </Jumbotron>
      </div>
    )
  }
};

export default Star;
