import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'
import ItemTypes from './ItemTypes'
import flow from 'lodash/flow';
import Icon from '@material-ui/core/Icon';
import { teal } from '@material-ui/core/colors';

const style = {
	padding: '1rem 1.5rem',
	marginBottom: '.5rem',
	cursor: 'move',
}

const cardSource = {
	beginDrag(props) {
		return {
			id: props.id,
			index: props.index,
		}
	},
}

const cardTarget = {
	hover(props, monitor, component) {
		const dragIndex = monitor.getItem().index
		const hoverIndex = props.index

		// Don't replace items with themselves
		if (dragIndex === hoverIndex) {
			return
		}

		// Determine rectangle on screen
		const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

		// Get vertical middle
		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

		// Determine mouse position
		const clientOffset = monitor.getClientOffset()

		// Get pixels to the top
		const hoverClientY = clientOffset.y - hoverBoundingRect.top

		// Only perform the move when the mouse has crossed half of the items height
		// When dragging downwards, only move when the cursor is below 50%
		// When dragging upwards, only move when the cursor is above 50%

		// Dragging downwards
		if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
			return
		}

		// Dragging upwards
		if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
			return
		}

		// Time to actually perform the action
		props.moveCard(dragIndex, hoverIndex)

		// Note: we're mutating the monitor item here!
		// Generally it's better to avoid mutations,
		// but it's good here for the sake of performance
		// to avoid expensive index searches.
		monitor.getItem().index = hoverIndex
	},
}

function dropTargetConnect(connect) {
  return {
	connectDropTarget: connect.dropTarget(),
  };
}

function dragSourceConnect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class DraggableSolution extends Component {
	static propTypes = {
		connectDragSource: PropTypes.func.isRequired,
		connectDropTarget: PropTypes.func.isRequired,
		index: PropTypes.number.isRequired,
		isDragging: PropTypes.bool.isRequired,
		id: PropTypes.any.isRequired,
		text: PropTypes.string.isRequired,
		moveCard: PropTypes.func.isRequired,
	}

	render() {
		const {
			text,
			isDragging,
			connectDragSource,
			connectDropTarget,
			star,
			index
		} = this.props
		const opacity = isDragging ? 0 : 1

		var styling = {};
		Object.keys(style).forEach(function(key) {
		     styling[key] = style[key];
		}); 
		
		var color = 'white';
		if(index === 0) {
			color = '#28A79A';
		} else if(index === 1) {
			color = '#7FCDBB';
		} else if(index === 2) {
			color = '#C7E9B4';
		} else if(index === 3) {
			color = '#E0E0E0';
		} else if(index === 4) {
			color = '#E0E0E0';
		}

		styling["backgroundColor"] = color;

		return connectDragSource(
			connectDropTarget(
				<div className="draggable-solution" style={{ ...styling, opacity }}>
					{star ? (
						<div className="draggable-star">
			                <Icon style={{
    							fontSize: '24px',
    							color: teal[300],								
							}}>grade</Icon>
			             </div>)
		             	: null
		            }
					{text}
				</div>
			),
		)
	}
}

export default flow(
  DragSource(ItemTypes.SOLUTION, cardSource, dragSourceConnect),
  DropTarget(ItemTypes.SOLUTION, cardTarget, dropTargetConnect)
)(DraggableSolution);
