import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from './nav-pages/Home'
import AdminPortal from './nav-pages/AdminPortal'
import Dashboard from './nav-pages/Dashboard'
import User from './nav-pages/User'
import Project from './nav-pages/Project'
import Preview from './nav-pages/Preview'
import Blueprint from './nav-pages/Blueprint'
import Videos from './nav-pages/Videos'

import AdvancedTools from './advanced-tools/AdvancedTools'
import Chart from './advanced-tools/Chart'
import ChartVideo from './advanced-tools/ChartVideo'
import Mapping from './advanced-tools/Mapping'
import MappingVideo from './advanced-tools/MappingVideo'

import About from './static/About'
import Terms from './static/Terms'
import Privacy from './static/Privacy'
import Contact from './static/Contact'

import Information from './bite1/Information'
import Selection from './bite1/Selection'
import Problem from './bite1/Problem'
import Aim from './bite1/Aim'
import Roster from './bite1/Roster'

import WelcomeVideo from './bite1/video-pages/WelcomeVideo'
import HealthCareVideo from './bite1/video-pages/HealthCareVideo'
import SelectVideo from './bite1/video-pages/SelectVideo'
import BlueprintVideo from './bite1/video-pages/BlueprintVideo'
import ProblemVideo from './bite1/video-pages/ProblemVideo'
import RosterVideo from './bite1/video-pages/RosterVideo'
import NextVideo from './bite1/video-pages/NextVideo'
import AimVideo from './bite1/video-pages/AimVideo'

import Measurement from './bite2/Measurement'
import Cause from './bite2/Cause'
import Star from './bite2/Star'

import MeasurementVideo from './bite2/video-pages/MeasurementVideo'
import CauseVideo from './bite2/video-pages/CauseVideo'

import Solutions from './bite3/Solutions'
import Prioritization from './bite3/Prioritization'
import Timeline from './bite3/Timeline'

import SolutionsVideo from './bite3/video-pages/SolutionsVideo'
import TimelineVideo from './bite3/video-pages/TimelineVideo'

import storageHelper from './helpers/storageHelper.jsx'
import firebaseHelper from './helpers/firebaseHelper.jsx'

class MainRouter extends React.Component {

  shouldShowInstruction() {
    const routerKeyToIndex = {
      selectvideo: 2,
      problemvideo: 2,
      problem: 2,
      aimvideo: 3,
      aim: 3,
      rostervideo: 4,
      roster: 4,
      measurementvideo: 5,
      measurement: 5,
      causevideo: 6,
      cause: 6,
      prioritization: 7,
      solutionsvideo: 7,
      solutions: 7,
      timelinevideo: 8,
      timeline: 8,
    };
    const activityStatus = this.props.activityStatus;
    var fullLink = window.location.href.split("/");
    var currentPage = fullLink[fullLink.length-1];
    const activityValue = activityStatus[routerKeyToIndex[currentPage]];
    if (activityValue === "1" || activityValue === "2") { return false; }
    return !this.props.returningUser;
  }

  clearDashboardBack() {
    var fullLink = window.location.href.split("/");
    var currentPage = fullLink[fullLink.length-1];
    if (currentPage === "dashboard") { return; }
    storageHelper.clearDashboardBack();
  }
  
  render() {
    this.clearDashboardBack();
    var showInstruction = this.shouldShowInstruction();
    return (
      <div>
        <main>
          <Switch>
            <Route exact path='/' render={(props) => (<Home {...props}
              setShowProgress={this.props.setShowProgress}
              setProjectCode={this.props.setProjectCode} />)} />

            <Route exact path="/about" render={(props) => (<About {...props}
              setShowProgress={this.props.setShowProgress} />)} />
            <Route exact path="/terms" render={(props) => (<Terms {...props}
              setShowProgress={this.props.setShowProgress} />)} />
            <Route exact path="/privacy" render={(props) => (<Privacy {...props}
              setShowProgress={this.props.setShowProgress} />)} />
            <Route exact path="/contact" render={(props) => (<Contact {...props}
              setShowProgress={this.props.setShowProgress} />)} />
            <Route exact path="/admin-portal" render={(props) => (<AdminPortal {...props}
              resetClient={this.props.resetClient}
              updateLogo={this.props.updateLogo}
              database={firebaseHelper.getDatabase()}
              setShowProgress={this.props.setShowProgress} />)} />
        
            <Route exact path="/videos" render={(props) => (<Videos {...props} />)} />
            <Route exact path="/dashboard" render={(props) => (<Dashboard {...props}
              routerKey={this.props.routerKey}
              activityStatus={this.props.activityStatus}
              lastBite={this.props.lastBite}
              problemStatement={this.props.problemStatement}
              goingForward={this.props.goingForward} />)} />
            <Route exact path="/preview" render={(props) => (<Preview {...props}
              routerKey={this.props.routerKey}
              activityStatus={this.props.activityStatus}
              lastBite={this.props.lastBite}
              goingForward={this.props.goingForward}
              session={firebaseHelper.getSession()} />)} />

            <Route exact path='/user' render={(props) => (<User {...props}
              setReturningUser={this.props.setReturningUser}
              routerKey={this.props.routerKey}
              addNewUser={this.props.addNewUser}
              setShowProgress={this.props.setShowProgress}
              projectCode={this.props.projectCode} />)} />
            <Route exact path="/project" render={(props) => (<Project {...props}
              setReturningUser={this.props.setReturningUser}
              setRouterKey={this.props.setRouterKey}
              addNewUser={this.props.addNewUser}
              addedOldUser={this.props.addedOldUser}
              setShowProgress={this.props.setShowProgress}
              projectCode={this.props.projectCode} />)} />

            <Route exact path="/information" render={(props) => (<Information {...props}
              updateCompletion={this.props.updateCompletion}
              routerKey={this.props.routerKey}
              setEdit={this.props.setEdit}
              session={firebaseHelper.getSession("/selection")} />)} />
            <Route exact path="/selection" render={(props) => (<Selection {...props}
              updateCompletion={this.props.updateCompletion}
              routerKey={this.props.routerKey}
              setEdit={this.props.setEdit}
              session={firebaseHelper.getSession("/selection")}
              setProjectTitle={this.props.setProjectTitle}
              projectTitle={this.props.projectTitle} />)} />
            <Route exact path="/problem" render={(props) => (<Problem {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey}
              setEdit={this.props.setEdit}
              updateCompletion={this.props.updateCompletion}
              session={firebaseHelper.getSession("/problem")} />)} />
            <Route exact path="/aim" render={(props) => (<Aim {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey}
              setEdit={this.props.setEdit}
              updateCompletion={this.props.updateCompletion}
              session={firebaseHelper.getSession("/aim")} />)} />
            <Route exact path="/roster" render={(props) => (<Roster {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey}
              setEdit={this.props.setEdit}
              updateCompletion={this.props.updateCompletion}
              session={firebaseHelper.getSession("/roster")} />)} />

            <Route exact path="/welcomevideo" render={(props) => (<WelcomeVideo {...props}
              routerKey={this.props.routerKey} />)} />
            <Route exact path="/healthcarevideo" render={(props) => (<HealthCareVideo {...props}
              routerKey={this.props.routerKey} />)} />
            <Route exact path="/selectvideo" render={(props) => (<SelectVideo {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey} />)} />
            <Route exact path="/blueprintvideo" render={(props) => (<BlueprintVideo {...props}
              routerKey={this.props.routerKey} />)} />
            <Route exact path="/problemvideo" render={(props) => (<ProblemVideo {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey} />)} />
            <Route exact path="/aimvideo" render={(props) => (<AimVideo {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey} />)} />
            <Route exact path="/rostervideo" render={(props) => (<RosterVideo {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey} />)} />
            <Route exact path="/nextvideo" render={(props) => (<NextVideo {...props}
              routerKey={this.props.routerKey} />)} />

            <Route exact path="/measurement" render={(props) => (<Measurement {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey}
              setEdit={this.props.setEdit}
              aimValues={this.props.aimValues}
              updateCompletion={this.props.updateCompletion}
              session={firebaseHelper.getSession("/measurement")} />)} />
            <Route exact path="/cause" render={(props) => (<Cause {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey}
              setEdit={this.props.setEdit}
              updateCompletion={this.props.updateCompletion}
              problemStatement={this.props.problemStatement}
              what={this.props.what}
              session={firebaseHelper.getSession("/cause")} />)} />
            <Route exact path="/star" render={(props) => (<Star {...props}
              routerKey={this.props.routerKey}
              setEdit={this.props.setEdit}
              updateCompletion={this.props.updateCompletion}
              session={firebaseHelper.getSession("/cause")} />)} />

            <Route exact path="/measurementvideo" render={(props) => (<MeasurementVideo {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey} />)} />
            <Route exact path="/causevideo" render={(props) => (<CauseVideo {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey} />)} />

            <Route exact path="/solutions" render={(props) => (<Solutions {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey}
              setEdit={this.props.setEdit}
              updateCompletion={this.props.updateCompletion}
              causeSession={firebaseHelper.getSession("/cause")}
              prioritizationSession={firebaseHelper.getSession("/prioritization")}
              session={firebaseHelper.getSession("/solutions")} />)} />
            <Route exact path="/prioritization" render={(props) => (<Prioritization {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey}
              setEdit={this.props.setEdit}
              updateCompletion={this.props.updateCompletion}
              timelineSession={firebaseHelper.getSession("/timeline")}
              session={firebaseHelper.getSession("/prioritization")} />)} />
            <Route exact path="/timeline" render={(props) => (<Timeline {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey}
              setEdit={this.props.setEdit}
              updateCompletion={this.props.updateCompletion}
              roster={this.props.roster}
              session={firebaseHelper.getSession("/timeline")} />)} />

            <Route exact path="/solutionsvideo" render={(props) => (<SolutionsVideo {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey} />)} />
            <Route exact path="/timelinevideo" render={(props) => (<TimelineVideo {...props}
              showInstruction={showInstruction}
              routerKey={this.props.routerKey} />)} />

            <Route exact path="/blueprint" render={(props) => (<Blueprint {...props}
              routerKey={this.props.routerKey}
              session={firebaseHelper.getSession()} />)} />

            <Route exact path="/blueprint" render={(props) => (<Blueprint {...props}
              routerKey={this.props.routerKey}
              session={firebaseHelper.getSession()} />)} />

            <Route exact path="/advancedtools" render={(props) => (<AdvancedTools {...props}
              routerKey={this.props.routerKey} />)} />
            <Route exact path="/mappingvideo" render={(props) => (<MappingVideo {...props}
              routerKey={this.props.routerKey} />)} />
            <Route exact path="/chartvideo" render={(props) => (<ChartVideo {...props}
              routerKey={this.props.routerKey} />)} />

            <Route exact path="/mapping" render={(props) => (<Mapping {...props} 
              routerKey={this.props.routerKey}
              session={firebaseHelper.getSession("/mapping")} />)} />
            <Route exact path="/chart" render={(props) => (<Chart {...props} 
              routerKey={this.props.routerKey}
              session={firebaseHelper.getSession("/chart")} />)} />
          </Switch>
        </main>
      </div>
    )
  }   
}

export default MainRouter;
