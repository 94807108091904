import React from 'react';
import {
  Button,
  FormControl,
  Modal,
  OverlayTrigger,
  Tooltip,
  Glyphicon,
  Jumbotron
} from 'react-bootstrap';
import Footer from '../global-components/Footer';
import ReactGA from './../../ga.js';

class Measurement extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      initialCheck: true,
      activityPopups: [],
      howValues: ["", "", "", ""],
      whatValue: "",
      showAim: false,
    };
  }

  handleKeyPress(index, target) {
    if(target.charCode === 13) {
      this.nextStep(index);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.checkAim(nextProps.aimValues);
  }

  componentWillMount() {
    this.checkAim();
    this.props.session.on('value', snapshot => {
      var howValues = [];
      snapshot.child("how").forEach((childSnapshot) => {
        howValues[childSnapshot.key] = childSnapshot.val();
      })
      let whatValue = snapshot.child("what").val();
      if(howValues.length === 4) { 
        this.setState({ 
          howValues: howValues,
          whatValue: whatValue
        }); 
      } 
    });  
  }

  componentWillUnmount() {
    var completed = (this.state.whatValue === "") ? "1": "2";
    for(var i = 0; i<this.state.howValues.length; i++) {
      let value = this.state.howValues[i];
      if(value !== "") { completed = "2"; }
      this.props.session.update({ ["how/" + i]: value });
    }    
    this.props.session.update({ what: this.state.whatValue });
    this.props.updateCompletion(5, completed, 2, false);
  }

  exit() {
    var popupCopy = this.state.activityPopups.slice();
    for (var index in popupCopy) {
      popupCopy[index] = false;
    }
    this.setState({ activityPopups: popupCopy });
  }

  backStep(index) {
    var popupCopy = this.state.activityPopups.slice();
    popupCopy[index] = false;
    popupCopy[index - 1] = true;
    this.setState({ activityPopups: popupCopy });
    ReactGA.event({
      category: 'Measurement',
      action: 'Modal Progress',
      label: 'Back step from index: ' + index,
    });
  }

  nextStep(index) {
    var popupCopy = this.state.activityPopups.slice();
    popupCopy[index] = false;
    popupCopy[index + 1] = true;
    this.setState({ activityPopups: popupCopy });
    ReactGA.event({
      category: 'Measurement',
      action: 'Modal Progress',
      label: 'Next step from index: ' + index,
    });
  }

  checkAim(aimValues=this.props.aimValues) {
    var show = aimValues.length === 4 ? true : false; 
    for(var i=0; i<aimValues.length; i++) {
      if(aimValues[i] === "") {
        show = false;
      }
    }
    this.setState({ showAim: show });
  }
  
  close() {
    var allFalse = [];
    for (var i = 0; i < 5; i++) {
      allFalse.push(false);
    }
    this.setState({ activityPopups: allFalse, initialCheck: false });
  }

  openPopup(index) {
    ReactGA.event({
      category: 'Measurement',
      action: 'Modal Progress',
      label: 'Popup opened at: ' + index,
    });
    var activityPopupsCopy = this.state.activityPopups.slice();
    activityPopupsCopy[index] = true;
    this.setState({ activityPopups: activityPopupsCopy });
  }

  handleChange(index, e) {
    var valuesCopy = this.state.values.slice();
    var value = e.target.value;
    valuesCopy[index] = value;
    this.setState({ values: valuesCopy });
  }

  handleWhatValue(e) {
    this.setState({ whatValue: e.target.value });
  }

  handleHowValue(index, e) {
    var valuesCopy = this.state.howValues.slice();
    var value = e.target.value;
    valuesCopy[index] = value;
    this.setState({ howValues: valuesCopy });
  }

  imageClick(page) {
    ReactGA.event({
      category: 'Measurement',
      action: 'Incomplete Activity',
      label: 'Going back to complete Aim Statement',
    });
    this.props.history.push('/' + page);
  }

  render(){
    var topToolTip = 
    <Tooltip id="topToolTip">
      Tap or click on each element of a measurement plan to provide further details about the "What" and "How much" of your aim statement. Each question will include examples if you get stuck.
    </Tooltip>;

    var initialCheck =
    <Modal className="activity-modal" show={this.state.initialCheck && this.props.showInstruction} onHide={this.close.bind(this)}>
      <Modal.Body>
        <p className="modal-heading">
          Measurement
        </p>
        <img className="modal-image" src="/images/measurement.png" alt="..."/>
        <br/>
        <br/>
        <p className="instructions-header">Instructions:</p>
        <p className="modal-text">
          Tap or click on each element of a measurement plan to provide further details about the "What" and "How much" of your aim statement. Each question will include examples if you get stuck.
        </p>
        <br/>
        <Button className="footer-button" bsSize="large" onClick={this.close.bind(this)}>
          Continue
        </Button> 
        <br/>
        <br/>
      </Modal.Body>
    </Modal>

    var what = 
    <Modal show={this.state.activityPopups[0]} onHide={this.exit.bind(this)} bsSize="large">
      <Modal.Header>
        <Modal.Title className="green-header">Define "What"</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {this.state.showAim ? (
        <div>
          <span id="measurement2-regular">You have identified your "What" as </span>
          <span id="measurement2-bold">{this.props.aimValues[1]}</span><span id="measurement2-regular">.</span><br/><br/>
          <span id="measurement2-regular">Now let's work on further defining your </span>
          <span id="measurement2-bold">{this.props.aimValues[1]}</span>
          <span id="measurement2-regular">. Using the examples provided, start thinking about how you can better define </span>
          <span id="measurement2-bold">{this.props.aimValues[1]}</span><span id="measurement2-regular">.</span><br/><br/>
          <span id="measurement2-regular">Remember, it is important to be as specific as possible.</span>
        </div>)
        : (<span id="measurement-aim">Please complete the Aim Statement Activity</span>)}  
        <br/>
        <FormControl onKeyPress={this.handleKeyPress.bind(this, 0)} autoFocus={true} className="measurement-textarea" componentclassName="textarea" placeholder="Type Here" value={this.state.whatValue} onChange={this.handleWhatValue.bind(this)} />
        <br/>
        Examples:
        <ul className="modal-examples">
          <li>
            Wait times -> <em>Wait times as measured from patient check-in to the moment the physician walks into the exam room</em>
          </li>
          <li>
            Patient satisfaction results -> <em>Mean scores on questions 3 & 6 of the CG CAHPS instrument</em>
          </li>
          <li>
            The turnaround time for lab results -> <em>Time from physician stat order placement for PT/PTT to availability of results in the EMR</em>       
          </li>
        </ul>

      </Modal.Body>
      <Modal.Footer>
        <Button className="activity-back" onClick={this.backStep.bind(this, 0)}>Back</Button>
        <Button onClick={this.nextStep.bind(this, 0)}>Next</Button>
      </Modal.Footer>
    </Modal>

    var givenWhat = this.props.aimValues[1];
    var base = "Now think about how you will measure ";
    var aimWhat = (givenWhat === "" || givenWhat === undefined) ? "No 'what' value given!" : base + givenWhat;

    var how0 = 
    <Modal show={this.state.activityPopups[1]} onHide={this.exit.bind(this)}>
      <Modal.Header>
        <Modal.Title className="measurement-header">{aimWhat}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title className="green-header">Who is going to collect it?</Modal.Title>
        <div className="measurement-input">
          <FormControl onKeyPress={this.handleKeyPress.bind(this, 1)} autoFocus={true} className="measurement-textarea" placeholder="Type Here" value={this.state.howValues[0]} onChange={this.handleHowValue.bind(this, 0)}/>
        </div>
        Examples:
        <ul>
          <li>
            Information technology department
          </li>
          <li>
            Front desk staff
          </li>
          <li>
            Clinic provider
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button className="activity-back" onClick={this.backStep.bind(this, 1)}>Back</Button>
        <Button onClick={this.nextStep.bind(this, 1)}>Next</Button>
      </Modal.Footer>
    </Modal>

    var how1 = 
    <Modal show={this.state.activityPopups[2]} onHide={this.exit.bind(this)}>
      <Modal.Header>
        <Modal.Title className="measurement-header">{aimWhat}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title className="green-header">Over what time period will it be collected?</Modal.Title>
        <div className="measurement-input">
          <FormControl onKeyPress={this.handleKeyPress.bind(this, 2)} autoFocus={true} className="measurement-textarea" placeholder="Type Here" value={this.state.howValues[1]} onChange={this.handleHowValue.bind(this, 1)}/>
        </div>
        Examples:
        <ul>
          <li>
            One week
          </li>
          <li>
            Three months
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button className="activity-back" onClick={this.backStep.bind(this, 2)}>Back</Button>
        <Button onClick={this.nextStep.bind(this, 2)}>Next</Button>
      </Modal.Footer>
    </Modal>

    var how2 = 
    <Modal show={this.state.activityPopups[3]} onHide={this.exit.bind(this)}>
      <Modal.Header>
        <Modal.Title className="measurement-header">{aimWhat}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title className="green-header">How will the data be collected?</Modal.Title>
        <div className="measurement-input">
          <FormControl onKeyPress={this.handleKeyPress.bind(this, 3)} autoFocus={true} className="measurement-textarea" placeholder="Type Here" value={this.state.howValues[2]} onChange={this.handleHowValue.bind(this, 2)}/>
        </div>
        Examples:
        <ul>
          <li>
            Manually using observation and a clipboard
          </li>
          <li>
            From the EMR with a custom report
          </li>
          <li>
            Counting the number of ‘in process’ requisitions at the end of each day
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button className="activity-back" onClick={this.backStep.bind(this, 3)}>Back</Button>
        <Button onClick={this.nextStep.bind(this, 3)}>Next</Button>
      </Modal.Footer>
    </Modal>

    var how3 = 
    <Modal show={this.state.activityPopups[4]} onHide={this.exit.bind(this)}>
      <Modal.Header>
        <Modal.Title className="measurement-header">{aimWhat}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title className="green-header">How will the data be recorded?</Modal.Title>
        <div className="measurement-input">
          <FormControl onKeyPress={this.handleKeyPress.bind(this, 4)} autoFocus={true} className="measurement-textarea" placeholder="Type Here" value={this.state.howValues[3]} onChange={this.handleHowValue.bind(this, 3)}/>
        </div>
        Examples:
        <ul>
          <li>
            Via the reporting system in the EMR
          </li>
          <li>
            Manually on forms using the clipboard
          </li>
          <li>
            Counting the number of screening forms returned by patients each day
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button className="activity-back" onClick={this.backStep.bind(this, 4)}>Back</Button>
        <Button onClick={this.exit.bind(this)}>Done</Button>
      </Modal.Footer>
    </Modal>

    var baseTexts = [
      'You have defined your "What" as: ', 
      "The data will be collected by: ",
      "The data will be collected over: ",
      "The data will be collected through: ",
      "The data will be recorded by: ",
    ];
    var whatText = (this.state.whatValue === "") ? null : <p><em>{baseTexts[0]}</em>{this.state.whatValue}</p>;
    var how0Text = (this.state.howValues[0] === "") ? null : <p><em>{baseTexts[1]}</em>{this.state.howValues[0]}</p>;
    var how1Text = (this.state.howValues[1] === "") ? null : <p><em>{baseTexts[2]}</em>{this.state.howValues[1]}</p>;
    var how2Text = (this.state.howValues[2] === "") ? null : <p><em>{baseTexts[3]}</em>{this.state.howValues[2]}</p>;
    var how3Text = (this.state.howValues[3] === "") ? null : <p><em>{baseTexts[4]}</em>{this.state.howValues[3]}</p>;

    var values = this.state.howValues;
    var buttonDefault = "Start your Measurement Plan";
    var buttonText = buttonDefault;
    var buttonStart;
    for (var i = 0; i < values.length; i++) {
      if(values[i] !== "") {
        continue;
      } else {
        buttonStart = i + 1;
        break
      }
    }
    var anyFilledIn = this.state.whatValue !== "";
    for (i = 0; i < values.length; i++) {
      if(values[i] !== "") { anyFilledIn = true; }
    }
    if (anyFilledIn) {
      buttonText = "Edit Measurement Plan";
    }
    if (this.state.whatValue === "" || buttonStart === undefined) {
      buttonStart = 0;
    }

    return (
      <div className="page-shift">
        <Jumbotron className="jumbotron-dashboard">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>

          <div>
            <div className="information-title">
              <p id="activity-text">Measurement</p>
            </div>
            <div className="measurement-text">
              {this.state.showAim ? (
              <div>
                  <span id="measurement-aim">Aim Statement: </span>
                  <span id="gray">By {this.props.aimValues[0]}, </span>
                  <span id="bold">{this.props.aimValues[1]}</span>
                  <span id="gray"> at {this.props.aimValues[2]} will </span>
                  <span id="bold">{this.props.aimValues[3]}.</span>
                </div>)
                : (
                <div>
                  <p id="measurement-sub">
                    You must complete your Aim Statement in order to complete a measurement plan. 
                  </p>
                  <br/>
                  <br/>
                  <p id="dashboard-sub-hover" onClick={this.imageClick.bind(this, "aim")}>
                   Click here to complete the Aim Statement activity.
                  </p>
                </div>
                )
              }    
              <div className="measurement-summary">
                <br/>
                {whatText}
                <hr/>
                {how0Text}
                {how1Text}
                {how2Text}
                {how3Text}
              </div>
            </div>

            <div>
              <div className="activity-start">
                <Button className="user-button" bsSize="large" onClick={this.openPopup.bind(this, buttonStart)}>
                  {buttonText}
                </Button> 
              </div>
            </div>

        </div>

        {initialCheck}
        {what}
        {how0}
        {how1}
        {how2}
        {how3}
        <Footer pageName="measurement" setEdit={this.props.setEdit} routerKey={this.props.routerKey} history={this.props.history} />

        </Jumbotron>
      </div>
    )
  }
};



export default Measurement;
