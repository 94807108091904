import React from 'react';
import {
  Button,
  Panel,
  Tooltip,
  OverlayTrigger,
  Glyphicon,
  Jumbotron,
} from 'react-bootstrap';
import {
  setInformation,
  setSelection,
  setProblem,
  setAim,
  setRoster,
  setMeasurement,
  setCause,
  setSolutions,
  setPrioritization,
  setTimeline,
} from '../helpers/previewHelper.jsx';
import Footer from '../global-components/Footer';
import ReactGA from './../../ga.js';

const buttonColor = {
  backgroundColor: "#3D5366",
}

const defaultResponse = "No response given".split();
const panelMapping = {
  "Your Information": "information",
  "Project Information": "selection",
  "Problem Statement": "problem",
  "Aim Statement": "aim",
  "Roster": "roster",
  "Measurement - \"What\" and \"How Much\"": "measurement",
  "Cause and Effect": "cause",
  "All Solutions": "solutions",
  "Ranking Solutions": "prioritization",
  "Timeline": "timeline",
}

const activityMapping = {
  information: 0,
  selection: 1,
  problem: 2,
  aim: 3,
  roster: 4,
  measurement: 5,
  cause: 6,
  solutions: 7,
  prioritization: 7,
  timeline: 8,
}

class Preview extends React.Component{

  constructor(props) {
    super(props);
    this.state = { 
      selection: defaultResponse,
      problem: defaultResponse,
      aim: defaultResponse,
      roster: defaultResponse,
      measurement: defaultResponse,
      cause: defaultResponse,
      solutions: defaultResponse,
      prioritization: defaultResponse,
      timeline: defaultResponse,
    };
    this.db = this.props.session;
  }

  componentWillMount() {
    this.db.on('value', snapshot => {
      this.setState({
        information: setInformation(snapshot.child("selection")),
        selection: setSelection(snapshot.child("selection")),
        problem: setProblem(snapshot.child("problem")),
        aim: setAim(snapshot.child("aim")),
        roster: setRoster(snapshot.child("roster")),
        measurement: setMeasurement(snapshot.child("measurement")),
        cause: setCause(snapshot.child("cause")),
        solutions: setSolutions(snapshot.child("solutions")),
        prioritization: setPrioritization(snapshot.child("prioritization")),
        timeline: setTimeline(snapshot.child("timeline")),
      })
    });
  }

  editPage(page) {
    ReactGA.event({
      category: 'Preview',
      action: 'Edit',
      label: 'Editing Page: ' + page,
    });
    this.db.update({ "dashboard/edit": true });
    this.props.history.push("/" + page);
  } 

  render(){
    var topToolTip = 
    <Tooltip id="topToolTip">
      For each milestone, click or tap Select a Date to choose a date that each milestone will be completed. Click or tap Choose a Team Member to assign that task to a specific team member.
    </Tooltip>;

    var activityStatus = this.props.activityStatus;
    let state = this.state
    var allPanels = [];
    for(var key in panelMapping) {
      var content = [];
      for(var i in state[panelMapping[key]]) {
        let stringVal = state[panelMapping[key]][i];
        content.push(<li key={i}>{stringVal}</li>);
      }
      var status = activityStatus[activityMapping[panelMapping[key]]];
      var disabled = true;
      if (status !== "0") { disabled = false;}
      if (status !== "2") { content = <br/>; }
      allPanels.push(
      <Panel 
        key={key}
        className="preview-panel"
        header={
          <div className="preview-panel-color">
            <h3 className="panel-title pull-left">
              {key}
            </h3>
            <div className="preview-edit-button">
              <Button
                style={buttonColor}
                onClick={this.editPage.bind(this, panelMapping[key])}
                bsSize="small"
                className="white-text"
                disabled={disabled}
              >
                Edit
              </Button>
            </div>
            <div className="clearfix" />
          </div>
        }
        bsStyle="info"
      >
        <ul>
          {content}
          <br />
        </ul>
      </Panel>);
    }

    var nextDisabled = false;
    for (const activity of activityStatus) {
      if (activity === "0") { nextDisabled = true; break; }
    }

    return (
      <div className="page-shift">
          <Jumbotron className="jumbotron-dashboard">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>

          <div>
            <div className="information-title">
              <p id="activity-text">Blueprint Preview</p>
            </div>
            <div className="preview-sub">
              Please review your responses. The information below will appear in your Project Blueprint.
            </div>

            <div className="preview-top-scrollable"> 
              <div className="inside-scrollable">
                <div className="preview-panels">
                {allPanels}
                </div>
              </div>
            </div>
          </div>

          <div className="activity-footer-buttons">
            <Footer pageName="preview" setEdit={false} routerKey={this.props.routerKey} history={this.props.history} nextDisabled={nextDisabled}/> 
          </div>

        </Jumbotron>
      </div>
    )
  }
};

export default Preview;
