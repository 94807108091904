import React from 'react';
import {
  Button,
  Modal,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Glyphicon,
  Jumbotron
} from 'react-bootstrap';
import Footer from '../global-components/Footer';
import ReactGA from './../../ga.js';

class Selection extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      showExamples: false,
      info: "", 
    };
  }

  componentWillMount() {
    this.props.session.on('value', snapshot => {
      var info = snapshot.child("2").val();
      if(info !== null && info !== undefined) {
        this.props.setProjectTitle(info);
        this.setState({ info: info });
      }
    });
  }

  componentWillUnmount() {
    var completed = this.state.info === "" ? "1" : "2";
    this.props.updateCompletion(1, completed, 1, true);
    this.props.session.update({ "/2": this.state.info });
  }

  close() {
    this.setState({ showExamples: false, showCode: false });
  }

  openExamples() {
    this.setState({ showExamples: true });
    ReactGA.event({
      category: 'Selection',
      action: 'Examples',
      label: 'Project title examples clicked',
    });
  }

  handleChange(e) {
    this.setState({ info: e.target.value });
    this.props.setProjectTitle(e.target.value);
  } 

  codeGenerator() {
    var result = '';
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = 6; i > 0; --i) {
      result += chars[Math.round(Math.random() * (chars.length - 1))];
    }
    return result;
  }

  render() {
    var topToolTip = 
    <Tooltip id="topToolTip">
      Click or tap inside the box to type in your information.
    </Tooltip>;

    var examples = 
    <Modal show={this.state.showExamples} onHide={this.close.bind(this)}>
      <Modal.Header>
        <Modal.Title className="green-header">Project Title Examples</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="example-list">
          <li>
            Wait time reduction in Clinic 123
          </li>
          <li>
            Improving patient satisfaction in cardiology
          </li>
          <li>
            Reduce turnaround time for lab results
          </li>
          <li>
            Medication reconciliation error reduction
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Close</Button>
      </Modal.Footer>
    </Modal>

    return (
      <div className="page-shift">
        <Jumbotron className="smaller-jumbotron">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>

          <div>
            <div className="information-title">
              <p id="activity-text">Name Your Project</p>
            </div>
            <div className="selection-container-alone">
              <FormControl autoFocus={true} className="selection-input-alone" bsSize="large" type="text" onChange={this.handleChange.bind(this)} value={this.state.info} placeholder="Insert Project Name"/>
            </div>
            <div className="activity-examples-selection"> 
              <Button className="activity-examples" bsSize="large" onClick={this.openExamples.bind(this)}>
                Project Title Examples
              </Button>
            </div>
          </div>
          {examples}

          <br/>
          <Footer pageName="selection" setEdit={this.props.setEdit} routerKey={this.props.routerKey} history={this.props.history} />
          <br/>
          <br/>

        </Jumbotron>
      </div>
    )
  }
};

export default Selection;
