import ReactGA from 'react-ga';

ReactGA.initialize('UA-120998475-1', {
  // debug: true,
  'cookieDomain': 'auto',
});

export default {
  event: eventObject => {
    // const projectCode = storageHelper.getProjectCode();
    // if (projectCode !== "") {
    //   ReactGA.set({ dimension1: projectCode });
    // }
    ReactGA.event(eventObject);
  },
  pageview: page => {
    // const projectCode = storageHelper.getProjectCode();
    // if (projectCode !== "") {
    //   ReactGA.set({ dimension1: projectCode });
    // }
    ReactGA.pageview(page);    
  }
};
