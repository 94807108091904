const csvHelper = {

    format(values) {
        values.cause = this.formatCause(values.cause);
        values.prioritization = this.formatPrioritization(values.prioritization);
        values.roster = this.formatRoster(values.roster);
        values.solutions = this.formatSolutions(values.solutions);
        values.timeline = this.formatTimeline(values.timeline);
        return values;
    },

    formatSolutions(solutions) {
        if (!solutions || solutions === "" || !solutions.allCauses) { return solutions; }
        const allCauses = JSON.parse(solutions.allCauses);
        const name = [];
        const cause = [];
        const difficulty = [];
        const likelihood = [];
        const allSolutions  = solutions.allSolutions;
        for (const solution of allSolutions) {
            name.push(solution.potential);
            cause.push(allCauses[solution.causes]);
            difficulty.push(solution.difficulty);
            likelihood.push(solution.likelihood);
        }
        return {
            allCauses,
            name,
            cause,
            difficulty,
            likelihood,
        };
    },

    formatTimeline(timeline) {
        if (!timeline || timeline === "" || !timeline.allRoster) { return timeline; }
        const allRoster = JSON.parse(timeline.allRoster);
        const allEvents = timeline.allEvents;
        const date = [];
        const event = [];
        const roster = [];
        for (const e of allEvents) {
            date.push(e.dates.replace(new RegExp('"', 'g'), ''));
            event.push(e.events);
            roster.push(allRoster[e.roster]);
        }
        return {
            date,
            event,
            roster,
            all_roster: allRoster,
        };
    },

    formatRoster(roster) {
        if (!roster || roster === "" || !roster.allRoles) { return roster; }
        const allRoles = JSON.parse(roster.allRoles);
        const team_names = [];
        const team_roles = [];
        const team = roster.team;
        for (const e of team) {
            // Weird formatting from database
            if (Array.isArray(e)) {
                team_names.push(e[1]);
                team_roles.push(allRoles[1]);
            } else {
                const keyArr = Object.keys(e);
                const key = keyArr[0];
                team_names.push(e[key]);
                team_roles.push(allRoles[key]);
            }
        }
        return {
            allRoles,
            team_names,
            team_roles,
        };
    },

    formatCause(cause) {
        if (!cause || cause === "" || !cause.main || !cause.subValues) { return cause; }
        const main = JSON.parse(cause.main);
        const subValues = JSON.parse(cause.subValues);
        return {
            main,
            subValues,
        };
    },

    formatPrioritization(prioritization) {
        if (!prioritization || prioritization === "" || !prioritization.recommended) { return prioritization; }
        const recommended = JSON.parse(prioritization.recommended);
        return {
            recommended,
        };
    },

};

export default csvHelper;
