import React from 'react';
import {
  Button,
  FormControl,
  Modal,
  OverlayTrigger,
  Tooltip,
  Glyphicon,
  Jumbotron,
} from 'react-bootstrap';
import Footer from '../global-components/Footer';
import ReactGA from './../../ga.js';

class Problem extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      initialCheck: true,
      activityPopups: [],
      values: ["", "", "", "", ""],
      show: false,
      dropVal: [0, 0],
    };
  }

  componentWillMount() {
    this.props.session.on('value', snapshot => {
      var show = true;
      var values = [];
      snapshot.forEach((childSnapshot) => {
        let val = childSnapshot.val();
        values[childSnapshot.key] = val;
        if(val === "") { show = false; }
      })
      if(values.length === 5) {
        this.setState({ 
          values: values,
          show: show
        });
      }
    });
  }

  componentWillUnmount() {
    var completed = "1";
    for(var i = 0; i<this.state.values.length; i++) {
      if (this.state.values[i] !== "") {
        completed = "2";
      }
      this.props.session.update({ [i]: this.state.values[i] });
    }
    this.props.updateCompletion(2, completed, 1, true);
  }

  handleKeyPress(index, target) {
    if(target.charCode === 13) {
      this.nextStep(index);
    }
  }

  handleDrop(index, key, e) {
    var dropCopy = this.state.dropVal.slice();
    dropCopy[index] = key;
    this.setState({ dropVal: dropCopy });
  }
  
  close() {
    var allFalse = [];
    for (var i = 0; i < 5; i++) {
      allFalse.push(false);
    }
    this.setState({ activityPopups: allFalse, initialCheck: false });
  }

  exit() {
    var popupCopy = this.state.activityPopups.slice();
    for (var index in popupCopy) {
      popupCopy[index] = false;
    }
    this.setState({ activityPopups: popupCopy });
  }

  nextStep(index) {
    var popupCopy = this.state.activityPopups.slice();
    popupCopy[index] = false;
    popupCopy[index + 1] = true;
    this.setState({ activityPopups: popupCopy });
    ReactGA.event({
      category: 'Problem',
      action: 'Modal Progress',
      label: 'Next step from index: ' + index,
    });
  }

  backStep(index) {
    var popupCopy = this.state.activityPopups.slice();
    popupCopy[index] = false;
    popupCopy[index - 1] = true;
    this.setState({ activityPopups: popupCopy });
    ReactGA.event({
      category: 'Problem',
      action: 'Modal Progress',
      label: 'Back step from index: ' + index,
    });
  }

  openPopup(index) {
    ReactGA.event({
      category: 'Problem',
      action: 'Modal Progress',
      label: 'Popup opened at: ' + index,
    });
    var activityPopupsCopy = this.state.activityPopups.slice();
    activityPopupsCopy[index] = true;
    this.setState({ activityPopups: activityPopupsCopy });
  }

  handleChange(index, e) {
    var valuesCopy = this.state.values.slice();
    var value = e.target.value;
    valuesCopy[index] = value;
    let previousShow = this.state.show;
    var show = true
    for(var i = 0; i<valuesCopy.length; i++) {
      if(valuesCopy[i] === "") {
        show = false;
      }
    }
    if(previousShow) {
      show = true;
    }
    this.setState({ 
      values: valuesCopy, 
      show: show 
    });
  }
  
  render() {
    var topToolTip = 
    <Tooltip id="topToolTip">
      Click on each key element of a problem statement to fill in your particular details. Each element includes examples to help you get started. Once all elements have been completed, your problem statement will appear at the bottom of your screen. Make sure it is a complete sentence and click or tap to edit if necessary. 
    </Tooltip>;

    var values = this.state.values;

    var problemElements = [];
    var problemStatementText = "";
    const extraText = ["", "", "", ".", "."];
    for (var i = 0; i < values.length; i++) {
      var value = values[i];
      if(value) {
        var currentText = values[i] + extraText[i];
        problemStatementText = problemStatementText + currentText + " ";
        problemElements.push(
          <em className="sentence-element" onClick={this.openPopup.bind(this, i)}>
            {currentText}
            &nbsp;
          </em>
        );
      }
    }
    problemElements.push(<br/>);

    var initialCheck =
    <Modal className="activity-modal" show={this.state.initialCheck && this.props.showInstruction} onHide={this.close.bind(this)}>
      <Modal.Body>
        <p className="modal-heading">
          Problem Statement
        </p>
        <img className="modal-image" src="/images/problem.png" alt="..."/>
        <br/>
        <br/>
        <p className="instructions-header">Instructions:</p>
        <p className="modal-text">
          Click on each key element of a problem statement to fill in your particular details. Each element includes examples to help you get started. Once all elements have been completed, your problem statement will appear at the bottom of your screen. Make sure it is a complete sentence and click or tap to edit if necessary. 
        </p>
        <br/>
        <Button className="footer-button" bsSize="large" onClick={this.close.bind(this)}>
          Continue
        </Button> 
        <br/>
        <br/>
      </Modal.Body>
    </Modal>

    var when = 
    <Modal show={this.state.activityPopups[0]} onHide={this.exit.bind(this)}>
      <Modal.Header closeButton>
        <Modal.Title className="green-header">When</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-text">
          Over What period of time did the problem occur?
        </p>
        <h4 className="green-header">Please type below</h4>
        <FormControl autoFocus={true} onKeyPress={this.handleKeyPress.bind(this, 0)} className="activity-input" bsSize="large" type="text" onChange={this.handleChange.bind(this, 0)} value={this.state.values[0]} placeholder="Insert When"/>
        <br />
        Full Problem Statement:
        <p className="modal-text">{problemStatementText}</p>
        <br />  
        Examples:
        <p className="modal-text">
          <ul className="modal-examples">
            <li>
              <em>From October to December</em>, patients at the Pine Street Clinic had to wait 30 minutes on average to see their doctor. This led to unhappy patients.
            </li>
            <li>
              <em>During the last three months</em>, patients in the cardiology service line reported satisfaction at the 45th percentile. This resulted in a decrease in return visits.  
            </li>
            <li>
              <em>Between March and June</em>, hematologists on Unit 9100 received stat coagulation results 65 minutes after the sample was drawn. This caused delayed discharges.
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.nextStep.bind(this, 0)}>Next</Button>
      </Modal.Footer>
    </Modal>

    var who = 
    <Modal show={this.state.activityPopups[1]} onHide={this.exit.bind(this)}>
      <Modal.Header closeButton>
        <Modal.Title className="green-header">Who</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-text">
          Who is the problem affecting?
        </p>
        <h4 className="green-header">Please type below</h4>
        <FormControl autoFocus={true} onKeyPress={this.handleKeyPress.bind(this, 1)} className="activity-input" bsSize="large" type="text" onChange={this.handleChange.bind(this, 1)} value={this.state.values[1]} placeholder="Insert Who"/>
        <br />
        Full Problem Statement:
        <p className="modal-text">{problemStatementText}</p>
        <br />  
        Examples:
        <p className="modal-text">
          <ul className="modal-examples">
            <li>
              From October to December, <em>patients</em> at the Pine Street Clinic had to wait 30 minutes on average to see their doctor. This led to unhappy patients.
            </li>
            <li>
              During the last three months, <em>patients</em> in the cardiology service line reported satisfaction at the 45th percentile. This resulted in a decrease in return visits.  
            </li>
            <li>
              Between March and June, <em>hematologists</em> on Unit 9100 received stat coagulation results 65 minutes after the sample was drawn. This caused delayed discharges.
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="activity-back" onClick={this.backStep.bind(this, 1)}>Back</Button>
        <Button onClick={this.nextStep.bind(this, 1)}>Next</Button>
      </Modal.Footer>
    </Modal>

    var where = 
    <Modal show={this.state.activityPopups[2]} onHide={this.exit.bind(this)}>
      <Modal.Header closeButton>
        <Modal.Title className="green-header">Where</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-text">
          Where is the problem occurring?
        </p>
        <h4 className="green-header">Please type below</h4>
        <FormControl autoFocus={true} onKeyPress={this.handleKeyPress.bind(this, 2)} className="activity-input" bsSize="large" type="text" onChange={this.handleChange.bind(this, 2)} value={this.state.values[2]} placeholder="Insert Where"/>
        <br />
        Full Problem Statement:
        <p className="modal-text">{problemStatementText}</p>
        <br />  
        Examples:
        <p className="modal-text">
          <ul className="modal-examples">
            <li>
              From October to December, patients <em>at the Pine Street Clinic </em>had to wait 30 minutes on average to see their doctor. This led to unhappy patients.
            </li>
            <li>
              During the last three months, patients <em>in the cardiology service line </em>reported satisfaction at the 45th percentile. This resulted in a decrease in return visits.  
            </li>
            <li>
              Between March and June, hematologists <em>on Unit 9100 </em>received stat coagulation results 65 minutes after the sample was drawn. This caused delayed discharges.
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="activity-back" onClick={this.backStep.bind(this, 2)}>Back</Button>
        <Button onClick={this.nextStep.bind(this, 2)}>Next</Button>
      </Modal.Footer>
    </Modal>

    var what = 
    <Modal show={this.state.activityPopups[3]} onHide={this.exit.bind(this)}>
      <Modal.Header closeButton>
        <Modal.Title className="green-header">What</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-text">
          What is the problem?
        </p>
        <h4 className="green-header">Please type below</h4>
        <FormControl autoFocus={true} onKeyPress={this.handleKeyPress.bind(this, 3)} className="activity-input" bsSize="large" type="text" onChange={this.handleChange.bind(this, 3)} value={this.state.values[3]} placeholder="Insert What"/>
        <br />
        Full Problem Statement:
        <p className="modal-text">{problemStatementText}</p>
        <br />  
        Examples:
        <p className="modal-text">
          <ul className="modal-examples">
            <li>
              From October to December, patients at the Pine Street Clinic <em>had to wait 30 minutes on average to see their doctor.</em> This led to unhappy patients.
            </li>
            <li>
              During the last three months, patients in the cardiology service line <em>reported satisfaction at the 45th percentile.</em> This resulted in a decrease in return visits.  
            </li>
            <li>
              Between March and June, hematologists on Unit 9100 <em>received stat coagulation results 65 minutes after the sample was drawn.</em> This caused delayed discharges.
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="activity-back" onClick={this.backStep.bind(this, 3)}>Back</Button>
        <Button onClick={this.nextStep.bind(this, 3)}>Next</Button>
      </Modal.Footer>
    </Modal>

    var harm = 
    <Modal show={this.state.activityPopups[4]} onHide={this.exit.bind(this)}>
      <Modal.Header closeButton>
        <Modal.Title className="green-header">Harm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-text">
          Why is this problem important? Who or what is it harming?
        </p>
        <h4 className="green-header">Please type below</h4>
        <FormControl autoFocus={true} onKeyPress={this.handleKeyPress.bind(this, 4)} className="activity-input" bsSize="large" type="text" onChange={this.handleChange.bind(this, 4)} value={this.state.values[4]} placeholder="Insert Harm"/>
        <br />
        Full Problem Statement:
        <p className="modal-text">{problemStatementText}</p>
        <br />  
        Examples:
        <p className="modal-text">
          <ul className="modal-examples">
            <li>
              From October to December, patients at the Pine Street Clinic had to wait 30 minutes on average to see their doctor. <em>This led to unhappy patients.</em>
            </li>
            <li>
              During the last three months, patients in the cardiology service line reported satisfaction at the 45th percentile. <em>This resulted in a decrease in return visits.</em> 
            </li>
            <li>
              Between March and June, hematologists on Unit 9100 received stat coagulation results 65 minutes after the sample was drawn. <em>This caused delayed discharges.</em>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="activity-back" onClick={this.backStep.bind(this, 4)}>Back</Button>
        <Button onClick={this.close.bind(this)}>Done</Button>
      </Modal.Footer>
    </Modal>

    var buttonDefault = "Start Problem Statement";
    var buttonText = buttonDefault;
    var buttonStart;
    for (i = 0; i < values.length; i++) {
      if(values[i] !== "") {
        continue;
      } else {
        buttonStart = i;
        break
      }
    }
    var anyFilledIn = false;
    for (i = 0; i < values.length; i++) {
      if(values[i] !== "") { anyFilledIn = true; }
    }
    if (anyFilledIn) {
      buttonText = "Edit Problem Statement";
    }
    if (buttonStart === undefined) {
      buttonStart = 0;
    }
    var problemSentenceText = 
      <p className="problem-section-text">
        {problemElements}
      </p>;

    var defaultText = [<p key={-1} className="problem-sentence-text">Your problem statement will appear here once the text fields are filled.</p>];
    const fullProblemText = !anyFilledIn ?
      <div className="problem-sentence">
        {defaultText}
      </div>
      : 
      <div className="problem-sentence-filled">
        {problemSentenceText}
      </div>;

    return (
      <div className="page-shift">
          <Jumbotron className="jumbotron-dashboard">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>

          <div>
            <div className="information-title">
              <p id="activity-text">Problem Statement</p>
            </div>

          <div className="activity-start">
            <Button className="user-button" bsSize="large" onClick={this.openPopup.bind(this, buttonStart)}>
              {buttonText}
            </Button> 
          </div>

          {fullProblemText}
        </div>

        {initialCheck}
        {what}
        {where}
        {who}
        {when}
        {harm}

        <Footer pageName="problem" setEdit={this.props.setEdit} routerKey={this.props.routerKey} history={this.props.history} />

        </Jumbotron>
      </div>
    )
  }
};

export default Problem;
