import React, { Component } from 'react';
import { FormControl, Button, Jumbotron, Alert } from 'react-bootstrap';

const $ = require('jquery');

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertShowing: false,
      alertStyle: 'success',
      alertMessage: '',
      email: '',
      subject: '',
      content: '',
    };
    this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleSubject = this.handleSubject.bind(this);
    this.handleContent = this.handleContent.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.setShowProgress(false);
  }

  componentWillUnmount() {
    this.props.setShowProgress(true);
  }

  handleAlertDismiss() {
    this.setState({ alertShowing: false });
  }

  handleEmail(event) {
    this.setState({
      email: event.target.value,
    });
  }

  handleSubject(event) {
    this.setState({
      subject: event.target.value,
    });
  }

  handleContent(event) {
    this.setState({
      content: event.target.value,
    });
  }

  submit(e) {
    const { email, subject, content } = this.state;

    e.preventDefault();
    if (email !== '' && subject !== '' && content !== '') {

      var formData = new FormData();
      formData.append("email", email);
      formData.append("subject", subject);
      formData.append("content", content);
      $.ajax({
        type: 'POST',
        url: 'https://php.pcforme.org/bitesizeqiemailer.php',
        data: formData,
        dataType: 'json',
        contentType: false,
        processData: false,
      });

      this.setState({
        alertShowing: true,
        alertStyle: 'success',
        alertMessage:
          'Thanks for contacting us! We appreciate your feedback and will respond to you soon.',
        email: '',
        subject: '',
        content: '',
      });
    } else {
      this.setState({
        alertShowing: true,
        alertStyle: 'danger',
        alertMessage: 'Please fill in all fields and try again.',
      });
    }
  }

  render() {
    const { email, subject, content } = this.state;

    return (
      <div>
        <div className="static-content">
          <Jumbotron className="jumbotron-static">
            <div className="static-text">
              <h2>Contact Us</h2>
              <br />
              We want to hear your feedback. Complete the form below to let us know how we can make
              BiteSizeQI{''}&trade; better.
              <br />
              <br />
              {this.state.alertShowing && this.state.alertMessage !== '' ? (
                <Alert bsStyle={this.state.alertStyle} onDismiss={this.handleAlertDismiss}>
                  {this.state.alertMessage}
                </Alert>
              ) : (
                <div />
              )}
              <form onSubmit={this.submit}>
                <FormControl
                  type="email"
                  value={email}
                  onChange={this.handleEmail}
                  placeholder="Your email address"
                />
                <br />
                <FormControl
                  type="text"
                  value={subject}
                  onChange={this.handleSubject}
                  placeholder="Subject"
                />
                <br />
                <FormControl
                  type="textarea"
                  value={content}
                  onChange={this.handleContent}
                  placeholder="Content"
                />
                <br />
                <Button className="contact-button" type="submit" bsSize="large">
                  Submit
                </Button>
              </form>
              <br />
              <br />
              <div className="static-pages"> 
                <a href="#about" className="home-page-text">About</a> 
                &nbsp; • &nbsp; 
                <a href="#terms" className="home-page-text">Terms</a>
                &nbsp; • &nbsp; 
                <a href="#privacy" className="home-page-text">Privacy</a>            
                &nbsp; • &nbsp; 
                <a href="#contact" className="home-page-text">Contact</a>
              </div>
            </div>
          </Jumbotron>
        </div>
      </div>
    );
  }
}
