import ReactGA from './../../ga';

const storageHelper = {

  setProjectCode(code) {
    ReactGA.event({
      category: 'Project Code',
      action: 'Change',
      label: 'to - ' + code,
    });
    localStorage.setItem('projectCode', code);
  },

  getProjectCode() {
    let code = localStorage.getItem('projectCode');
    if (code === null) { return ""; } else { 
      ReactGA.event({
        category: 'Project Code',
        action: 'Get Request',
        label: 'for - ' + code,
      });
      return code; 
    }
  },

  setDashboardBack(page) {
    let previous = localStorage.getItem('dashboardBack');
    if (page === previous) { return; }    
    localStorage.setItem('dashboardBack', page);
  },

  getDashboardBack() {
    return localStorage.getItem('dashboardBack');
  },

  clearDashboardBack() {
    localStorage.setItem('dashboardBack', null);
  },

  setClient(client) {
    localStorage.setItem('client', client);
  },

  getClient() {
    return localStorage.getItem('client');
  },
  
};

export default storageHelper;
