import React from 'react';
import {
  Image,
  Navbar,
  Button,
  Modal,
  Glyphicon,
  Alert,
  FormGroup, 
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { withRouter } from 'react-router';
import ReactGA from './../../ga.js';
import storageHelper from './../helpers/storageHelper.jsx';
import Icon from '@material-ui/core/Icon';
import { amber } from '@material-ui/core/colors';

const $ = require('jquery');

const styling = {
  fontSize: '32px',
  color: amber[400],
}

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      emails: [""],
      showEmail: false,
      sentEmail: false,
    };
  }

  openEmail() {
    this.setState({ showEmail: true })
  }

  close() {
    this.setState({ 
      showEmail: false, 
      sentEmail: false,
    })
  }

  sendEmail() {
    var formData = new FormData();
    ReactGA.event({
      category: 'Main',
      action: 'Email',
      label: this.state.emails.length + ' emails sent from header',
    });
    formData.append("code", this.props.projectCode);
    formData.append("emails", this.state.emails);
    $.ajax({
      type: 'POST',
      url: 'https://php.pcforme.org/codesender.php',
      data: formData,
      dataType: 'json',
      contentType: false,
      processData: false,
    });  
    this.setState({ 
      sentEmail: true,
      showEmail: false,
      emails: [""],
    });
  }

 handleChange(index, e) {
    var emailsCopy = this.state.emails.slice();
    emailsCopy[index] = e.target.value;
    this.setState({ emails: emailsCopy, sentEmail: false });
  }

  removeField(index, e) {
    var emailsCopy = this.state.emails.slice();
    emailsCopy.splice(index, 1);
    this.setState({ emails: emailsCopy });  
  }

  addField() {
    var emailsCopy = this.state.emails.slice();
    emailsCopy.push("");
    this.setState({ emails: emailsCopy });
  }
  
  render () {
    var projectCode = this.props.projectCode;
    var projectCodeText;
    var projectTitleText;
    var emailPicture = null;
    if (projectCode !== "") {
      projectCodeText = "Your Project Code is: " + projectCode;
      projectTitleText = this.props.projectTitle;
      emailPicture = 
      <div className="header-email" onClick={this.openEmail.bind(this)}>
        <Icon style={styling}>email</Icon>
      </div>
    } else {
      projectCodeText = "";
      projectTitleText = "";
    }

    var projetCodeText = "Your Project Code: " + this.props.projectCode;
    var showCode =         
    <Modal show={this.state.showEmail} onHide={this.close.bind(this)}>
      <Modal.Body>
        <span id="show-project-code">{projetCodeText}</span>
        <h5>
          Enter your email in the box below and BiteSizeQI will email you your project code to save for later use.  Remember, if you do not have your project code, you cannot retrieve your work.  
        </h5>
        {this.state.emails.map((value, i) => (
          <FormGroup key={i}>
            <InputGroup>
              <FormControl type="text" onChange={this.handleChange.bind(this, i)} value={value} placeholder="Insert Email"/>
              <InputGroup.Button>
                <Button bsStyle="danger" onClick={this.removeField.bind(this, i)}><Glyphicon glyph="remove" /></Button>
              </InputGroup.Button>
            </InputGroup>
          </FormGroup>
        ))}
        <Button className="user-button" onClick={this.addField.bind(this)}>Add Email Address</Button>
        <Button className="user-button user-send" onClick={this.sendEmail.bind(this)}>Send Email</Button>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Close</Button>
      </Modal.Footer>
    </Modal>;

    var sentEmail = 
    <Modal show={this.state.sentEmail} onHide={this.close.bind(this)}>
      <Modal.Body>
        <div>
          <Alert bsStyle="success">
            Your email was sent!
          </Alert>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Continue</Button>
      </Modal.Footer>
    </Modal>;
    const client = storageHelper.getClient();
    const lastURL = client === "bsqi" ? "" : client;
    const baseURL = "/" + lastURL;
    return (
      <Navbar className="header-nav" fixedTop>
        <Navbar.Brand>
          {this.props.logoImage ? <div><a href={baseURL}><Image className="header-logo" style={this.props.logoStyling} src={this.props.logoImage}/></a></div>
            : null}
        </Navbar.Brand>
        <Navbar.Toggle />
        <p className="header-title">{projectTitleText}</p>
        <div className="header-code">
          <p className="header-code-text">
            {projectCodeText}
          </p>
          {emailPicture}
        </div>
        {showCode}
        {sentEmail}
      </Navbar>
    )
  }
};

export default withRouter(Header);
