import React from 'react';
import {
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Glyphicon,
  Jumbotron
} from 'react-bootstrap';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import DraggableSolution from '../helpers/dragging/DraggableSolution.jsx';
import update from 'immutability-helper';
import Footer from '../global-components/Footer';

const style = {
  width: 500,
  margin: 'auto',
}

class Prioritization extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      initialCheck: true,
      solutions: [
        {
          id: 1,
          text: 'Please complete the previous activity',
          star: true,
        },
      ],
    };
  }

  sortSolutions(a, b) {
    return (a["text"].localeCompare(b["text"]));
  }

  sameSolutions(s, p) {
    var solutions = s.slice(0).sort(this.sortSolutions);
    var prev = p.slice(0).sort(this.sortSolutions);
    return JSON.stringify(solutions) === JSON.stringify(prev);
  }

  componentWillMount() {
    this.props.session.on('value', snapshot => {
      let recommended = JSON.parse(snapshot.child("recommended").val());
      if(recommended === null || recommended.length === 0) { return; }
      var solutions = [];
      for(var i in recommended) {
        solutions.push({ id: i, text: recommended[i], star: (i === "0" ) });
      }
      let prev = JSON.parse(snapshot.child("prevSolutions").val());
      if(prev === null || prev.length === 0) { 
        this.setState({ solutions: solutions });
        return; 
      }
      if(solutions === null || solutions.length === 0) { return; }
      if(this.sameSolutions(solutions, prev)) {
        this.setState({ solutions: prev });
      } else {
        this.setState({ solutions: solutions });
      }
    });  
  }

  componentWillUnmount() {
    this.props.session.update({ "prevSolutions": JSON.stringify(this.state.solutions) });
    this.setTimelineSolutions();
  }

  setTimelineSolutions() {
    this.props.timelineSession.update({ solutions: JSON.stringify(this.state.solutions) });
  }
  
  close() {
    this.setState({ initialCheck: false });
  }

  moveCard(dragIndex, hoverIndex) {
    const { solutions } = this.state
    const dragCard = solutions[dragIndex]

    this.setState(
      update(this.state, {
        solutions: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
        },
      }),
    )
  }

  render(){
    var topToolTip = 
    <Tooltip id="topToolTip">
    Your solutions have now been re-ordered from easiest to do and most likely to work to hardest to do and most unlikely to work. If you prefer a different order, you can click and drag the solutions to reorder. Your top three solutions will carry forward to the next activity. 
    </Tooltip>;

    const showAlert = this.state.initialCheck && this.props.showInstruction;

    var initialCheck =
    <Modal className="activity-modal" show={showAlert} onHide={this.close.bind(this)}>
      <Modal.Body>
        <p className="modal-heading">
          Prioritization
        </p>
        <img className="modal-image" src="/images/solutions.png" alt="..."/>
        <br/>
        <br/>
        <p className="instructions-header">Instructions:</p>
        <p className="modal-text">
          Your solutions have now been re-ordered from easiest to do and most likely to work to hardest to do and most unlikely to work. If you prefer a different order, you can click and drag the solutions to reorder. Your top three solutions will carry forward to the next activity. 
        </p>
        <br/>
        <Button className="footer-button" bsSize="large" onClick={this.close.bind(this)}>
          Continue
        </Button> 
        <br/>
        <br/>
      </Modal.Body>
    </Modal>

    return (
      <div className="page-shift">
        <Jumbotron className="jumbotron-dashboard">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>

          <div>
            <div className="information-title">
              <p id="activity-text">Prioritization</p>
            </div>
            <div className="prioritization-text">
              <span id="prioritization-text">
                Congratulations! Your hard work has paid off and now you have potential solutions to your problem! We’ve highlighted the top three solutions based on your responses in the previous activity, but feel free to rearrange them if you would like. The top three solutions carry forward to the next activity.  
              </span>         
            </div>

            <div style={style}>
              {this.state.solutions.map((sol, i) => (
                <DraggableSolution
                  star={sol.star}
                  key={sol.id}
                  index={i}
                  id={sol.id}
                  text={sol.text}
                  moveCard={this.moveCard.bind(this)}
                />
              ))}
            </div>
          </div>

        {showAlert ? initialCheck : null}

        <Footer pageName="prioritization" setEdit={this.props.setEdit} routerKey={this.props.routerKey} history={this.props.history} />

        </Jumbotron>
      </div>
    )
  }
};

export default DragDropContext(HTML5Backend)(Prioritization);
