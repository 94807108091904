import React from 'react';
import {
  Button,
  FormControl,
  Modal,
  OverlayTrigger,
  Tooltip,
  FormGroup,
  InputGroup,
  Glyphicon,
  Jumbotron
} from 'react-bootstrap';
import Footer from '../global-components/Footer';
import ReactGA from './../../ga.js';
import Icon from '@material-ui/core/Icon';

const styling = {
  fontSize: '28px',
}

class Cause extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      initialCheck: true,
      showExamples: [false, false, false, false],
      mainValues: ["Processes", "People", "Equipment", "Environment"],
      subValues: [["", "", "", ""], ["", "", "", ""], ["", "", "", ""], ["", "", "", ""]],
      checkValues: [[false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false]],
    };
  }

  componentWillMount() {
    this.props.session.on('value', snapshot => {
      let mainValues = JSON.parse(snapshot.child("main").val());
      let subValues = JSON.parse(snapshot.child("subValues").val());
      let checkValues = JSON.parse(snapshot.child("checkValues").val());
      if(subValues !== null && subValues.length >= 3) {
        this.setState({
          mainValues: mainValues, 
          subValues: subValues,
          checkValues: checkValues,
        });
      }
    });
  }

  componentWillUnmount() {
    var completed = "1";
    for(var i in this.state.subValues) {
      for(var j in this.state.subValues[i]) {
        if (this.state.subValues[i][j] !== "") {
          completed = "2";
        }
      }
    }
    this.props.session.update({
      main: JSON.stringify(this.state.mainValues),
      subValues: JSON.stringify(this.state.subValues),
      checkValues: JSON.stringify(this.state.checkValues)
    });
    this.props.updateCompletion(6, completed, 2, true);  
  }
  
  close() {
    this.setState({ showExamples: [false, false, false, false], initialCheck: false });
  }

  openPopup(index) {
    var activityPopupsCopy = this.state.activityPopups.slice();
    activityPopupsCopy[index] = true;
    this.setState({ activityPopups: activityPopupsCopy });
  }

  handleMain(index, e) {
    var valuesCopy = this.state.mainValues.slice();
    var value = e.target.value;
    valuesCopy[index] = value;
    this.setState({ mainValues: valuesCopy });
    ReactGA.event({
      category: 'Cause',
      action: 'Modal Progress',
      label: 'Open Main Popup: ' + index,
    });
  }

  handleSub(index, sub, e) {
    var valuesCopy = this.state.subValues.slice();
    var value = e.target.value;
    valuesCopy[index][sub] = value;
    this.setState({ subValues: valuesCopy });
  }

  openExamples(index) {
    var examples = this.state.showExamples.slice();
    examples[index] = true;
    this.setState({ showExamples: examples });
  }

  addSub(index) {
    var copy = this.state.subValues.slice();
    copy[index].push("");
    var checkCopy = this.state.checkValues.slice();
    checkCopy[index].push(false);
    this.setState({ 
      subValues: copy, 
      checkValues: checkCopy,
    });
  }

  removeSub(index, sub) {
    var copy = this.state.subValues.slice();
    copy[index].splice(sub, 1);
    var checkCopy = this.state.checkValues.slice();
    checkCopy[index].splice(sub, 1);
    this.setState({ 
      subValues: copy, 
      checkValues: checkCopy,
    });
  }

  render(){
    var topToolTip = 
    <Tooltip id="topToolTip">
      Click or tap in the empty boxes to type in potential causes you have identified. If you would prefer a different category that would better fit your project, you can click or tap within that box to change the heading of each section. Click the Examples button to see other possible causes for each section. Once you have identified all possible causes for your problem, click the star button next to the five causes you want to focus on in the next section.  
    </Tooltip>;

    var initialCheck =
    <Modal className="activity-modal" show={this.state.initialCheck && this.props.showInstruction} onHide={this.close.bind(this)}>
      <Modal.Body>
        <p className="modal-heading">
          Cause and Effect
        </p>
        <img className="modal-image" src="/images/cause.png" alt="..."/>
        <br/>
        <br/>
        <p className="instructions-header">Instructions:</p>
        <p className="modal-text">
          Click or tap in the empty boxes to type in potential causes you have identified. If you would prefer a different category that would better fit your project, you can click or tap within that box to change the heading of each section. Click the Examples button to see other possible causes for each section. Once you have identified all possible causes for your problem, click the star button next to the five causes you want to focus on in the next section.  
        </p>
        <br/>
        <Button className="footer-button" bsSize="large" onClick={this.close.bind(this)}>
          Continue
        </Button> 
        <br/>
        <br/>
      </Modal.Body>
    </Modal>

    var mains = [];
    for(var i = 0; i<this.state.mainValues.length; i++) {
      var buttonText = this.state.mainValues[i];
      buttonText = buttonText === "" ? "Click Here!" : buttonText;
      mains.push(
        <div key={i} className={"cause-main-div cause-main-" + i}>
          <Button className="cause-examples" bsSize="small" onClick={this.openExamples.bind(this, i)}>
            {buttonText} &nbsp; &nbsp;
            <div className="cause-main-image">
              <Icon style={styling}>search</Icon>
            </div>
          </Button>
        </div>
      );
    };

    var subs = [];
    for(i = 0; i<this.state.subValues.length; i++) {
      let subValues = this.state.subValues[i];
      let subComponents = []
      for(var j in subValues) {
        subComponents.push(
          <Button className="cause-button" onClick={this.openExamples.bind(this, i)}>
            {this.state.subValues[i][j]}
          </Button> 
        );
      }
      subs.push(subComponents);
    }

    var exampleSubs = [];
    for(i = 0; i<this.state.subValues.length; i++) {
      let subValues = this.state.subValues[i];
      let subComponents = []
      for(j in subValues) {
        var formControlClass = "cause-input";
        formControlClass = j < 4 ? formControlClass + " cause-sub-width" : formControlClass;
        subComponents.push(
          <FormGroup key={(i+10)*(j+3)} className="cause-sub-comp">
            <InputGroup className="cause-full-width">
              <FormControl className={formControlClass} style={{zIndex: 0}} type="text" bsSize="small" onChange={this.handleSub.bind(this, i, j)} value={this.state.subValues[i][j]} />
              {j >= 4 ? (
                <InputGroup.Button>
                  <Button bsStyle="danger" bsSize="small" onClick={this.removeSub.bind(this, i, j)}><Glyphicon glyph="remove" /></Button>
                </InputGroup.Button>)
                : null
              }
            </InputGroup>
          </FormGroup>
        );
      }
      subComponents.push(
        <div key={-1*(i+1)} className="padding-bottom-10">
          <Button className="cause-add" bsSize="small" onClick={this.addSub.bind(this, i)}>
            Add Cause
          </Button>
        </div>
      )
      exampleSubs.push(subComponents);
    }

    var allSubs = [];
    for(i = 0; i<this.state.subValues.length; i++) {
      allSubs.push(
        <div key={-3*(i+1)} className={"cause-sub cause-sub-" + i}>
        {subs[i]}
        </div>
      )
    }

    var examples1 = 
    <Modal show={this.state.showExamples[0]} onHide={this.close.bind(this)}>
      <Modal.Header>
        <Modal.Title className="green-header">{this.state.mainValues[0]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Process Example:
        <ul>
          <li>
            Procedure is outdated
          </li>
          <li>
            No checklist or standard protocol
          </li>
          <li>
            Requires unnecessary hand-offs
          </li>
        </ul>
        Category:
        <FormControl
          className="cause-main"
          type="text" 
          onChange={this.handleMain.bind(this, 0)} 
          value={this.state.mainValues[0]} placeholder="Type Here"/>
        <br/>
        <br/>
        Causes:
        <div>
          {exampleSubs[0]}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Close</Button>
      </Modal.Footer>
    </Modal>

    var examples2 = 
    <Modal show={this.state.showExamples[1]} onHide={this.close.bind(this)}>
      <Modal.Header>
        <Modal.Title className="green-header">{this.state.mainValues[1]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        People Example:
        <ul>
          <li>
            Staff turnover prevents proper training
          </li>
          <li>
            Nurses having to leave post to back up colleagues
          </li>
          <li>
            Leadership does not respond to requests for help 
          </li>
        </ul>
        Category:
        <FormControl
          className="cause-main"
          type="text" 
          onChange={this.handleMain.bind(this, 1)} 
          value={this.state.mainValues[1]} placeholder="Type Here"/>
        <br/>
        <br/>
        Causes:
        <div>
          {exampleSubs[1]}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Close</Button>
      </Modal.Footer>
    </Modal>

    var examples3 = 
    <Modal show={this.state.showExamples[2]} onHide={this.close.bind(this)}>
      <Modal.Header>
        <Modal.Title className="green-header">{this.state.mainValues[2]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Equipment Example:
        <ul>
          <li>
            Machine too slow causing hold up
          </li>
          <li>
            Machine produces defective labels that are manually overwritten
          </li>
          <li>
            Equipment malfunctions causing the backup process to be used twice each week   
          </li>
        </ul>
        Category:
        <FormControl
          className="cause-main"
          type="text" 
          onChange={this.handleMain.bind(this, 2)} 
          value={this.state.mainValues[2]} placeholder="Type Here"/>
        <br/>
        <br/>
        Causes:
        <div>
          {exampleSubs[2]}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Close</Button>
      </Modal.Footer>
    </Modal>

    var examples4 = 
    <Modal show={this.state.showExamples[3]} onHide={this.close.bind(this)}>
      <Modal.Header>
        <Modal.Title className="green-header">{this.state.mainValues[3]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Environment Example:
        <ul>
          <li>
            Laboratory instrument located in different building
          </li>
          <li>
            Area used as walk way impacting infection control processes
          </li>
          <li>
            Excessive noise causing distraction
          </li>
        </ul>
        Category:
        <FormControl
          className="cause-main"
          type="text" 
          onChange={this.handleMain.bind(this, 3)} 
          value={this.state.mainValues[3]} placeholder="Type Here"/>
        <br/>
        <br/>
        Causes:
        <div>
          {exampleSubs[3]}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Close</Button>
      </Modal.Footer>
    </Modal>

    return (
      <div className="page-shift">
        <Jumbotron className="jumbotron-dashboard">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>
          <div>
            <div className="information-title">
              <p id="activity-text">Cause and Effect</p>
            </div>

            <div className="cause-top-scrollable"> 
              <div className="inside-scrollable">

                {allSubs}
                {mains}
                
                <div className="main-arrow">
                  <div className="main-line"></div>
                </div>

                <div className="sub-arrow top-left-arrow">
                  <div className="sub-line"></div>
                </div>

                <div className="sub-arrow bot-left-arrow">
                  <div className="sub-line"></div>
                </div>

                <div className="sub-arrow top-right-arrow">
                  <div className="sub-line"></div>
                </div>

                <div className="sub-arrow bot-right-arrow">
                  <div className="sub-line"></div>
                </div>

                <div className="cause-end">
                  <div className="cause-what">{this.props.problemStatement}</div>
                </div>

              </div>
            </div>
          </div>

          {examples1}
          {examples2}
          {examples3}
          {examples4}
          {initialCheck}

          <Footer pageName="cause" setEdit={this.props.setEdit} routerKey={this.props.routerKey} history={this.props.history} />

        </Jumbotron>
      </div>
    )
  }
};

export default Cause;
