import React from 'react';
import {
  Button,
  Grid,
  Row,
  Col,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Glyphicon,
  Jumbotron
} from 'react-bootstrap';
import Footer from '../global-components/Footer';

class Information extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      info: ["", ""], 
    };
  }

  componentWillMount() {
    this.props.session.on('value', snapshot => {
      var info = [snapshot.child("0").val(), snapshot.child("1").val()];
      if(info.length === 2 && info[0] !== null) {
        this.setState({ info: info });
      }
    });
  }

  componentWillUnmount() {
    for(var i in this.state.info) {
      this.props.session.update({ [i]: this.state.info[i] });
    }
    var completed = "1";
    for(var index in this.state.info) {
      var value = this.state.info[index];
      if (value !== "" && value !== null) { 
        completed = "2";
      }
    }
    this.props.updateCompletion(0, completed, 1, true);
  }

  handleChange(index, e) {
    var infoCopy = this.state.info.slice();
    infoCopy[index] = e.target.value;
    this.setState({ info: infoCopy });
  } 

  codeGenerator() {
    var result = '';
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = 6; i > 0; --i) {
      result += chars[Math.round(Math.random() * (chars.length - 1))];
    }
    return result;
  }

  render(){
    var topToolTip = 
    <Tooltip id="topToolTip">
      Click or tap inside the box to type in your information.
    </Tooltip>;

    return (
      <div className="page-shift">
        <Jumbotron className="smaller-jumbotron">

          <div className="absolute-tooltip">
            <div className="hidden-xs">
              <OverlayTrigger placement="left" overlay={topToolTip} >
                <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
              </OverlayTrigger>
            </div>
            <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
          </div>

          <div>
            <div className="information-title">
              <p id="activity-text">Your Information</p>
            </div>
            <div className="selection-container">
              <Grid>
                <Row className="selection-grid">
                  <Col xs={8} md={4}><span id="selection-label">Organization</span></Col>
                  <Col xs={10} md={8}>
                    <FormControl autoFocus={true} className="selection-input" bsSize="large" type="text" onChange={this.handleChange.bind(this, 0)} value={this.state.info[0]} placeholder="Insert Organization Name"/>
                  </Col>
                </Row>
                <Row className="selection-grid">
                  <Col xs={8} md={4}><span id="selection-label">Your Name</span></Col>
                  <Col  xs={10} md={8}>
                    <FormControl className="selection-input" bsSize="large" type="text" onChange={this.handleChange.bind(this, 1)} value={this.state.info[1]} placeholder="Insert Your Name"/>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
          
          <br/>
          <Footer pageName="information" setEdit={this.props.setEdit} routerKey={this.props.routerKey} history={this.props.history} />
          <br/>
          <br/>

        </Jumbotron>
      </div>
    )
  }
};

export default Information;
