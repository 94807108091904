import React from 'react';
import {
  Image,
  Table,
  Button,
  Modal,
  Glyphicon,
  Alert,
  FormGroup, 
  InputGroup,
  FormControl,
  Jumbotron,
} from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import ReactGA from './../../ga.js';

const $ = require('jquery');
const styling = {
  fontSize: '32px',
}

class User extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      showCode: false,
      emails: [""],
      sentEmail: false,
      techCheckShowing: true,
    };
  }
  
  componentDidMount() {
    this.props.setShowProgress(false);
  }

  componentWillUnmount() {
    this.props.setShowProgress(true);
  }

  newUser() {
    this.setState({ showCode: true });
    this.props.addNewUser();
    this.props.setReturningUser(false);
    ReactGA.event({
      category: 'User',
      action: 'Continue',
      label: 'New User',
    });
  }

  returningUser() {
    this.props.history.push('/project');
    ReactGA.event({
      category: 'User',
      action: 'Continue',
      label: 'Returning User',
    });
  }

  back() {
    this.props.history.push('/');
    ReactGA.event({
      category: 'User',
      action: 'Back',
      label: 'Back to Home Page',
    });
  }

  close() {
    this.setState({ showCode: false });
    this.props.history.push('/welcomevideo');
  }

 handleChange(index, e) {
    var emailsCopy = this.state.emails.slice();
    emailsCopy[index] = e.target.value;
    this.setState({ emails: emailsCopy, sentEmail: false });
  }

  removeField(index, e) {
    var emailsCopy = this.state.emails.slice();
    emailsCopy.splice(index, 1);
    this.setState({ emails: emailsCopy });  
  }

  addField() {
    var emailsCopy = this.state.emails.slice();
    emailsCopy.push("");
    this.setState({ emails: emailsCopy });
  }

  sendEmail() {
    if(this.state.sentEmail) { return; }
    ReactGA.event({
      category: 'User',
      action: 'Email',
      label: this.state.emails.length + ' emails sent',
    });
    var formData = new FormData();
    formData.append("code", this.props.projectCode);
    formData.append("emails", this.state.emails);
    $.ajax({
      type: 'POST',
      url: 'https://php.pcforme.org/codesender.php',
      data: formData,
      dataType: 'json',
      contentType: false,
      processData: false,
    });  
    this.setState({ 
      sentEmail: true, 
      showCode: false, 
    });
  }

  closeTechCheck() {
    this.setState({ techCheckShowing: false });
  }

  render(){
    let codeShowing = localStorage.getItem('codeModal') === "true" ? true : false;
    let showTechCheck = this.state.techCheckShowing && !codeShowing;

    var techCheck = 
      <Modal show={showTechCheck} onHide={this.closeTechCheck.bind(this)}>
        <Modal.Header>
          <Modal.Title className="green-header">Before You Begin...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
            </thead>
            <tbody>
            <tr>
              <td>
                <Image className="tech-check-image img-responsive center-block" src="/images/sound.png" responsive />
              </td>
              <td>
                <b>Is your volume ON and UP?</b>
                <br />
                Check that your volume button is unmuted and you can hear sound clearly.
              </td>
            </tr>
            <tr>
              <td>
                <Image className="tech-check-image img-responsive center-block" src="/images/connected.png" responsive />
              </td>
              <td>
                <b>Are you connected to the internet?</b>
                <br />
                To access all of the site functionality your device must have an active internet connection.
              </td>
            </tr>
            <tr>
              <td>
                <Image className="tech-check-image img-responsive center-block" src="/images/help.png" responsive />
              </td>
              <td>
                <b>Need more help or have questions?</b>
                <br />
                If you need help at any point, click the question mark located in the top right throughout this site.
              </td>
            </tr>

            <tr>
              <td>
                <Image className="tech-check-image img-responsive center-block" src="/images/save.png" responsive />
              </td>
              <td>
                <b>Need to take a break but have not finished?</b>
                <br />
                You can save your progress and blueprint by writing down your unique code, always visible on the top right of your screen. Upon returning, you can enter that code to pick up right where you left off.
              </td>
            </tr>

            </tbody>
          </Table>
          </Modal.Body>
        <Modal.Footer>
          <Button className="green-button" bsStyle="success" onClick={this.closeTechCheck.bind(this)}>Continue</Button>
        </Modal.Footer>
      </Modal>

    var projetCodeText = "Your Project Code: " + this.props.projectCode;
    var codeModal =         
    <Modal show={this.state.showCode} onHide={this.close.bind(this)}>
      <Modal.Body>
        <span id="show-project-code">{projetCodeText}</span>
        <h5>
          When you start a new project, that project is assigned a unique 6-digit code that allows you to return to your project at any time.  To return to your work, simply enter this code at the home screen next time you visit BiteSizeQI.  
        </h5> 
        <h5>
          Enter your email in the box below and BiteSizeQI will email you your project code to save for later use.  Remember, if you do not have your project code, you cannot retrieve your work.  
        </h5>
        {this.state.emails.map((value, i) => (
          <FormGroup key={i}>
            <InputGroup>
              <FormControl type="text" onChange={this.handleChange.bind(this, i)} value={value} placeholder="Insert Email"/>
              <InputGroup.Button>
                <Button bsStyle="danger" onClick={this.removeField.bind(this, i)}><Glyphicon glyph="remove" /></Button>
              </InputGroup.Button>
            </InputGroup>
          </FormGroup>
        ))}
        <Button className="user-button" onClick={this.addField.bind(this)}>Add Email Address</Button>
        <Button className="user-button user-send" onClick={this.sendEmail.bind(this)}>Send Email</Button>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Close</Button>
      </Modal.Footer>
    </Modal>;

    var sentEmail = 
    <Modal show={this.state.sentEmail} onHide={this.close.bind(this)}>
      <Modal.Body>
        <div>
          <Alert bsStyle="success">
            Your email was sent!
          </Alert>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Continue</Button>
      </Modal.Footer>
    </Modal>;

    return (
      <div className="page-center">
        <Jumbotron className="jumbotron-nav">
          <div className="two-button-shift">
            <br/>
            <div className="inline intro-buttons">
              <Button className="gray-btn" bsSize="large" block onClick={this.newUser.bind(this)}><b>New User</b></Button>
              <br/>
              <Button className="gray-btn next-block" bsSize="large" block onClick={this.returningUser.bind(this)}><b>Returning User</b></Button>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            {techCheck}
            {codeModal}
            {sentEmail}
            <div className="footer-buttons">
              <Button className="footer-button left-footer-button" bsSize="large" onClick={this.back.bind(this)}>
                <div className="footer-left-image">
                  <Icon style={styling}>keyboard_arrow_left</Icon>
                </div>
                &nbsp; &nbsp; Back
              </Button>  
            </div>
          </div>
        </Jumbotron>
      </div>
    )
  }
};

export default User;
