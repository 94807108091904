import React from 'react';
import {
  Button,
} from 'react-bootstrap';
import ReactGA from './../../ga.js';

class Home extends React.Component{

  componentDidMount() {
    document.body.style.backgroundImage = "url('/images/Background.png')";
    this.props.setProjectCode("");
    this.props.setShowProgress(false);
  }

  componentWillUnmount() {
    document.body.style.backgroundImage = "";
    this.props.setShowProgress(true);
  }

  handleAlertDismiss() {
    this.setState({ errorShowing: false });
  }

  handleAlertShow() {
    this.setState({ errorShowing: true });
  }

  next() {
    ReactGA.event({
      category: 'Home',
      action: 'Next',
      label: 'Getting started button',
    });
    this.props.setShowProgress(true);
    this.props.history.push('/user');
  }

  render(){
    return (
      <div>
          <div className="homeview-page">
            <div className="homeview-content">
              <p className="homeview-main">bitesize</p>
              <p className="homeview-orange">QI</p>
              <div className="homeview-subtext">
                <p>
                  Quality Improvement in Bite Size Portions
                </p>
              </div>
              <br/>
              <Button className="homeview-button" bsSize="large" onClick={this.next.bind(this)}>Get Started<br />
              </Button>  
            </div>
          </div>
          <div className="home-copyright">Duke University 2018 Copyright ©</div>

          <div className="home-pages"> 
            <a href="#about" className="home-page-text">About</a> 
            &nbsp; • &nbsp; 
            <a href="#terms" className="home-page-text">Terms</a>
            &nbsp; • &nbsp; 
            <a href="#privacy" className="home-page-text">Privacy</a>            
            &nbsp; • &nbsp; 
            <a href="#contact" className="home-page-text">Contact</a>
          </div>

      </div>
    )
  }
};

export default Home;
