import React from 'react';
import {
  Button,
  FormControl,
  Modal,
  OverlayTrigger,
  Tooltip,
  Glyphicon,
  Jumbotron,
} from 'react-bootstrap';
import Footer from '../global-components/Footer';
import ReactGA from './../../ga.js';

class Aim extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      initialCheck: true,
      activityPopups: [],
      values: ["", "", "", ""],
      show: false
    };
  }

  handleKeyPress(index, target) {
    if(target.charCode === 13) {
      this.nextStep(index);
    }
  }

  exit() {
    var popupCopy = this.state.activityPopups.slice();
    for (var index in popupCopy) {
      popupCopy[index] = false;
    }
    this.setState({ activityPopups: popupCopy });
  }

  componentWillMount() {
    this.props.session.on('value', snapshot => {
      var show = true;
      var values = [];
      snapshot.forEach((childSnapshot) => {
        let val = childSnapshot.val();
        values[childSnapshot.key] = val;
        if(val === "") { show = false; }
      })
      if(values.length === 4) {
        this.setState({ 
          values: values,
          show: show
        });
      }
    });
  }

  componentWillUnmount() {
    var completed = "1";
    for(var i = 0; i<this.state.values.length; i++) {
      if (this.state.values[i] !== "") {
        completed = "2";
      }
      this.props.session.update({ [i]: this.state.values[i] });
    }
    this.props.updateCompletion(3, completed, 1, true);
  }
  
  close() {
    var allFalse = [];
    for (var i = 0; i < 6; i++) {
      allFalse.push(false);
    }
    this.setState({ activityPopups: allFalse, initialCheck: false });
  }

  nextStep(index) {
    var popupCopy = this.state.activityPopups.slice();
    popupCopy[index] = false;
    popupCopy[index + 1] = true;
    this.setState({ activityPopups: popupCopy });
    ReactGA.event({
      category: 'Aim',
      action: 'Modal Progress',
      label: 'Next step from index: ' + index,
    });
  }

  backStep(index) {
    var popupCopy = this.state.activityPopups.slice();
    popupCopy[index] = false;
    popupCopy[index - 1] = true;
    this.setState({ activityPopups: popupCopy });
    ReactGA.event({
      category: 'Aim',
      action: 'Modal Progress',
      label: 'Next step from index: ' + index,
    });
  }

  openPopup(index) {
    ReactGA.event({
      category: 'Aim',
      action: 'Modal Progress',
      label: 'Popup opened at: ' + index,
    });
    var activityPopupsCopy = this.state.activityPopups.slice();
    activityPopupsCopy[index] = true;
    this.setState({ activityPopups: activityPopupsCopy });
  }

  handleChange(index, e) {
    var valuesCopy = this.state.values.slice();
    var value = e.target.value;
    valuesCopy[index] = value;
    let previousShow = this.state.show;
    var show = true
    for(var i = 0; i<valuesCopy.length; i++) {
      if(valuesCopy[i] === "") {
        show = false;
      }
    }
    if(previousShow) {
      show = true;
    }
    this.setState({ 
      values: valuesCopy, 
      show: show 
    });
  }

  render() {
    var values = this.state.values;

    var aimElements = [];
    var aimStatementText = "";
    const extraText = ["", "", "", "."];
    for (var i = 0; i < values.length; i++) {
      var value = values[i];
      if (value) {
        var currentText = values[i] + extraText[i];
        aimStatementText = aimStatementText + currentText + " ";
        aimElements.push(
          <em className="sentence-element" onClick={this.openPopup.bind(this, i)}>
            {currentText}
            &nbsp;
          </em>
        );
      }
    }
    aimElements.push(<br/>);
    var aimSentenceText = 
      <p className="problem-section-text">
        {aimElements}
      </p>;

    var topToolTip = 
    <Tooltip id="topToolTip">
      Click on each key element of a aim statement to fill in your particular details. Each element includes examples to help you get started. Once all elements have been completed, your aim statement will appear at the bottom of your screen. Make sure it is a complete sentence and click or tap to edit if necessary. 
    </Tooltip>;

    var initialCheck =
    <Modal className="activity-modal" show={this.state.initialCheck && this.props.showInstruction} onHide={this.close.bind(this)}>
      <Modal.Body>
        <p className="modal-heading">
          Aim Statement
        </p>
        <img className="modal-image" src="/images/aim.png" alt="..."/>
        <br/>
        <br/>
        <p className="instructions-header">Instructions:</p>
        <p className="modal-text">
          Click on each key element of a Aim statement to fill in your particular details. Each element includes examples to help you get started. Once all elements have been completed, your Aim statement will appear at the bottom of your screen. Make sure it is a complete sentence and click or tap to edit if necessary. 
        </p>
        <br/>
        <Button className="footer-button" bsSize="large" onClick={this.close.bind(this)}>
          Continue
        </Button> 
        <br/>
        <br/>
      </Modal.Body>
    </Modal>

    var when = 
    <Modal show={this.state.activityPopups[0]} onHide={this.exit.bind(this)}>
      <Modal.Header closeButton>
        <Modal.Title className="green-header">When</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-text">
          When is the problem going to be improved?
        </p>
        <h4 className="green-header">Please type below</h4>
        <FormControl autoFocus={true} onKeyPress={this.handleKeyPress.bind(this, 0)} className="activity-input" bsSize="large" type="text" onChange={this.handleChange.bind(this, 0)} value={this.state.values[0]} placeholder="Insert When"/>
        <br />
        Full Aim Statement:
        <p className="modal-text">{aimStatementText}</p>
        <br />  
        Examples:
        <p className="modal-text">
          <ul className="modal-examples">
            <li>
              <em>In the next four months</em>, wait times as measured from patient check-in to the moment the physician walks into the exam room at the Pine Street Clinic will be reduced from 30 minutes to 20 minutes.
            </li>
            <li>
              <em>Within nine months</em>, the patient satisfaction results for the cardiology service line will be at or above the 65th percentile.
            </li>
            <li>
              <em>By December</em>, the turnaround time for lab results on Unit 9100 will be reduced to less than 30 minutes on average over a three-month period.       
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.nextStep.bind(this, 0)}>Next</Button>
      </Modal.Footer>
    </Modal>

    var what = 
    <Modal show={this.state.activityPopups[1]} onHide={this.exit.bind(this)}>
      <Modal.Header closeButton>
        <Modal.Title className="green-header">What</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-text">
          What is the problem?
        </p>
        <h4 className="green-header">Please type below</h4>
        <FormControl autoFocus={true} onKeyPress={this.handleKeyPress.bind(this, 1)} className="activity-input" bsSize="large" type="text" onChange={this.handleChange.bind(this, 1)} value={this.state.values[1]} placeholder="Insert What"/>
        <br />
        Full Aim Statement:
        <p className="modal-text">{aimStatementText}</p>
        <br />        
        Examples:
        <p className="modal-text">
          <ul className="modal-examples">
            <li>
              In the next four months, <em>wait times as measured from patient check-in to the moment the physician walks into the exam room</em> at the Pine Street Clinic will be reduced from 30 minutes to 20 minutes.
            </li>
            <li>
              Within nine months, <em>the patient satisfaction results</em> for the cardiology service line will be at or above the 65th percentile.
            </li>
            <li>
              By December, <em>the turnaround time for lab results</em> on Unit 9100 will be reduced to less than 30 minutes on average over a three-month period.       
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="activity-back" onClick={this.backStep.bind(this, 1)}>Back</Button>
        <Button onClick={this.nextStep.bind(this, 1)}>Next</Button>
      </Modal.Footer>
    </Modal>

    var where = 
    <Modal show={this.state.activityPopups[2]} onHide={this.exit.bind(this)}>
      <Modal.Header closeButton>
        <Modal.Title className="green-header">Where</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-text">
          Where is the improvement going to be seen?
        </p>
        <h4 className="green-header">Please type below</h4>
        <FormControl autoFocus={true} onKeyPress={this.handleKeyPress.bind(this, 2)} className="activity-input" bsSize="large" type="text" onChange={this.handleChange.bind(this, 2)} value={this.state.values[2]} placeholder="Insert Where"/>
        <br />
        Full Aim Statement:
        <p className="modal-text">{aimStatementText}</p>
        <br />  
        Examples:
        <p className="modal-text">
          <ul className="modal-examples">
            <li>
              In the next four months, wait times as measured from patient check-in to the moment the physician walks into the exam room <em>at the Pine Street Clinic</em> will be reduced from 30 minutes to 20 minutes.
            </li>
            <li>
              Within nine months, the patient satisfaction results <em>for the cardiology service line</em> will be at or above the 65th percentile.
            </li>
            <li>
              By December, the turnaround time for lab results <em>on Unit 9100</em> will be reduced to less than 30 minutes on average over a three-month period.       
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="activity-back" onClick={this.backStep.bind(this, 2)}>Back</Button>
        <Button onClick={this.nextStep.bind(this, 2)}>Next</Button>
      </Modal.Footer>
    </Modal>

    var how_much = 
    <Modal show={this.state.activityPopups[3]} onHide={this.exit.bind(this)}>
      <Modal.Header closeButton>
        <Modal.Title className="green-header">How Much?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-text">
          How much will the problem be improved?
        </p>
        <h4 className="green-header">Please type below</h4>
        <FormControl autoFocus={true} onKeyPress={this.handleKeyPress.bind(this, 3)} className="activity-input" bsSize="large" type="text" onChange={this.handleChange.bind(this, 3)} value={this.state.values[3]} placeholder="Insert How Much"/>
        <br />
        Full Aim Statement:
        <p className="modal-text">{aimStatementText}</p>
        <br />  
        Examples:
        <p className="modal-text">
          <ul className="modal-examples">
            <li>
              In the next four months, wait times as measured from patient check-in to the moment the physician walks into the exam room at the Pine Street Clinic <em>will be reduced from 30 minutes to 20 minutes</em>.
            </li>
            <li>
              Within nine months, the patient satisfaction results for the cardiology service line <em>will be at or above the 65th percentile</em>.
            </li>
            <li>
              By December, the turnaround time for lab results on Unit 9100 <em>will be reduced to less than 30 minutes on average over a three-month period</em>.       
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="activity-back" onClick={this.backStep.bind(this, 3)}>Back</Button>
        <Button onClick={this.close.bind(this, 3)}>Done</Button>
      </Modal.Footer>
    </Modal>

    var buttonDefault = "Start Aim Statement";
    var buttonText = buttonDefault;
    var buttonStart;
    for (i = 0; i < values.length; i++) {
      if(values[i] !== "") {
        continue;
      } else {
        buttonStart = i;
        break
      }
    }
    for (i = 0; i < values.length; i++) {
      if(values[i] !== "") {
        continue;
      } else {
        buttonStart = i;
        break
      }
    }
    var anyFilledIn = false;
    for (i = 0; i < values.length; i++) {
      if(values[i] !== "") { anyFilledIn = true; }
    }
    if (anyFilledIn) {
      buttonText = "Edit Aim Statement";
    }
    if (buttonStart === undefined) {
      buttonStart = 0;
    }

    var defaultText = [<p key={-1} className="problem-sentence-text">Your aim statement will appear here once the text fields are filled.</p>];
    const fullAimText = !anyFilledIn ?
      <div className="problem-sentence">
        {defaultText}
      </div>
      : 
      <div className="problem-sentence-filled">
        {aimSentenceText}
      </div>;
    return (
      <div className="page-shift">
          <Jumbotron className="jumbotron-dashboard">

            <div className="absolute-tooltip">
              <div className="hidden-xs">
                <OverlayTrigger placement="left" overlay={topToolTip} >
                  <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
                </OverlayTrigger>
              </div>
              <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
            </div>

            <div>
              <div className="information-title">
                <p id="activity-text">Aim Statement</p>
              </div>

            <div className="activity-start">
              <Button className="user-button" bsSize="large" onClick={this.openPopup.bind(this, buttonStart)}>
                {buttonText}
              </Button> 
            </div>

            {fullAimText}
          </div>

          {initialCheck}
          {what}
          {where}
          {when}
          {how_much}

          <Footer pageName="aim" setEdit={this.props.setEdit} routerKey={this.props.routerKey} history={this.props.history} />

        </Jumbotron>
      </div>
    )
  }
};

export default Aim;
