import React from 'react';
import {
  Jumbotron,
  Button,
  Row,
  Modal,
  Glyphicon,
  Alert,
  FormGroup,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import storageHelper from './../helpers/storageHelper.jsx';
import $ from 'jquery';
import Footer from '../global-components/Footer';
import ReactGA from './../../ga.js';

class BlueprintLoaded extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showCode: false,
      emails: [""],
      sentEmail: false,
    };
  }

  finish() { 
    ReactGA.event({
      category: 'Blueprint',
      action: 'Continue',
      label: 'Finished button pressed',
    });
    this.props.history.push("/dashboard");
  }

  addEmails() {
    ReactGA.event({
      category: 'Blueprint',
      action: 'Email',
      label: 'Email button pressed',
    });
    this.setState({ showCode: true });
  }

  close() {
    this.setState({ 
      showCode: false, 
      sentEmail: false,
    });
  }

  getPDF(action) {
    ReactGA.event({
      category: 'Blueprint',
      action: 'Continue',
      label: 'PDF saved',
    });
    let fileName = "BiteSizeQI Blueprint - " + storageHelper.getProjectCode();
    this.props.doc.save(fileName);
  }

  handleEmail(index, e) {
    var emailsCopy = this.state.emails.slice();
    emailsCopy[index] = e.target.value;
    this.setState({ emails: emailsCopy, sentEmail: false });
  }

  removeField(index, e) {
    var emailsCopy = this.state.emails.slice();
    emailsCopy.splice(index, 1);
    this.setState({ emails: emailsCopy });  
  }

  addField() {
    var emailsCopy = this.state.emails.slice();
    emailsCopy.push("");
    this.setState({ emails: emailsCopy });
  }

  sendEmail(projectCode) {
    if(this.state.sentEmail) { return; }
    ReactGA.event({
      category: 'Blueprint',
      action: 'Email',
      label: this.state.emails.length + ' emails sent',
    });
    let fileName = "BiteSizeQI_" + storageHelper.getProjectCode();
    const pdfString = this.props.doc.output('blob'); // returns raw body of resulting PDF returned as a string as per the plugin documentation
    var formData = new FormData();
    formData.append("file_name", fileName);
    formData.append("code", projectCode);
    formData.append("emails", this.state.emails);
    formData.append("data", pdfString);
    $.ajax({
      type: 'POST',
      url: 'https://php.pcforme.org/pdfsender.php',
      data: formData,
      dataType: 'json',
      contentType: false,
      processData: false,
    });  
    this.setState({ 
      sentEmail: true, 
      showCode: false, 
      emails: [""],
    });
  }

  viewBlueprint() {
    this.props.doc.output('datauri'); //opens the data uri in current window
  }

  render(){
    let projectCode = storageHelper.getProjectCode();

    var sentEmail = 
    <Modal show={this.state.sentEmail} onHide={this.close.bind(this)}>
      <Modal.Body>
        <div>
          <Alert bsStyle="success">
            Your email was sent!
          </Alert>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Continue</Button>
      </Modal.Footer>
    </Modal>;

    var codeModal =         
    <Modal show={this.state.showCode} onHide={this.close.bind(this)}>
      <Modal.Header>
        <Modal.Title className="green-header">Email your Project Blueprint</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Type in your email below to email your Project Blueprint to you and your team members.  Remember, your Project Blueprint contains your Project Code.  Save this code to return to your project at any time. </h5> 
        {this.state.sentEmail ? (
          <div>
            <Alert bsStyle="success">
              Sent Email!
            </Alert>
          </div>
        ) : null }
        {this.state.emails.map((value, i) => (
          <FormGroup key={i}>
            <InputGroup>
              <FormControl type="text" onChange={this.handleEmail.bind(this, i)} value={value} placeholder="Insert Email"/>
              <InputGroup.Button>
                <Button bsStyle="danger" onClick={this.removeField.bind(this, i)}><Glyphicon glyph="remove" /></Button>
              </InputGroup.Button>
            </InputGroup>
          </FormGroup>
        ))}
        <Button className="user-button" onClick={this.addField.bind(this)}>Add Email Address</Button>
        <Button className="user-button user-send" onClick={this.sendEmail.bind(this, projectCode)}>Send Email</Button>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.close.bind(this)}>Close</Button>
      </Modal.Footer>
    </Modal>;

    return (
      <div className="page-shift">
        <Jumbotron className="jumbotron-dashboard">

          <div>
            <div className="information-title">
              <img className="activity-image-left" src="/images/blueprint.png" alt="..." responsive />
              <p id="activity-text">Project Blueprint</p>
            </div>

            <div>
              <div className="blueprint-text">
                <span id="blueprint-text">Congratulations!<br/>{"Project Code - " + projectCode}</span>
              </div>
            </div>
            <div>
            </div>
            {this.props.loaded ? 
              <Row>
                <br />
                <Button
                  onClick={this.addEmails.bind(this)}
                  className="blueprint-button email-button"
                >
                  Click to <b>EMAIL</b> your blueprint
                </Button>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <Button
                  onClick={this.getPDF.bind(this)}
                  className="blueprint-button save-button"
                >
                  Click to <b>SAVE</b> your blueprint
                </Button>
              </Row> : null}

              <br />
            <p>
              <b>Thank you for using BiteSizeQI</b>
            </p>

            <Button
              onClick={this.finish.bind(this)}
              className="blueprint-button finished-button"
            >
              Click here once you are <b>FINISHED</b> 
            </Button>

            {codeModal}
            {sentEmail}

        </div>

          <div className="activity-footer-buttons">
            <Footer pageName="blueprint" setEdit={false} routerKey={this.props.routerKey} history={this.props.history} nextDisabled={true}/> 
          </div>

        </Jumbotron>
      </div>
    )
  }
}

export default BlueprintLoaded
