import React from 'react';
import {
  Button,
  Row,
  Col,
  Jumbotron
} from 'react-bootstrap';
import ReactGA from './../../ga.js';

const routing = [
  {welcomevideo: "Welcome to BiteSizeQI", healthcarevideo: "Introduction to Healthcare Quality", selectvideo: "Project Selection"},
  {blueprintvideo: "Project Blueprint", problemvideo: "Problem Statement", aimvideo: "Aim Statement"},
  {rostervideo: "Team Roster", nextvideo: "Next Steps", measurementvideo: "Measurement Plan"},
  {causevideo: "Cause and Effect", solutionsvideo: "Solutions and Prioritization", timelinevideo: "Timeline"},
];

class Videos extends React.Component {

  moveTo(page) {
    ReactGA.event({
      category: 'Videos',
      action: 'Check All Videos',
      label: 'Check Video: ' + page,
    });
    this.props.history.push('/' + page);
  }

  render() {
    var content = [];
    for (const section of routing) {  
      var currentSection = [];
      for (const link of Object.keys(section)) {
        const buttonText = section[link];
        currentSection.push(
          <Col xs={6} md={4}>
            <Button className="videos-button" bsSize="large" onClick={this.moveTo.bind(this, link)}>{buttonText}</Button>
          </Col>
        );
      }; 
      content.push(
        <div>
          <Row key={0} className="videos-grid">
            {currentSection}
          </Row>
        </div>
      );
    } 
    return (
      <div className="page-shift">
        <Jumbotron className="jumbotron-dashboard">
          {content}
        </Jumbotron>
      </div>
    )
  }
};


export default Videos;
