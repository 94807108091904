import areIntlLocalesSupported from 'intl-locales-supported';

const defaultResponse = "No response given".split();
let DateTimeFormat;
if (areIntlLocalesSupported(['fr', 'fa-IR'])) {
  DateTimeFormat = global.Intl.DateTimeFormat;
} 

function setInformation(snapshot) {
  var values = []
  let labels = ["Organization: ", "Name: "];
  let data = snapshot.val();
  if(!data) { return defaultResponse; }
  for(var i in labels) {
    values[i] = labels[i] + data[i];
  }
  return values;
}

function setSelection(snapshot) {
  var values = ["Project Title: "]; 
  let data = snapshot.child("2").val();
  if(!data) { return defaultResponse; }
  values[0] = values[0] + data;
  return values;
}

function setProblem(snapshot) {
  var values = [];
  snapshot.forEach((childSnapshot) => {
    values[childSnapshot.key] = childSnapshot.val();
  })
  var setProblem = (values.length !== 5) ? false : true;
  for(var i = 0; i<values.length; i++) {
    if(values[i] === "") {
      setProblem = false;
    } 
  }
  var problem = ["Please complete the Problem Statement Activity"];
  if (setProblem) {
    problem[0] = values[0] + ", " + values[1] + " " + values[2] + " " + values[3] + ". " + values[4] + ".";
  }
  return problem;
}

function setAim(snapshot) {
  var values = [];
  snapshot.forEach((childSnapshot) => {
    values[childSnapshot.key] = childSnapshot.val();
  })
  var setProblem = (values.length !== 4) ? false : true;
  for(var i = 0; i<values.length; i++) {
    if(values[i] === "") {
      setProblem = false;
    } 
  }
  var problem = ["Please complete the Aim Statement Activity"];
  if (setProblem) {
    problem[0] = "By " + values[0] + ", " + values[1] + " at " + values[2] + " will " + values[3] + ".";
  }
  return problem;
}

function setRoster(snapshot) {
  var values = [];
  let allRoster = JSON.parse(snapshot.child("allRoles").val());
  if (allRoster === null) { return defaultResponse; }
  snapshot.child("team").forEach((childSnapshot) => {
    let index = childSnapshot.key;
    for(var key in childSnapshot.val()) {
      let role = allRoster[key] === "0" ? "No Role Given": allRoster[key];
      let name = childSnapshot.val()[key] === "" ? "No Name Given": childSnapshot.val()[key];
      if (role === "QI Team") { role = "QI Team Member"; }
      values[index] = name + " is a " + role;
    }
  })
  return values;
}

function setMeasurement(snapshot) {
  var values = [];
  let descriptions = ["\"What\" Info: ", "Who will collect it: ", "What time period: ", 
  "How will the data be collected: ", "How will the data be recorded: "];
  let keys = ["what", "how/0", "how/1", "how/2", "how/3"];
  let defaultValue = "No Response Given";
  for(var i = 0; i<keys.length; i++) {
    let currentVal = snapshot.child(keys[i]).val();
    let response = (currentVal === "" || currentVal === null) ? defaultValue: snapshot.child(keys[i]).val();
    values[i] = descriptions[i] + response;
  }
  return values;
}

function setCause(snapshot) {
  var values = [];
  let mainValues = JSON.parse(snapshot.child("main").val());
  let subValues = JSON.parse(snapshot.child("subValues").val());
  let checkValues = JSON.parse(snapshot.child("checkValues").val());
  if(mainValues === null) { values.push(defaultResponse); return values; }
  for(var i = 0; i<mainValues.length; i++) {
    let first = mainValues[i] === "" ? "No Cause Given  ": mainValues[i] + " leads to ";
    var second = ""
    for(var j in subValues[i]) {
      var effect = subValues[i][j];
      if (effect === "") { continue; }
      if(checkValues[i][j]) {
        effect = "(Starred) " + effect;
      }
      second = second + effect + ", ";
    }
    if (second === "") { second = " No Effects  "; }
    values[i] = (first + second).slice(0, -2);
  }
  return values;
}

function setSolutions(snapshot) {
  var allCauses = JSON.parse(snapshot.child("allCauses").val());
  if(!allCauses) { return defaultResponse; }
  var values = [];
  snapshot.child("allSolutions").forEach((childSnapshot) => {
    var dataMap = childSnapshot.val();
    let potential = dataMap["potential"];
    if(!dataMap) { return; }
    if (potential === "") { 
      values.push("No Solution Given"); 
      return;
    }
    let cause = allCauses[dataMap["causes"]] === "Select a Cause" ? "No Cause Give" : allCauses[dataMap["causes"]];
    let likelihood = dataMap["likelihood"] === "0" ? 6 : dataMap["likelihood"]; 
    let difficulty = dataMap["difficulty"] === "0" ? 6 : dataMap["difficulty"];
    let extra = dataMap["prioritizeValues"] ? "(Priority) " : "";
    values.push(extra + potential + " will solve issue caused by " + cause + 
      ". This solution has " + (6-likelihood) + " likelihood rating and " 
      + (6-difficulty) + " difficulty rating.");
  });
  return values;
} 

function setPrioritization(snapshot) {
  let recommended = JSON.parse(snapshot.child("recommended").val());
  let prev = JSON.parse(snapshot.child("prevSolutions").val());
  var prevNames = [];
  if(prev === null || prev.length === 0) { return defaultResponse; }
  for(var i in prev) {
    let aSolution = prev[i];
    prevNames.push(aSolution["text"]);
  }
  let prevCopy = prevNames.slice();
  if(recommended === null || recommended.length === 0) { return defaultResponse; }
  let recCopy = recommended.slice();
  if(prevCopy.sort().join(',') === recCopy.sort().join(',')) {
    return prevNames;
  } else {
    return recommended;
  }
}

function setTimeline(snapshot) {
  var values = []
  var allRoster = JSON.parse(snapshot.child("allRoster").val());
  if (!allRoster) { return defaultResponse; }
  snapshot.child("allEvents").forEach((childSnapshot) => {
    let index = childSnapshot.key; 
    if (index >= 10) { return; }
    var dataMap = childSnapshot.val();
    values.push(readDataMap(dataMap, allRoster));
  })
  let addedEvents = JSON.parse(snapshot.child("extraFields").val());
  if(addedEvents === null) { addedEvents = []; }
  for(var i in addedEvents) {
    let index = addedEvents[i]*2;
    let indices = [index + 10, index + 11];
    for(var j in indices) {
      let current = indices[j];
      let child = snapshot.child("allEvents/" + current);
      var dataMap = child.val();
      values.push(readDataMap(dataMap, allRoster));
    }
  }
  return values;
}

function readDataMap(dataMap, allRoster) {
  let event = dataMap["events"];
  if (!event) { return "No Event Given"; }
  var date = new Date(JSON.parse(dataMap["dates"]));
  if(isNaN(date.getTime())) { date = "No Date Given"; } else {
    let formatter = new DateTimeFormat(
      'en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
    date = formatter.format(date);
  }
  let rosterIndex = dataMap["roster"];
  var asignee = allRoster[rosterIndex];
  if((rosterIndex === 0) || (rosterIndex >= allRoster.length)) {
    return (event + " is on " + date);
  } 
  return (event + " assigned to " + asignee + " for " + date);
}

export {
  setInformation,
  setSelection,
  setProblem,
  setAim,
  setRoster,
  setMeasurement,
  setCause,
  setSolutions,
  setPrioritization,
  setTimeline,
};
