import React from 'react';
import {
  Alert,
  OverlayTrigger,
  Button,
  Jumbotron,
  Grid,
  Row,
  Col,
  FormControl,
  InputGroup,
  FormGroup,
  Glyphicon,
  Tooltip,
  Modal,
  Image,
} from 'react-bootstrap';
import Footer from '../global-components/Footer';
import jsPDF from 'jspdf';

import CanvasJSReact from '../../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

let chartInstance;

class Chart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmChangeToUpload: false,
      confirmChangeToCreate: false,
      selected_index: null,
      charts: [],
      showLegend: true,
      editOrder: false,
      loaded: false,
    };
  }

  componentWillMount() {
    this.props.session.on('value', snapshot => {
      var val = snapshot.val();
      if (!val || val === "") {
        val = [];
      }
      this.setState({
        charts: val,
        loaded: true,
      });
    });
  }

  componentWillUnmount() {
    this.save();
  }

  addField() {
    const chartsCopy = this.state.charts.slice();
    chartsCopy.push({
      image: null,
      items: null,
      inBlueprint: true,
      creationStatus: null,

      name: "",
      xLabelType: null,
      yLabelType: null,
      interventionLabel: null,
      xLabel: null,
      yLabel: null,
      pre: [
        {
          x: 1,
          label: "",
        },
        {
          x: 2,
          label: "",
        },
        {
          x: 3,
          label: "",
        },
        {
          x: 4,
          label: "",
        },
        {
          x: 5,
          label: "",
        },
      ],
      post: [
        {
          x: 1,
          label: "",
        },
        {
          x: 2,
          label: "",
        },
        {
          x: 3,
          label: "",
        },
        {
          x: 4,
          label: "",
        },
        {
          x: 5,
          label: "",
        },
      ],
      notes: "",
    });
    const index = chartsCopy.length - 1;
    this.props.session.update({ ["/" + index]: chartsCopy[index] });
    this.setState({ selected_index: index });
  }

  createDiagram() {
    const chartsCopy = this.state.charts.slice();
    const index = this.state.selected_index;
    chartsCopy[index].creationStatus = "create";
    this.setState({
      confirmChangeToUpload: false,
      confirmChangeToCreate: false,
    });
    this.props.session.update({ ["/" + index + "/creationStatus"]: "create" });
  }

  uploadImage() {
    const chartsCopy = this.state.charts.slice();
    const index = this.state.selected_index;
    chartsCopy[index].creationStatus = "upload";
    this.setState({
      confirmChangeToUpload: false,
      confirmChangeToCreate: false,
    });
    this.props.session.update({ ["/" + index + "/creationStatus"]: "upload" });
  }

  exportPNG() {
    this.save();
    const selected_index = this.state.selected_index;
    const charts = this.state.charts;
    chartInstance.exportChart({
      format: "png",
      fileName: charts[selected_index].name,
    });
  }

  exportPDF() {
    const image = this.save();
    const doc = new jsPDF('portrait', 'mm', 'a4', true);
    const selected_index = this.state.selected_index;
    const charts = this.state.charts;
    const $ = require('jquery');

    const img = new Image();
    img.src = image;

    const fullWidth = doc.internal.pageSize.width;
    const IMGLeft = 16;
    const x = IMGLeft;
    const y = 40;
    doc.text(charts[selected_index].name, x, 34);
    $(img).ready(() => {
      const maxHeight = 100;
      const maxWidth = fullWidth - 2 * IMGLeft;

      const w = img.width ? img.width : 1;
      const h = img.height ? img.height : 1;
      const ratio = maxHeight / h;

      var width = w * ratio;
      var height = maxHeight;
      const widthRatio = width / maxWidth;
      if (widthRatio > 1) {
        width = maxWidth;
        height = height / widthRatio;
      }
      doc.addImage(image, 'png', x, y, width, height);
      doc.save(charts[selected_index].name);
    });
  }

  save() {
    const selected_index = this.state.selected_index;
    const charts = this.state.charts;
    if (
      selected_index !== null
      && charts[selected_index].creationStatus === "create"
    ) {
      const image = chartInstance.exportChart({
        format: "png",
        toDataURL: true,
      });
      this.props.session.update({ ["/" + selected_index + "/image"]: image });
      return image;
    }
  }

  cancelChangeToUpload() {
    this.setState({
      confirmChangeToUpload: false,
    });
  }

  cancelChangeToCreate() {
    this.setState({
      confirmChangeToCreate: false,
    });
  }

  changeToUpload() {
    this.setState({
      confirmChangeToUpload: true,
    });
  }

  saveAndDashboard() {
    this.save();
    this.props.history.push("/dashboard");
  }

  changeToCreate() {
    this.setState({
      confirmChangeToCreate: true,
    });
  }

  onFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const index = this.state.selected_index;
    const customUpdate = (newImage) => {
      this.props.session.update({ ["/" + index + "/image"]: newImage });
    };
    reader.onload = function () {
      customUpdate(reader.result);
    };
    reader.readAsDataURL(file);
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  editTitle(e) {
    const chartName = e.target.value;
    const index = this.state.selected_index;
    this.props.session.update({ ["/" + index + "/name"]: chartName });
  }

  deleteChart(input_index = null) {
    const chartsCopy = this.state.charts.slice();
    const index = Number.isInteger(input_index) ? input_index : this.state.selected_index;
    chartsCopy.splice(index, 1);
    this.setState({
      selected_index: null,
    });
    this.props.session.set(chartsCopy);
  }

  handleText(type, i, key, e) {
    const text = e.target.value;
    const index = this.state.selected_index;
    this.props.session.update({ ["/" + index + "/" + type + "/" + i + "/" + key]: text });
  }

  handleXLabel(e) {
    const label = e.target.value;
    const index = this.state.selected_index;
    this.props.session.update({ ["/" + index + "/xLabel"]: label });
  }

  handleInterventionLabel(e) {
    const label = e.target.value;
    const index = this.state.selected_index;
    this.props.session.update({ ["/" + index + "/interventionLabel"]: label });
  }

  handleYLabel(e) {
    const label = e.target.value;
    const index = this.state.selected_index;
    this.props.session.update({ ["/" + index + "/yLabel"]: label });
  }

  editNotes(e) {
    const notes = e.target.value;
    const index = this.state.selected_index;
    this.props.session.update({ ["/" + index + "/notes"]: notes });
  }

  selectChart(i) {
    this.setState({
      selected_index: i,
    });
  }

  addEvent(type) {
    const i = this.state.selected_index;
    const chart = this.state.charts[i];
    const arrCopy = chart[type].slice();
    arrCopy.splice(Math.max(arrCopy.length - 1, 0), 0, {
      type: "event",
      y: "",
      value: "",
    });
    const key = "/" + i + "/" + type;
    this.props.session.update({ [key]: this.formatX(arrCopy) });
  }

  handleToggle(i) {
    const previous = this.state.charts[i].inBlueprint;
    this.props.session.update({ ["/" + i + "/inBlueprint"]: !previous });
  }

  viewAll() {
    this.save();
    this.setState({
      selected_index: null,
    });
  }

  formatX(data) {
    var count = 0;
    for (var i = 0; i < data.length; i++) {
      const valObj = data[i];
      if (valObj.type === "event") { continue; }
      count = count + 1;
      data[i].x = count;
    }
    return data;
  }

  removeValue(type, index) {
    const i = this.state.selected_index;
    const chart = this.state.charts[i];
    var arrCopy = type === "pre" ? chart.pre.slice() : chart.post.slice();
    arrCopy.splice(index, 1);
    const key = "/" + i + "/" + type;
    this.props.session.update({ [key]: this.formatX(arrCopy) });
  }

  moveValue(type, index, change) {
    const i = this.state.selected_index;
    const chart = this.state.charts[i];
    var arrCopy = type === "pre" ? chart.pre.slice() : chart.post.slice();
    const to = index + change;
    if (to === arrCopy.length - 1 || to < 0) { return; }
    arrCopy.splice(to, 0, arrCopy.splice(index, 1)[0]);
    const key = "/" + i + "/" + type;
    this.props.session.update({ [key]: this.formatX(arrCopy) });
  }

  addValue(type) {
    const i = this.state.selected_index;
    const chart = this.state.charts[i];
    const arrCopy = chart[type].slice();
    arrCopy.push({
      x: 1,
      label: "",
      // label: "Observation " + (arrCopy.length + 1),
    });
    const key = "/" + i + "/" + type;
    this.props.session.update({ [key]: this.formatX(arrCopy) });
  }

  formatData(data, type) {
    const values = [
      <Row>
        <Col xs={10} md={7}>
          Data Label
        </Col>
        <Col xs={16} md={5}>
          Observation
        </Col>
      </Row>
    ];
    const editOrder = this.state.editOrder;
    for (var i = 0; i < (data.length - 1); i++) {
      const valObj = data[i];
      let inputFormControl;
      let label;
      if (valObj.type === "event") {
        inputFormControl = <FormControl bsSize="large" type="text" onChange={this.handleText.bind(this, type, i, "value")} value={valObj.value} placeholder="Type here" />;
        label = "Event:";
      } else {
        inputFormControl = <FormControl bsSize="large" type="text" onChange={this.handleText.bind(this, type, i, "y")} value={valObj.y ? valObj.y : ""} placeholder="Type here" />;
        label = valObj.label;
      }
      values.push(<div>
        <Col xs={10} md={7}>
          <div className="chart-extra">
            <FormGroup>
              <InputGroup>
                <InputGroup.Button>
                  <Button bsStyle="danger" onClick={this.removeValue.bind(this, type, i)} disabled={false}><Glyphicon glyph="remove" /></Button>
                </InputGroup.Button>
                <FormControl bsSize="large" type="text" onChange={this.handleText.bind(this, type, i, "label")} value={label} placeholder="Type here" />
              </InputGroup>
            </FormGroup>
          </div>
        </Col>
        <Col xs={16} md={5}>
          {editOrder ?
            <div>
              <FormGroup>
                <InputGroup>
                  {inputFormControl}
                  <InputGroup.Button>
                    <Button bsStyle="primary" onClick={this.moveValue.bind(this, type, i, -1)} disabled={false}><Glyphicon glyph="arrow-up" /></Button>
                  </InputGroup.Button>
                  <InputGroup.Button>
                    <Button bsStyle="primary" onClick={this.moveValue.bind(this, type, i, 1)} disabled={false}><Glyphicon glyph="arrow-down" /></Button>
                  </InputGroup.Button>
                </InputGroup>
              </FormGroup>
            </div> :
            <div>
              {inputFormControl}
            </div>
          }
        </Col>
      </div>);
    }
    i = data.length - 1;
    var valObj = data[i];
    const label = valObj.label;
    values.push(<div>
      <Col xs={10} md={7}>
        <div className="chart-extra">
          <FormControl bsSize="large" type="text" onChange={this.handleText.bind(this, type, i, "label")} value={label} placeholder="Type here" />
        </div>
      </Col>
      <Col xs={16} md={5}>
        <div>
          <FormGroup>
            <InputGroup>
              <FormControl className="selection-input" bsSize="large" type="text" onChange={this.handleText.bind(this, type, i, "y")} value={valObj.y} placeholder="Type here" />
              <InputGroup.Button>
                <Button bsStyle="success" onClick={this.addValue.bind(this, type, i, -1)} disabled={false}><Glyphicon glyph="ok" /></Button>
              </InputGroup.Button>
            </InputGroup>
          </FormGroup>
        </div>
      </Col>
    </div>);
    return values;
  }

  getPercentile(data, percentile) {
    var index = (percentile / 100) * data.length;
    var result;
    if (Math.floor(index) === index) {
      result = (data[(index - 1)].y + data[index].y) / 2;
    }
    else {
      result = data[Math.floor(index)].y;
    }
    return result;
  }

  getAddedChartingLines(raw_pre, raw_post) {
    const numberMapper = e => {
      e.y = Number(e.y)
      return e;
    };
    const pre = raw_pre.map(numberMapper);

    const post = raw_post.map(e => {
      e.x = e.x + pre.length;
      return e;
    }).map(numberMapper);

    var _lineChartUpdates = [];
    var _stripLines = [];
    var _lineChartAdditions = [];
    for (const dataObj of [this.runCheck(pre), this.runCheck(post)]) {
      const {
        lineChartUpdates,
        stripLines,
        lineChartAdditions,
      } = dataObj;
      _lineChartUpdates = lineChartUpdates ? _lineChartUpdates.concat(lineChartUpdates) : _lineChartUpdates;
      _stripLines = stripLines ? _stripLines.concat(stripLines) : _stripLines;
      _lineChartAdditions = lineChartAdditions ? _lineChartAdditions.concat(lineChartAdditions) : _lineChartAdditions;
    }
    return {
      lineChartUpdates: _lineChartUpdates,
      lineChartAdditions: _lineChartAdditions.concat(_stripLines),
    };
  }

  runCheck(data) {
    if (data.length < 10) { return {}; }
    const sorted = data.slice().sort((a, b) => a.y - b.y);
    const q1 = this.getPercentile(sorted, 25);
    const median = this.getPercentile(sorted, 50);
    const q3 = this.getPercentile(sorted, 75);
    const defaultStripLines = [
      // {    
      //   type: "line",            
      //   color: "#d8d8d8",
      //   lineDashType: "dash",
      //   markerType: "none",
      //   thickness: 3,
      //   lineThickness: 3.5,
      //   labelFontColor : "black", 
      //   dataPoints: [
      //     {
      //       x: data[0].x,
      //       y: q1,
      //       // indexLabel: "Q1",
      //     },
      //     {
      //       x: data[data.length-1].x,
      //       y: q1,
      //     },
      //   ],
      // },
      // {                
      //   type: "line",            
      //   color: "#d8d8d8",
      //   lineDashType: "dash",
      //   thickness: 3,
      //   lineThickness: 3.5,
      //   labelFontColor: "black", 
      //   dataPoints: [
      //     {
      //       x: data[0].x,
      //       y: q3,
      //       // indexLabel: "Q3",
      //     },
      //     {
      //       x: data[data.length-1].x,
      //       y: q3,
      //     },
      //   ],
      // },

      {
        type: "rangeSplineArea",
        fillOpacity: 0.05,
        lineThickness: 0,
        dataPoints: [
          {
            x: data[0].x,
            y: [q1, q3],
          },
          {
            x: data[data.length - 1].x,
            y: [q1, q3],
          }
        ],
      },
      {
        type: "line",
        color: "gray",
        labelFontColor: "black",
        lineThickness: 2.5,
        dataPoints: [
          {
            x: data[0].x,
            y: q1,
          },
          {
            x: data[data.length - 1].x,
            y: q1,
          },
        ],
      },
      {
        type: "line",
        color: "gray",
        labelFontColor: "black",
        lineThickness: 2.5,
        dataPoints: [
          {
            x: data[0].x,
            y: q3,
          },
          {
            x: data[data.length - 1].x,
            y: q3,
          },
        ],
      },

      {
        type: "rangeSplineArea",
        fillOpacity: 0.15,
        color: "gray",
        lineThickness: 3.5,
        dataPoints: [
          {
            // indexLabel: "Outlier Zone",
            x: data[0].x,
            y: [q3, sorted[sorted.length - 1].y],
          },
          {
            x: data[data.length - 1].x,
            y: [q3, sorted[sorted.length - 1].y],
          }
        ],
      },
      {
        type: "rangeSplineArea",
        fillOpacity: 0.15,
        color: "gray",
        lineThickness: 3.5,
        dataPoints: [
          {
            x: data[0].x,
            y: [sorted[0].y, q1],
          },
          {
            // indexLabel: "Outlier Zone",
            x: data[data.length - 1].x,
            y: [sorted[0].y, q1],
          }
        ],
      },
      {
        type: "line",
        lineDashType: "dash",
        color: "black",
        thickness: 2,
        labelFontColor: "black",
        lineThickness: 2.5,
        dataPoints: [
          {
            x: data[0].x,
            y: median,
          },
          {
            x: data[data.length - 1].x,
            y: median,
            // indexLabel: "Median",
          },
        ],
      },
    ];
    const lineChartUpdates = {};
    const lineChartAdditions = [];

    // Run check
    var boolean_data = data.map(e => {
      return {
        x: e.x,
        y: e.y,
        is_below_median: e.y < median,
      };
    });
    var i = 0;
    var start_i = 0;
    while (i < boolean_data.length) {
      const current_below_median = boolean_data[i].is_below_median;
      start_i = i;
      while (i < boolean_data.length && current_below_median === boolean_data[i].is_below_median) {
        i = i + 1;
      }
      if ((i - start_i) >= 6) {
        const dataPoints = [];
        for (var j = start_i; j < i; j++) {
          const dataPoint = boolean_data[j];
          const {
            x,
            y,
          } = dataPoint;

          dataPoints.push(
            {
              x: x,
              y: y,
            }
          );

          const yToLabel = lineChartUpdates[x] ? lineChartUpdates[x] : {};
          const label = yToLabel[y] ? yToLabel[y] : [];
          label.push("Run");
          yToLabel[y] = label;
          lineChartUpdates[x] = yToLabel;
        }
        lineChartAdditions.push({
          type: "line",
          markerColor: "red",
          lineThickness: 5,
          dataPoints,
        });
      }
    }

    // // Alternating Points
    // boolean_data = data.map(e => {
    //   return {
    //     x: e.x,
    //     y: e.y,
    //     is_below_median: e.y < median,
    //   };
    // });
    // i = 0;
    // start_i = 0;
    // while (i < boolean_data.length) {
    //   var looking_below_median = boolean_data[i].is_below_median;
    //   start_i = i;
    //   while (i < boolean_data.length && looking_below_median === boolean_data[i].is_below_median) {
    //     looking_below_median = !looking_below_median;
    //     i = i + 1;
    //   }
    //   if ((i - start_i) >= 6) {
    //     const dataPoints = [];
    //     for (j = start_i; j < i; j++) {
    //       const dataPoint = boolean_data[j];
    //       const {
    //         x,
    //         y, 
    //       } = dataPoint;

    //       dataPoints.push(
    //         {
    //           x: x,
    //           y: y,
    //         }
    //       );

    //       const yToLabel = lineChartUpdates[x] ? lineChartUpdates[x] : {};
    //       const label = yToLabel[y] ? yToLabel[y] : [];
    //       label.push("Alternating");
    //       yToLabel[y] = label;
    //       lineChartUpdates[x] = yToLabel;
    //     }
    //     lineChartAdditions.push({
    //       type: "line",
    //       markerColor: "yellow",
    //       lineThickness: 5,
    //       dataPoints,
    //     });
    //   }
    // }

    // Trend check
    boolean_data = data.map((e, i, arr) => {
      return {
        x: e.x,
        y: e.y,
        is_less_than_previous: i === 0 ? false : e.y < arr[i - 1].y,
      };
    });
    i = 0;
    start_i = 0;
    while (i < boolean_data.length) {
      const current_below_median = boolean_data[i].is_less_than_previous;
      start_i = i;
      while (i < boolean_data.length && current_below_median === boolean_data[i].is_less_than_previous) {
        i = i + 1;
      }
      if ((i - start_i) >= 5) {
        const dataPoints = [];
        for (j = start_i; j < i; j++) {
          const dataPoint = boolean_data[j];
          const {
            x,
            y,
          } = dataPoint;

          dataPoints.push(
            {
              x: x,
              y: y,
            }
          );

          const yToLabel = lineChartUpdates[x] ? lineChartUpdates[x] : {};
          const label = yToLabel[y] ? yToLabel[y] : [];
          label.push("Trend");
          yToLabel[y] = label;
          lineChartUpdates[x] = yToLabel;
        }
        lineChartAdditions.push({
          type: "line",
          markerColor: "green",
          lineThickness: 5,
          dataPoints,
        });
      }
    }

    // POI check
    const iqr = q3 - q1;
    const highestY = 1.5 * iqr + q3;
    const lowestY = q1 - 1.5 * iqr;
    for (i = 0; i < data.length; i++) {
      const dataPoint = data[i];
      const {
        x,
        y,
      } = dataPoint;
      if (y < highestY && y > lowestY) { continue; }
      const yToLabel = lineChartUpdates[x] ? lineChartUpdates[x] : {};
      const label = yToLabel[y] ? yToLabel[y] : [];
      label.push("Point of Interest");
      yToLabel[y] = label;
      lineChartUpdates[x] = yToLabel;

      lineChartAdditions.push({
        type: "line",
        markerColor: "blue",
        dataPoints: [
          {
            x,
            y,
            markerType: "cross",
          },
        ],
      });
    }

    return {
      lineChartUpdates,
      stripLines: defaultStripLines,
      lineChartAdditions,
    };
  }

  flipOrder() {
    this.setState({
      editOrder: !this.state.editOrder,
    });
  }

  enableLegend() {
    this.setState({
      showLegend: true,
    });
  }

  disableLegend() {
    this.setState({
      showLegend: false,
    });
  }

  closeModal() {
    localStorage.setItem('shownChartModal', true);
    this.forceUpdate()
  }

  render() {
    const selected_index = this.state.selected_index;
    const charts = this.state.charts;
    const editOrder = this.state.editOrder;
    var showToolTip = false;

    var insideJumbotron;
    if (selected_index === null) {
      const confirmedFields = [];
      for (var i = 0; i < charts.length; i++) {
        const cur_chart = charts[i];
        const toggleText = (charts[i].inBlueprint ? "Remove chart from" : "Add chart to") + " the Blueprint";
        confirmedFields.push(
          <Row key={i} className="selection-grid">
            <Col xs={3} md={3}>
              <div className="timeline-left">
                <p className="timeline-text">{cur_chart.name ? cur_chart.name : "Unnamed Chart"}</p>
              </div>
            </Col>
            <Col xs={3} md={2}>
              <div className="mapping-right">
                <Button className="roster-button" onClick={this.selectChart.bind(this, i)}>Edit</Button>
              </div>
            </Col>
            <Col xs={3} md={2}>
              <div className="mapping-right">
                <Button className="roster-button" onClick={this.deleteChart.bind(this, i)}>Delete</Button>
              </div>
            </Col>
            <Col xs={3} md={2}>
              <div className="mapping-right">
                <Button className="roster-button" onClick={this.handleToggle.bind(this, i)}>{toggleText}</Button>
              </div>
            </Col>
          </Row>);
      }
      if (this.state.loaded && confirmedFields.length === 0) {
        this.addField();
      }
      insideJumbotron =
        <div>
          <div className="timeline-grid">
            <Grid>
              {confirmedFields}
            </Grid>
            <div align="center" className="solutions-added-btns">
              <Button style={{
                "height": "50px",
              }} className="gray-btn" onClick={this.addField.bind(this)}>Add A Chart</Button>
            </div>
          </div>
        </div>;
    } else {
      const creationStatus = charts[selected_index].creationStatus;
      if (creationStatus === "upload") {
        const changeToCreateModal =
          <Modal className="activity-modal" show={this.state.confirmChangeToCreate}>
            <Modal.Body className="code-input">
              Are you sure you want to create a chart?
            <br />
            You will have to upload another image if you change your mind.
            <br />
              <Button style={{
                "marginRight": "30px",
              }} bsSize="large" onClick={this.cancelChangeToCreate.bind(this)}>Cancel</Button>
              <Button bsSize="large" onClick={this.createDiagram.bind(this)}>Create chart</Button>
            </Modal.Body>
          </Modal>;

        const chartData = charts[selected_index];
        const {
          name,
        } = chartData;

        insideJumbotron =
          <div className="two-button-shift">
            <Grid>
              <Row className="selection-grid">
                <Col xs={8} md={4}><span id="selection-label">Title</span></Col>
                <Col xs={10} md={8}>
                  <FormControl className="selection-input" bsSize="large" type="text" onChange={this.editTitle.bind(this)} value={name} placeholder="Type here" />
                </Col>
              </Row>
            </Grid>
            <Button className="diagram-btn" onClick={this.changeToCreate.bind(this)}>Create chart</Button>
            <Button className="diagram-btn" onClick={this.deleteChart.bind(this)}>Delete chart</Button>
            <Button className="diagram-btn" onClick={this.viewAll.bind(this)}>View All charts</Button>
            <br />
            <br />
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <input className="diagram-input" type="file" onChange={(e) => this.onFileChange(e)} />
            </form>
            <br />
            <br />
            {charts[selected_index].image && charts[selected_index].image !== "" ?
              <div>
                <img
                  style={{
                    "max-width": "100%",
                    "max-height": "100%",
                    "height": "auto",
                    "width": "auto",
                  }}
                  alt="uploaded" src={charts[selected_index].image} />
              </div> : null}
            {changeToCreateModal}
          </div>;
      }
      else if (creationStatus === "create") {
        const showModal = !localStorage.getItem("shownChartModal");
        showToolTip = true;
        var topToolTip =
          <Tooltip id="topToolTip">
            1. To create a Run Chart, start by giving your Run Chart a name in the box at the top of the screen<br />
            2. Begin labeling the X-axis and Y-axis of your chart.<br />
            3. Start inputting your pre-intervention data.  Start by adding the label of each data point in the left column (ie. Day 1, January) and put your observation value in the right column.  Your Run Chart will start to display your inputs as you input this information.  If you need to add more points, click the green check mark.<br />
            4. Input a label for your intervention in the box provided.<br />
            5. Repeat Step 3 with your post-intervention data.<br />
            Reminder: If you need help in analyzing your Run Chart, BiteSizeQI provides a Legend that describes the various trends in your data.
          </Tooltip>;

        var instructionModal =
          <Modal show={showModal} onHide={this.closeModal.bind(this)}>
            <Modal.Header>
              <Modal.Title className="green-header">Creating A Run Chart</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ol>
                <li>To create a Run Chart, start by giving your Run Chart a name in the box at the top of the screen.</li>
                <li>Begin labeling the X-axis and Y-axis of your chart. </li>
                <li>Start inputting your pre-intervention data.  Start by adding the label of each data point in the left column (ie. Day 1, January) and put your observation value in the right column.  Your Run Chart will start to display your inputs as you input this information.  If you need to add more points, click the green check mark. </li>
                <li>Input a label for your intervention in the box provided. </li>
                <li>Repeat Step 3 with your post-intervention data. </li>
              </ol>
              <p style={{ "font-weight": "bold" }}>Reminder: If you need help in analyzing your Run Chart, BiteSizeQI provides a Legend that describes the various trends in your data.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button className="green-button" bsStyle="success" onClick={this.closeModal.bind(this)}>Continue</Button>
            </Modal.Footer>
          </Modal>


        const changeToUploadModal =
          <Modal className="activity-modal" show={this.state.confirmChangeToUpload}>
            <Modal.Body className="code-input">
              Are you sure you want to upload an image?
            <br />
            You will lose the diagram you've already created.
            <br />
              <Button style={{
                "marginRight": "30px",
              }} bsSize="large" onClick={this.cancelChangeToUpload.bind(this)}>Cancel</Button>
              <Button bsSize="large" onClick={this.uploadImage.bind(this)}>Upload Image</Button>
            </Modal.Body>
          </Modal>;

        const chartData = charts[selected_index];
        const pre = chartData.pre;
        const post = chartData.post;
        const acceptedPre = pre.filter((value, index, data) => value.type !== "event" && index !== data.length - 1);
        const acceptedPost = post.filter((value, index, data) => value.type !== "event" && index !== data.length - 1);
        const lineChartUpdates = null;

        const {
          // lineChartUpdates,
          // stripLines,
          lineChartAdditions,
        } = this.getAddedChartingLines(JSON.parse(JSON.stringify(acceptedPre)), JSON.parse(JSON.stringify(acceptedPost)));

        var lineChartData = [
          {
            color: "black",
            type: "line",
            dataPoints: acceptedPre.slice().filter(e => !isNaN(e.y)).map(valObj => {
              const dataPoint = {
                x: Number(valObj.x),
                label: valObj.label,
                y: Number(valObj.y),
              };
              if (!lineChartUpdates) { return dataPoint; }
              const xChanges = lineChartUpdates[dataPoint.x];
              if (xChanges) {
                const updates = xChanges[dataPoint.y];
                if (updates) {
                  dataPoint.indexLabel = updates.join(", ");
                  dataPoint.markerColor = "red";
                }
              }
              return dataPoint;
            }),
          },
          {
            color: "black",
            type: "line",
            dataPoints: acceptedPost.slice().filter(e => !isNaN(e.y)).map(valObj => {
              const dataPoint = {
                x: Number(valObj.x) + acceptedPre.length,
                label: valObj.label,
                y: Number(valObj.y),
              };
              if (!lineChartUpdates) { return dataPoint; }
              const xChanges = lineChartUpdates[dataPoint.x];
              if (xChanges) {
                const updates = xChanges[dataPoint.y];
                if (updates) {
                  dataPoint.indexLabel = updates.join(", ");
                  dataPoint.markerColor = "red";
                }
              }
              return dataPoint;
            }),
          },
        ];
        lineChartData = lineChartAdditions && lineChartAdditions.length > 0 ? lineChartData.concat(lineChartAdditions) : lineChartData;
        const {
          name,
          yLabel,
          xLabel,
        } = chartData;
        const options = {
          animationEnabled: true,
          theme: "light2",
          title: {
            text: name,
          },
          axisX: {
            title: xLabel,
            interval: 1,
            stripLines: [
              {
                value: acceptedPre.length + .5,
                color: "#d8d8d8",
                lineDashType: "dash",
                label: chartData.interventionLabel ? chartData.interventionLabel : "",
                thickness: 3,
                labelFontColor: "black",
              },
            ]
          },
          data: lineChartData,
          axisY: {
            title: yLabel,
            includeZero: false,
            // stripLines,
          },
        }
        const preValues = this.formatData(pre, "pre");
        const postValues = this.formatData(post, "post");

        const chart = <div className="two-button-shift">
          <br />
          <CanvasJSChart options={options}
            onRef={ref => chartInstance = ref}
          />
        </div>;

        insideJumbotron =
          <div>
            <div className="diagram-top">
              <Grid>
                <Row className="selection-grid">
                  <Col xs={8} md={4}><span id="selection-label">Title</span></Col>
                  <Col xs={10} md={8}>
                    <FormControl className="selection-input" bsSize="large" type="text" onChange={this.editTitle.bind(this)} value={name} placeholder="Type here" />
                  </Col>
                </Row>
              </Grid>
              <Button className="diagram-btn" onClick={this.deleteChart.bind(this)}>Delete Chart</Button>
              <Button className="diagram-btn" onClick={this.viewAll.bind(this)}>View All Charts</Button>
              <Button className="diagram-btn" onClick={this.exportPNG.bind(this)}>Export as png</Button>
              <Button className="diagram-btn" onClick={this.exportPDF.bind(this)}>Export as PDF</Button>
              <Button className="diagram-btn" onClick={this.changeToUpload.bind(this)}>Upload Image</Button>
              <Button className="diagram-btn" onClick={this.saveAndDashboard.bind(this)}>Save and go to dashboard</Button>
              <br />
            </div>
            <br />
            {changeToUploadModal}
            <div className="chart-wrapper">
              {chart}
            </div>
            <div>
              <Grid>
                <div>
                  {this.state.showLegend ?
                    <Alert onDismiss={this.disableLegend.bind(this)}>
                      <p>
                        Trends and runs will display after at least 10 data points are added
                    </p>
                      <p style={{ "font-size": "14px" }}>
                        <span style={{ color: "blue", "font-size": "14px" }}>A Point of Interest</span> - This is a significant outlier much higher or lower than expected
                    </p>
                      <p style={{ "font-size": "14px" }}>
                        <span style={{ color: "red", "font-size": "14px" }}>A Run</span> - six or more consecutive points are either all above or all below the median. A run shows the stability of the data.
                    </p>
                      <p style={{ "font-size": "14px" }}>
                        <span style={{ color: "green", "font-size": "14px" }}>A Trend</span> - five or more consecutive points all increasing or decreasing. A trend shows the consistency of the data.
                    </p>
                      {/* <p> */}
                      {/* <span style={{ color: "yellow" }}>Alternating Points</span> - when six or more consecutive points alternating up and down across the median. */}
                      {/* </p> */}
                      <p>
                        <Button onClick={this.disableLegend.bind(this)}>Hide Legend</Button>
                      </p>
                    </Alert> :
                    <Button onClick={this.enableLegend.bind(this)}>Show Legend</Button>
                  }
                </div>
                <br />
                <div>I.E. "Days" or "Sessions" or dates</div>
                <Row className="selection-grid">
                  <Col xs={8} md={4}><span id="selection-label">X Label</span></Col>
                  <Col xs={10} md={8}>
                    <FormControl className="selection-input" bsSize="large" type="text" onChange={this.handleXLabel.bind(this)} value={xLabel} placeholder="Type here" />
                  </Col>
                </Row>
                <br />
                <br />
                <Row className="selection-grid">
                  I.E. "# of Falls" or rates/percentages like "Percent Success"
              </Row>
                <Row className="selection-grid">
                  <Col xs={8} md={4}><span id="selection-label">Y Label</span></Col>
                  <Col xs={10} md={8}>
                    <FormControl className="selection-input" bsSize="large" type="text" onChange={this.handleYLabel.bind(this)} value={yLabel} placeholder="Type here" />
                  </Col>
                </Row>
                <hr className="chart-divider" />
                <br />
                <Row className="selection-grid">
                  <Col xs={17} md={8}></Col>
                  <Col xs={1} md={1}>
                    <div className="add-field">
                      <Button className="roster-button" onClick={this.flipOrder.bind(this)}>{editOrder ? "Done Editing" : "Edit Data Order"}</Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {editOrder ? "Click the up arrow to move the data point earlier in the data set" : ""}
                </Row>
                <Row className="selection-grid">
                  <Col xs={18} md={12}>
                    <div>Pre Intervention Data</div>
                    <Row className="selection-grid">
                      {preValues}
                    </Row>
                  </Col>
                </Row>
                <br />
                <hr className="chart-divider" />
                <br />
                <Row>
                  <Col xs={8} md={4}></Col>
                  <Col xs={10} md={8}>
                    I.E. "New lab process” or “Different check-in procedure"
                  </Col>
                </Row>
                <Row className="chart-intervention-example">
                  <Col xs={8} md={4}><span id="selection-label">Intervention Label</span></Col>
                  <Col xs={10} md={8}>
                    <FormControl className="selection-input" bsSize="large" type="text" onChange={this.handleInterventionLabel.bind(this)} value={chartData.interventionLabel} placeholder="Type here" />
                  </Col>
                </Row>
                <hr className="chart-divider" />
                <Row className="selection-grid">
                  <Col xs={18} md={12}>
                    <div>Post Intervention Data</div>
                    <Row className="selection-grid">
                      {postValues}
                    </Row>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  Add any notes here
              </Row>
                <Row className="selection-grid" style={{ width: "80%", margin: "auto" }}>
                  <textarea className="admin-text-area" value={chartData.notes} onChange={this.editNotes.bind(this)} />
                </Row>
              </Grid>
            </div>
          </div>;
      }
      else {
        insideJumbotron =
          <div className="two-button-shift">
            <br />
            <div className="inline intro-buttons">
              <Button className="gray-btn" bsSize="large" block onClick={this.createDiagram.bind(this)}><b>Create Diagram</b></Button>
              <br />
              <Button className="gray-btn next-block" bsSize="large" block onClick={this.uploadImage.bind(this)}><b>Upload Image</b></Button>
            </div>
          </div>;
      }
    }
    return (
      <div className="page-shift">
        {instructionModal}
        <Jumbotron className="jumbotron-dashboard">
          {showToolTip ?
            <div className="absolute-tooltip">
              <div className="hidden-xs">
                <OverlayTrigger placement="left" overlay={topToolTip} >
                  <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
                </OverlayTrigger>
              </div>
              <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
            </div> : null
          }
          <div className="information-title">
            <p id="activity-text">Run Charts</p>
          </div>
          {this.state.loaded ? insideJumbotron : "loading..."}
          <Footer pageName="chart" setEdit={false} routerKey={this.props.routerKey} history={this.props.history} />
        </Jumbotron>
      </div>
    )
  }
};

export default Chart;
