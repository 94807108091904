import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import { render } from 'react-dom'
import { HashRouter } from 'react-router-dom'
import App from './components/Main';

render((
    <HashRouter>
        <App />
    </HashRouter>
), document.getElementById('root'));
