export default {
	welcome: "//player.vimeo.com/video/257573180?autoplay=1&title=0&byline=0&portrait=0",
	healthcare: "//player.vimeo.com/video/257573216?autoplay=1&title=0&byline=0&portrait=0",
	select: "//player.vimeo.com/video/257573308?autoplay=0&title=0&byline=0&portrait=0",
	blueprint: "//player.vimeo.com/video/257573276?autoplay=1&title=0&byline=0&portrait=0",
	problem: "//player.vimeo.com/video/257573339?autoplay=0&title=0&byline=0&portrait=0",
	aim: "//player.vimeo.com/video/257573358?autoplay=0&title=0&byline=0&portrait=0",
	roster: "//player.vimeo.com/video/257573396?autoplay=0&title=0&byline=0&portrait=0",
	next: "//player.vimeo.com/video/257573418?autoplay=1&title=0&byline=0&portrait=0",
	measurement: "//player.vimeo.com/video/257573463?autoplay=0&title=0&byline=0&portrait=0",
	cause: "//player.vimeo.com/video/257573444?autoplay=0&title=0&byline=0&portrait=0",
	solutions: "//player.vimeo.com/video/257573503?autoplay=0&title=0&byline=0&portrait=0",
	timeline: "//player.vimeo.com/video/257573500?autoplay=0&title=0&byline=0&portrait=0",

	selectAuto: "//player.vimeo.com/video/257573308?autoplay=1&title=0&byline=0&portrait=0",
	problemAuto: "//player.vimeo.com/video/257573339?autoplay=1&title=0&byline=0&portrait=0",
	aimAuto: "//player.vimeo.com/video/257573358?autoplay=1&title=0&byline=0&portrait=0",
	rosterAuto: "//player.vimeo.com/video/257573396?autoplay=1&title=0&byline=0&portrait=0",
	measurementAuto: "//player.vimeo.com/video/257573463?autoplay=1&title=0&byline=0&portrait=0",
	causeAuto: "//player.vimeo.com/video/257573444?autoplay=1&title=0&byline=0&portrait=0",
	solutionsAuto: "//player.vimeo.com/video/257573503?autoplay=1&title=0&byline=0&portrait=0",
	timelineAuto: "//player.vimeo.com/video/257573500?autoplay=1&title=0&byline=0&portrait=0",

	chart: "//player.vimeo.com/video/418617396?autoplay=0&title=0&byline=0&portrait=0",
	chartAuto: "//player.vimeo.com/video/418617396?autoplay=1&title=0&byline=0&portrait=0",
	mapping: "//player.vimeo.com/video/418617193?autoplay=0&title=0&byline=0&portrait=0",
	mappingAuto: "//player.vimeo.com/video/418617193?autoplay=1&title=0&byline=0&portrait=0",
} 
