import React from 'react';
import {
  Button,
  OverlayTrigger,
  FormControl,
  Grid,
  Row,
  Col,
  Jumbotron,
  Glyphicon,
  Tooltip,
  Modal,
  Image,
} from 'react-bootstrap';
import {
  DiagramTools,
  PrintAndExport,
  Inject,
  DiagramComponent,
} from "@syncfusion/ej2-react-diagrams";
import Footer from '../global-components/Footer';
import jsPDF from 'jspdf';

let diagramInstance;

var count = 0;

const circle = {
  id: "circle",
  offsetX: 150,
  offsetY: 50,
  shape: { type: "Flow", shape: "Terminator" },
  style: {
    strokeColor: 'black',
    // fill: '#F5F5F5',
  },
  annotations: [
    {
      content: "Double Click here"
    }
  ]
};

const rectangle = {
  id: "rectangle",
  offsetX: 150,
  offsetY: 100,
  shape: { type: "Flow", shape: "Process" },
  style: {
    strokeColor: 'black',
    // fill: '#5C9E8F',
  },
  annotations: [
    {
      content: "Double Click here"
    }
  ]
};

const diamond = {
  id: "diamond",
  offsetX: 150,
  offsetY: 150,
  shape: { type: "Flow", shape: "Decision" },
  style: {
    strokeColor: 'black',
    // fill: '#F04D25',
  },
  annotations: [
    {
      content: "Double Click here",
    }
  ]
};

const connector = {
  id: 'decision',
  type: 'Straight',
  sourcePoint: { x: 100, y: 210 },
  targetPoint: { x: 200, y: 260 },
  style: {
    strokeColor: 'black',
    fill: 'black',
  },
  targetDecorator: {
    style: {
      fill: 'black',
      strokeColor: 'black'
    }
  },
};

const decision_connector = {
  id: 'decision',
  type: 'Straight',
  sourcePoint: { x: 100, y: 310 },
  targetPoint: { x: 200, y: 360 },
  style: {
    strokeColor: 'black',
    // strokeDashArray: "3,3",
    fill: 'black',
    strokeWidth: 2
  },
  targetDecorator: {
    style: {
      fill: 'black',
      strokeColor: 'black'
    }
  },
  annotations: [
    {
      content: "Double Click here",
    }
  ]
};

class Mapping extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmChangeToUpload: false,
      confirmChangeToCreate: false,
      selected_index: null,
      maps: [],
      loaded: false,
    };
  }

  componentWillMount() {
    this.props.session.on('value', snapshot => {
      var val = snapshot.val();
      if (!val || val === "") {
        val = [];
      }
      this.setState({
        maps: val,
        loaded: true,
      });
    });
  }

  componentDidMount() {
    setInterval(this.save.bind(this), 1500);
  }

  downloadDiagram() {
    const selected_index = this.state.selected_index;
    const maps = this.state.maps;
    if (!diagramInstance
      || diagramInstance.connectors.length > 0
      || diagramInstance.nodes.length > 0
      || selected_index === null
      || !maps[selected_index]
      || !maps[selected_index].items) { return; }
    const prevItems = JSON.parse(maps[selected_index].items);
    setTimeout(() => {
      if (!diagramInstance) { return; }
      for (const item of prevItems) {
        diagramInstance.add(item);
      }
    }, 50);
  }

  componentWillUnmount() {
    this.save();
  }

  createItem(i) {
    var item;
    if (i === 0) {
      item = circle;
    }
    else if (i === 1) {
      item = rectangle;
    }
    else if (i === 2) {
      item = diamond;
    }
    else if (i === 3) {
      item = connector;
    }
    else if (i === 4) {
      item = decision_connector;
    }
    item.id = item.id + count;
    if (item.offsetX) {
      item.offsetX = (item.offsetX + Math.random() * 10 + 5) % 600;
    } else {
      const random_offset = Math.random() * 10 + 5;
      item.sourcePoint.x = item.sourcePoint.x + random_offset;
      item.targetPoint.x = item.targetPoint.x + random_offset;
    }
    count = count + 1;
    diagramInstance.add(item);
  }

  createDiagram() {
    const mapsCopy = this.state.maps.slice();
    const index = this.state.selected_index;
    mapsCopy[index].creationStatus = "create";
    this.setState({
      confirmChangeToUpload: false,
      confirmChangeToCreate: false,
    });
    this.props.session.update({ ["/" + index + "/creationStatus"]: "create" });
  }

  uploadImage() {
    const mapsCopy = this.state.maps.slice();
    const index = this.state.selected_index;
    mapsCopy[index].creationStatus = "upload";
    this.setState({
      confirmChangeToUpload: false,
      confirmChangeToCreate: false,
    });
    this.props.session.update({ ["/" + index + "/creationStatus"]: "upload" });
  }

  exportPNG() {
    this.save();
    const selected_index = this.state.selected_index;
    const maps = this.state.maps;
    diagramInstance.exportDiagram({
      mode: "Download",
      format: "png",
      fileName: maps[selected_index].name,
      stretch: 'Stretch',
    });
  }

  exportPDF() {
    this.save();
    const doc = new jsPDF('portrait', 'mm', 'a4', true);
    const selected_index = this.state.selected_index;
    const maps = this.state.maps;
    const image = diagramInstance.exportDiagram({
      mode: "Data",
      format: "png",
      fileName: maps[selected_index].name,
      stretch: 'Stretch',
    });
    const $ = require('jquery');

    const img = new Image();
    img.src = image;

    const fullWidth = doc.internal.pageSize.width;
    const IMGLeft = 16;
    const x = IMGLeft;
    const y = 40;
    doc.text(maps[selected_index].name, x, 34);
    $(img).ready(() => {
      const maxHeight = 100;
      const maxWidth = fullWidth - 2 * IMGLeft;

      const w = img.width ? img.width : 1;
      const h = img.height ? img.height : 1;

      const ratio = maxHeight / h;

      var width = w * ratio;
      var height = maxHeight;
      const widthRatio = width / maxWidth;
      if (widthRatio > 1) {
        width = maxWidth;
        height = height / widthRatio;
      }
      doc.addImage(image, 'png', x, y, width, height);
      doc.save(maps[selected_index].name);
    });
  }

  save() {
    const selected_index = this.state.selected_index;
    const maps = this.state.maps;
    if (
      selected_index !== null
      && maps[selected_index].creationStatus === "create"
      && diagramInstance
      && (diagramInstance.connectors.length > 0 || diagramInstance.nodes.length > 0)) {
      const diagram = JSON.parse(diagramInstance.saveDiagram());
      const diagramItems = diagram.nodes.concat(diagram.connectors);
      const items = JSON.stringify(diagramItems);
      const image = diagramInstance.exportDiagram({
        mode: "Data",
        format: "png",
        fileName: maps[selected_index].name,
      });
      this.props.session.update({ ["/" + selected_index + "/image"]: image });
      this.props.session.update({ ["/" + selected_index + "/items"]: items });
    }
  }

  cancelChangeToUpload() {
    this.setState({
      confirmChangeToUpload: false,
    });
  }

  cancelChangeToCreate() {
    this.setState({
      confirmChangeToCreate: false,
    });
  }

  changeToUpload() {
    this.setState({
      confirmChangeToUpload: true,
    });
  }

  changeToCreate() {
    this.setState({
      confirmChangeToCreate: true,
    });
  }

  onFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const index = this.state.selected_index;
    const customUpdate = (newImage) => {
      this.props.session.update({ ["/" + index + "/image"]: newImage });
    };
    reader.onload = function () {
      customUpdate(reader.result);
    };
    reader.readAsDataURL(file);
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  editTitle(e) {
    const mapName = e.target.value;
    const mapsCopy = this.state.maps.slice();
    const index = this.state.selected_index;
    mapsCopy[index].name = mapName;
    this.props.session.update({ ["/" + index + "/name"]: mapName });
  }

  deleteMap(input_index = null) {
    const mapsCopy = this.state.maps.slice();
    const index = Number.isInteger(input_index) ? input_index : this.state.selected_index;
    mapsCopy.splice(index, 1);
    this.setState({
      selected_index: null,
    });
    this.props.session.set(mapsCopy);
  }

  viewAll() {
    this.setState({
      selected_index: null,
    });
  }

  selectMap(i) {
    this.setState({
      selected_index: i,
    });
    this.downloadDiagram();
  }

  addField() {
    const mapsCopy = this.state.maps.slice();
    mapsCopy.push({
      image: null,
      items: null,
      inBlueprint: true,
      creationStatus: null,
      name: "",
    });
    const index = mapsCopy.length - 1;
    this.props.session.update({ ["/" + index]: mapsCopy[index] });
    this.setState({ selected_index: index });
  }

  handleToggle(i) {
    const previous = this.state.maps[i].inBlueprint;
    this.props.session.update({ ["/" + i + "/inBlueprint"]: !previous });
  }

  closeModal() {
    localStorage.setItem('shownMappingModal', true);
    this.forceUpdate()
  }

  render() {
    const selected_index = this.state.selected_index;
    const maps = this.state.maps;
    var showToolTip = false;

    var insideJumbotron;
    if (selected_index === null) {
      const confirmedFields = [];
      for (var i = 0; i < maps.length; i++) {
        const cur_map = maps[i];
        const toggleText = (maps[i].inBlueprint ? "Remove map from " : "Add map to ") + " the Blueprint";
        confirmedFields.push(
          <Row key={i} className="selection-grid">
            <Col xs={3} md={3}>
              <div className="timeline-left">
                <p className="timeline-text">{cur_map.name ? cur_map.name : "Unnamed Map"}</p>
              </div>
            </Col>
            <Col xs={3} md={2}>
              <div className="mapping-right">
                <Button className="roster-button" onClick={this.selectMap.bind(this, i)}>Edit</Button>
              </div>
            </Col>
            <Col xs={3} md={2}>
              <div className="mapping-right">
                <Button className="roster-button" onClick={this.deleteMap.bind(this, i)}>Delete</Button>
              </div>
            </Col>
            <Col xs={3} md={2}>
              <div className="mapping-right">
                <Button className="roster-button" onClick={this.handleToggle.bind(this, i)}>{toggleText}</Button>
              </div>
            </Col>
          </Row>);
      }
      if (this.state.loaded && confirmedFields.length === 0) {
        this.addField();
      }
      insideJumbotron =
        <div>
          <div className="timeline-grid">
            <Grid>
              {confirmedFields}
            </Grid>
            <div align="center" className="solutions-added-btns">
              <Button style={{
                "height": "50px",
              }} className="gray-btn" onClick={this.addField.bind(this)}>Add A Map</Button>
            </div>
          </div>
        </div>;
    } else {
      const currentMap = maps[selected_index];
      const creationStatus = currentMap ? currentMap.creationStatus : null;
      if (creationStatus === "upload") {
        const changeToCreateModal =
          <Modal className="activity-modal" show={this.state.confirmChangeToCreate}>
            <Modal.Body className="code-input">
              Are you sure you want to create a map?
            <br />
            You will have to upload another image if you change your mind.
            <br />
              <Button style={{
                "marginRight": "30px",
              }} bsSize="large" onClick={this.cancelChangeToCreate.bind(this)}>Cancel</Button>
              <Button bsSize="large" onClick={this.createDiagram.bind(this)}>Create Map</Button>
            </Modal.Body>
          </Modal>;

        const mapName = selected_index !== null ? maps[selected_index].name : null;
        insideJumbotron =
          <div className="two-button-shift">
            <div className="mapping-title">
              <FormControl bsSize="large" type="text" onChange={this.editTitle.bind(this)} value={mapName} placeholder="Name Your Process Map" />
            </div>
            <br />
            <Button className="diagram-btn" onClick={this.changeToCreate.bind(this)}>Create Map</Button>
            <Button className="diagram-btn" onClick={this.deleteMap.bind(this)}>Delete Map</Button>
            <Button className="diagram-btn" onClick={this.viewAll.bind(this)}>View All Maps</Button>
            <br />
            <br />
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <input className="diagram-input" type="file" onChange={(e) => this.onFileChange(e)} />
            </form>
            <br />
            <br />
            {maps[selected_index].image && maps[selected_index].image !== "" ?
              <div>
                <img
                  style={{
                    "max-width": "100%",
                    "max-height": "100%",
                    "height": "auto",
                    "width": "auto",
                  }}
                  alt="uploaded" src={maps[selected_index].image} />
              </div> : null}
            {changeToCreateModal}
          </div>;
      }
      else if (creationStatus === "create") {
        const showModal = !localStorage.getItem("shownMappingModal");
        showToolTip = true;
        var topToolTip =
          <Tooltip id="topToolTip">
            1. To create a Process Map, click on one of the shapes listed in the menu above. <br />
            2. Once you chose the shapes you need, drag and drop them to create your map. <br />
            3. If you need to remove an image from your map, simply click on the image and delete using your keyboard.
          </Tooltip>;
        var instructionModal =
          <Modal show={showModal} onHide={this.closeModal.bind(this)}>
            <Modal.Header>
              <Modal.Title className="green-header">Creating A Process Map</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ol>
                <li>To create a Process Map, click on one of the shapes listed in the menu above.</li>
                <li>Once you chose the shapes you need, drag and drop them to create your map.</li>
                <li>If you need to remove an image from your map, simply click on the image and delete using your keyboard.</li>
              </ol>
            </Modal.Body>
            <Modal.Footer>
              <Button className="green-button" bsStyle="success" onClick={this.closeModal.bind(this)}>Continue</Button>
            </Modal.Footer>
          </Modal>

        const diagramItems = [];
        const shapes = ["Terminator", "Process", "Decision Diamond", "Process Connector", "Decision Connector"];
        const images = ["terminator", "process", "decision_diamond", "connector", "decision_connector"];
        for (i = 0; i < shapes.length; i++) {
          diagramItems.push(
            <div key={i} className="diagram-item" onClick={this.createItem.bind(this, i)}>
              {[shapes[i]]}
              <img className="diagram-image" src={"/images/" + images[i] + ".png"} alt="" />
            </div>
          );
        }

        const changeToUploadModal =
          <Modal className="activity-modal" show={this.state.confirmChangeToUpload}>
            <Modal.Body className="code-input">
              Are you sure you want to upload an image?
            <br />
            You will lose the diagram you've already created.
            <br />
              <Button style={{
                "marginRight": "30px",
              }} bsSize="large" onClick={this.cancelChangeToUpload.bind(this)}>Cancel</Button>
              <Button bsSize="large" onClick={this.uploadImage.bind(this)}>Upload Image</Button>
            </Modal.Body>
          </Modal>;
        const mapName = selected_index !== null ? maps[selected_index].name : null;
        insideJumbotron =
          <div className="mapping-diagram">
            <div className="diagram-top">
              <div className="mapping-title">
                <FormControl bsSize="large" type="text" onChange={this.editTitle.bind(this)} value={mapName} placeholder="Name Your Process Map" />
              </div>
              <br />
              <Button className="diagram-btn" onClick={this.deleteMap.bind(this)}>Delete Map</Button>
              <Button className="diagram-btn" onClick={this.viewAll.bind(this)}>View All Maps</Button>
              <Button className="diagram-btn" onClick={this.exportPNG.bind(this)}>Export as png</Button>
              <Button className="diagram-btn" onClick={this.exportPDF.bind(this)}>Export as PDF</Button>
              <br />
              <br />
            </div>
            <p style={{ "font-size": "22px" }}>Click on the shapes below to populate your map</p>
            <div className="diagram-items">
              <Button className="diagram-btn" style={{ "margin-top": "30px" }} onClick={this.changeToUpload.bind(this)}>Upload Image</Button>
              {diagramItems}
              <Button className="diagram-btn" style={{ "margin-top": "30px" }} onClick={this.save.bind(this)}>Save Changes</Button>
            </div>
            {changeToUploadModal}
            <div className="diagram-wrapper">
              <DiagramComponent
                id="diagram"
                ref={diagram => {
                  diagramInstance = diagram;
                  this.downloadDiagram();
                }}
                tool={DiagramTools.DrawOnce}
                enableCanvasRendering={true}
              >
                <Inject services={[PrintAndExport]} />
              </DiagramComponent>
            </div>
          </div>;
      }
      else {
        insideJumbotron =
          <div className="two-button-shift">
            <br />
            <div className="inline intro-buttons">
              <Button className="gray-btn" bsSize="large" block onClick={this.createDiagram.bind(this)}><b>Create Diagram</b></Button>
              <br />
              <Button className="gray-btn next-block" bsSize="large" block onClick={this.uploadImage.bind(this)}><b>Upload Image</b></Button>
            </div>
          </div>;
      }
    }
    return (
      <div className="page-shift">
        {instructionModal}
        <Jumbotron className="jumbotron-dashboard">
          {showToolTip ?
            <div className="absolute-tooltip">
              <div className="hidden-xs">
                <OverlayTrigger placement="left" overlay={topToolTip} >
                  <Button bsSize="large" bsStyle="link"><Glyphicon glyph="question-sign" />{' '}</Button>
                </OverlayTrigger>
              </div>
              <Button bsSize="large" bsStyle="link" className="hidden-sm hidden-md hidden-lg"><Glyphicon glyph="question-sign" />{' '}</Button>
            </div> : null
          }
          <div className="information-title">
            <p id="activity-text">Process Maps</p>
          </div>
          {this.state.loaded ? insideJumbotron : "loading..."}
          <Footer pageName="mapping" setEdit={false} routerKey={this.props.routerKey} history={this.props.history} />
        </Jumbotron>
      </div>
    )
  }
};

export default Mapping;
