import React from 'react';
import { withRouter } from 'react-router';
import Icon from '@material-ui/core/Icon';
import storageHelper from './../helpers/storageHelper.jsx'
import { amber } from '@material-ui/core/colors';

const PROGRESS_SIDEBAR_COUNT = 10;

const sidebarText = [
  { name: "Welcome", link: "welcomevideo" },
  { name: "Get Started", link: "information", index: "0" },
  { name: "Project Information", link: "selection", index: "1" },
  { name: "Problem Statement", link: "problem", index: "2" },
  { name: "Aim Statement", link: "aim", index: "3" },
  { name: "Roster", link: "roster", index: "4" },
  { name: "Measurement", link: "measurement", index: "5" },
  { name: "Cause and Effect", link: "cause", index: "6" },
  { name: "Solutions", link: "solutions", index: "7" },
  { name: "Timeline", link: "timeline", index: "8" },
  { name: "Videos", link: "videos", styling: "movie", index: "0" },
  { name: "Dashboard", link: "dashboard", styling: "dashboard", index: "0" },
  { name: "Advanced Tools", link: "advancedtools", styling: "build", index: "0" },
];

const divideAt = ["Roster", "Cause and Effect"];

const styling = {
  dashboard: {
    fontSize: '38px',
    color: amber[400],
  }, 
  movie: {
    fontSize: '24px'
  },
  circle: {
    fontSize: '24px'
  },
  build: {
    fontSize: '24px'
  },
};

const styles = {
  sidebar: {
    height: '100%',
  },
  sidebarLink: {
    display: 'block',
    color: '#F5F5F5',
    textDecoration: 'none',
  },
  content: {
    padding: "1px",
    height: '100%',
    backgroundColor: '#343D45',
  },
};

class ProgressBar extends React.Component {

  expand() {
    this.props.setProgressExpansion(true);
  }

  contract() {
    this.props.setProgressExpansion(false);
  }

  checkDashboard() {
    var fullLink = window.location.href.split("/");
    var currentPage = fullLink[fullLink.length-1];
    storageHelper.setDashboardBack(currentPage);
  }

  render() {
    const expanded = this.props.expanded;
    const activityStatus = this.props.activityStatus;

    var sizeButton;

    if (expanded) {
      sizeButton = 
      <div key={-2} className="sidebar-contract" onClick={this.contract.bind(this)}>
        <Icon style={styling.circle} className="contract-symbol">keyboard_arrow_left</Icon>
      </div>;
    } else {
      sizeButton = 
      <div key={-2} className="sidebar-expand" onClick={this.expand.bind(this)}>
        <Icon style={styling.circle} className="expand-symbol">keyboard_arrow_right</Icon>
      </div>;
    }   

    var links = [sizeButton];

    for (let ind = 0; ind < PROGRESS_SIDEBAR_COUNT; ind++) {
      var sidebarLineClass = "sidebar-line";
      var sidebarPadding = "sidebar-padding";
      var sidebarCircle = "sidebar-circle";
      if(ind === 0) {
        sidebarLineClass = "sidebar-top-line";
        sidebarPadding += " sidebar-top-padding"
      } else if(ind === 9) {
        sidebarLineClass = "sidebar-bot-line";
        sidebarPadding = " sidebar-bot-padding";
        sidebarCircle = "sidebar-bot-circle";
      }
      var current = sidebarText[ind];

      var circleColor = " default-circle";
      var activityValue = activityStatus[current.index]

      var fullLink = window.location.href.split("/");
      var currentPage = fullLink[fullLink.length-1];
      var currentHighlight = "";
      if (current.link === currentPage) {
        currentHighlight = "current-highlight";
        circleColor = " default-circle";
      } else if (ind === 0) {
        circleColor = " green-circle";
      } else if (activityValue === "1") {
        circleColor = " orange-circle";
      } else if (activityValue === "2") {
        circleColor = " green-circle";
      } else {
        circleColor = " grey-circle";
        currentHighlight = "inactive-circle";
      }

      sidebarCircle += circleColor;

      links.push(
        <div key={ind} className="sidebar-content">
          <a className={currentHighlight} href={"#/" + current.link} style={styles.sidebarLink}>
            <div className={sidebarPadding}>
              <div className={sidebarLineClass}></div>  
              <div className={sidebarCircle}></div>  
              {expanded ? <p className="sidebar-text">{current.name}</p>: null}
            </div>    
          </a>
        </div>
      );
      if (divideAt.indexOf(current.name) > -1) {
        links.push(<div key={ind+20} className="sidebar-divider"></div>);
      }
    }

    for (let ind = 0; ind < 1; ind++) {
      links.push(<br key={-PROGRESS_SIDEBAR_COUNT}></br>);
    }
    for (let ind = 0; ind < sidebarText.length - PROGRESS_SIDEBAR_COUNT; ind++) {
      if (!(this.props.returningUser) && ind === 0) {
        continue;
      }
      const current = sidebarText[ind + PROGRESS_SIDEBAR_COUNT];
      const onClickFunction = current.name === "Dashboard" ? this.checkDashboard.bind(this) : null;
      var extraElement = 
        <div key={ind+PROGRESS_SIDEBAR_COUNT} className="sidebar-content">
            <a onClick={onClickFunction} href={"#/" + current.link} style={styles.sidebarLink}>
              <div className={"sidebar-" + current.styling}>
                <Icon style={styling[current.styling]}>{current.styling}</Icon>
                {expanded ? <p className={"sidebar-text-" + current.styling}>{current.name}</p> : null}
              </div>
            </a>
        </div>;
      links.push(extraElement);
    }

    return (
      <div key={-3} className="progress-bar">
        {links}
      </div>
    )
  }

};

export default withRouter(ProgressBar);
